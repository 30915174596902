import React, { useState } from "react";
import '../../styles/photohub/general.css';
import "../../styles/general.scss";
import "../../styles/home.scss";
import Compliant from "./Compliant";
import Non_Compliant from "./Non_Compliant";
import { useCompliance } from "./Context/ComplianceContext";

const Compliance = (props) => {
    const { activeTab, setActiveTab } = useCompliance();
    const switchHandler = (tab) => {
        setActiveTab(tab);
    };
console.log(activeTab,'fklsfjlt')
    return (
        <div className="main_container">
            <div className="row justify-content-center">
                <div className="col-8 text-center">
                    <div className="btn-group" role="group">
                        <button
                            type="button"
                            className={`switch_button btn ${activeTab === "compliant" ? "button_primary" : "btn-light "
                                }`}
                            onClick={() => switchHandler("compliant")}
                        >
                            Compliant
                        </button>
                        <button
                            type="button"
                            className={`switch_button btn ${activeTab === "non_compliant" ? "button_primary" : "btn-light "
                                }`}
                            onClick={() => switchHandler("non_compliant")}
                        >
                            Non Compliant
                        </button>
                    </div>
                </div>
            </div>
            {activeTab === "compliant" && (
                <Compliant {...props} />
            )}
            {activeTab === "non_compliant" && (
                <Non_Compliant{...props} />
            )}
        </div>
    );
};

export default Compliance;
