import React, { useState, useEffect } from 'react';
import MixedChart from './MixedChart';
import DatePicker from 'react-datepicker';
import LoggedUser from './LoggedUser';
import moment from 'moment';
import { Navigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dropdown/style.css';
import '../../styles/photohub/general.css';

const PhotoStatistics = (props) => {
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [countryList, setCountryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [storeList, setStoreList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedChannel, setSelectedChannel] = useState('');
    const [selectedStore, setSelectedStore] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({ labels: [], datasets: [] });
    const [filteredData, setFilteredData] = useState({ labels: [], datasets: [] });
    const [filtered, setFiltered] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setErrorMessage(null);

            try {
                const filterListsResponse = await fetch('/api/filterLists', {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }
                });
                const filterListsData = await filterListsResponse.json();

                const countries = [];
                const cities = [];
                const channels = [];
                const stores = [];

                for (const dataObject of filterListsData.filters) {
                    if (!countries.includes(dataObject.countryName.trim().toLowerCase())) {
                        countries.push(dataObject.countryName.trim().toLowerCase());
                    }

                    if (!cities.includes(dataObject.storeCity.trim().toLowerCase())) {
                        cities.push(dataObject.storeCity.trim().toLowerCase());
                    }

                    if (!channels.includes(dataObject.storeChannel.trim().toLowerCase())) {
                        channels.push(dataObject.storeChannel.trim().toLowerCase());
                    }

                    if (!stores.includes(dataObject.storeName.trim().toLowerCase())) {
                        stores.push(dataObject.storeName.trim().toLowerCase());
                    }
                }

                setCountryList(countries);
                setCityList(cities);
                setChannelList(channels);
                setStoreList(stores);
            } catch (error) {
                console.log("Filter Lists Api Error: ", error);
                setErrorMessage("Internal Server Error! Please try again later.");
            }

            try {
                const photoStatisticsResponse = await fetch('/api/photoStatistics', {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }
                });
                const photoStatisticsData = await photoStatisticsResponse.json();

                const processedImages = [];
                const unprocessedImages = [];
                const months = [];

                for (const dataObject of photoStatisticsData.statistics) {
                    const monthYear = dataObject.ImageMonth + " " + dataObject.ImageYear;
                    if (!months.includes(monthYear)) {
                        months.push(monthYear);
                    }
                }

                months.sort((a, b) => {
                    const getDate = f => {
                        const d = f.split(' ');
                        d[0] = {
                            january: 1, february: 2, march: 3, april: 4, may: 5, june: 6,
                            july: 7, august: 8, september: 9, october: 10, november: 11, december: 12
                        }[d[0].substring(0).toLowerCase()] || 0;
                        return d;
                    };

                    const aa = getDate(a);
                    const bb = getDate(b);
                    return aa[1] - bb[1] || aa[0] - bb[0];
                });

                for (let i = 0; i < months.length; i++) {
                    for (const dataObject of photoStatisticsData.statistics) {
                        if (dataObject.ImageMonth + " " + dataObject.ImageYear === months[i]) {
                            if (dataObject.status === "PROCESSED") {
                                processedImages.push(dataObject.ImageCount);
                                unprocessedImages.push(0);
                            } else if (dataObject.status === "NEW") {
                                processedImages.push(0);
                                unprocessedImages.push(dataObject.ImageCount);
                            }
                        }
                    }
                }

                const totalImages = processedImages.map((num, idx) => num + unprocessedImages[idx]);

                const graphData = {
                    labels: months,
                    datasets: [
                        {
                            label: "Number of Images Unprocessed",
                            data: unprocessedImages,
                            backgroundColor: "rgba(87, 121, 234, 0.6)",
                            borderColor: "rgba(87, 121, 234, 0.6)",
                            order: 1
                        },
                        {
                            label: "Number of Images Processed",
                            data: processedImages,
                            backgroundColor: "rgba(18, 200, 150, 0.6)",
                            borderColor: "rgba(18, 200, 150, 0.6)",
                            order: 1
                        },
                        {
                            label: "Total Images",
                            data: totalImages,
                            backgroundColor: "rgba(234, 87, 102, 0.6)",
                            borderColor: "rgba(234, 87, 102, 0.6)",
                            fill: false,
                            pointHoverRadius: 20,
                            pointHoverBorderWidth: 5,
                            type: "line",
                            order: 0
                        }
                    ]
                };

                setData(graphData);
                setFilteredData(graphData);
            } catch (error) {
                console.log("Photo Statistics Api Error: ", error);
                setErrorMessage("Internal Server Error! Please try again later.");
            }

            setIsLoading(false);
        };

        fetchData();
    }, []);

    const updateStartDate = (date) => {
        setStartDate(date);
    };

    const updateEndDate = (date) => {
        setEndDate(date);
    };

    const updateCountry = (e) => {
        setSelectedCountry(e.target.value);
    };

    const updateCity = (e) => {
        setSelectedCity(e.target.value);
    };

    const updateChannel = (e) => {
        setSelectedChannel(e.target.value);
    };

    const updateStore = (e) => {
        setSelectedStore(e.target.value);
    };

    const updateCategory = (e) => {
        setSelectedCategory(e.target.value);
    };

    const handleFilter = () => {
        if (Date.parse(endDate) - Date.parse(startDate) >= 0) {
            setIsLoading(true);
            setErrorMessage(null);

            fetch('/api/filteredPhotoStatistics', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    country: selectedCountry,
                    city: selectedCity,
                    channel: selectedChannel,
                    store: selectedStore,
                    startDate: moment(startDate).format("yyyy-MM-DD"),
                    endDate: moment(endDate).format("yyyy-MM-DD")
                })
            })
                .then(response => response.json())
                .then(data => {
                    const processedImages = [];
                    const unprocessedImages = [];
                    const months = [];

                    for (const dataObject of data.statistics) {
                        const monthYear = dataObject.ImageMonth + " " + dataObject.ImageYear;
                        if (!months.includes(monthYear)) {
                            months.push(monthYear);
                        }
                    }

                    months.sort((a, b) => {
                        const getDate = f => {
                            const d = f.split(' ');
                            d[0] = {
                                january: 1, february: 2, march: 3, april: 4, may: 5, june: 6,
                                july: 7, august: 8, september: 9, october: 10, november: 11, december: 12
                            }[d[0].substring(0).toLowerCase()] || 0;
                            return d;
                        };

                        const aa = getDate(a);
                        const bb = getDate(b);
                        return aa[1] - bb[1] || aa[0] - bb[0];
                    });

                    for (let i = 0; i < months.length; i++) {
                        for (const dataObject of data.statistics) {
                            if (dataObject.ImageMonth + " " + dataObject.ImageYear === months[i]) {
                                if (dataObject.status === "PROCESSED") {
                                    processedImages.push(dataObject.ImageCount);
                                    unprocessedImages.push(0);
                                } else if (dataObject.status === "NEW") {
                                    processedImages.push(0);
                                    unprocessedImages.push(dataObject.ImageCount);
                                }
                            }
                        }
                    }

                    if (processedImages && unprocessedImages) {
                        if (processedImages.length === 0) {
                            processedImages.push(0);
                        } else if (unprocessedImages.length === 0) {
                            unprocessedImages.push(0);
                        }

                        const totalImages = processedImages.map((num, idx) => num + unprocessedImages[idx]);
                        const graphData = {
                            labels: months,
                            datasets: [
                                {
                                    label: "Number of Images Unprocessed",
                                    data: unprocessedImages,
                                    backgroundColor: "rgba(87, 121, 234, 0.6)",
                                    borderColor: "rgba(87, 121, 234, 0.6)",
                                    order: 1
                                },
                                {
                                    label: "Number of Images Processed",
                                    data: processedImages,
                                    backgroundColor: "rgba(18, 200, 150, 0.6)",
                                    borderColor: "rgba(18, 200, 150, 0.6)",
                                    order: 1
                                },
                                {
                                    label: "Total Images",
                                    data: totalImages,
                                    backgroundColor: "rgba(234, 87, 102, 0.6)",
                                    borderColor: "rgba(234, 87, 102, 0.6)",
                                    fill: false,
                                    pointHoverRadius: 20,
                                    pointHoverBorderWidth: 5,
                                    type: "line",
                                    order: 0
                                }
                            ]
                        };

                        setFilteredData(graphData);
                        setFiltered(true);
                    }
                })
                .catch(error => {
                    console.log("Filtered Photo Statistics Api Error: ", error);
                    setErrorMessage("Internal Server Error! Please try again later.");
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            alert("Invalid Date Range!");
        }
    };

    const handleReset = () => {
        setFilteredData(data);
        setFiltered(false);
        setStartDate(new Date());
        setEndDate(new Date());
        setSelectedCountry('');
        setSelectedCity('');
        setSelectedChannel('');
        setSelectedStore('');
        setSelectedCategory('');
    };

    return (
        <>
               {!props?.currentUser?.user ? (
                <Navigate to="/login" replace={true} />
            ) : (
                <div>
                        <h2 style={{ marginTop: '20px' }}>Photo Hub</h2>
                        {/* <LoggedUser logOut = {this.props.logOut}/> */}
                        <div className="photoHubDataContainer">
                            <div style={{ width: '70%' }}>
                                {isLoading ? (
                                    <div className="d-flex justify-content-center">
                                        <br />
                                        <br />
                                        <div className="spinner-border text-info" role="status"></div>
                                    </div>
                                ) : errorMessage ? (
                                    <span><br />{errorMessage}</span>
                                ) : (
                                    <MixedChart data={filteredData} />
                                )}
                            </div>
                            <div className={`dataFilter ${filteredData.datasets && filteredData.datasets.length > 0 ? 'dataAvailable' : ''}`}>
                                <span>Filter</span>

                                <div className="row">
                                    <div className="col-md-6" style={{ paddingRight: '5px' }}>
                                        <span style={{ fontWeight: 'bold', fontSize: '13px' }}>Start Date</span>
                                        <DatePicker dateFormat="dd/MM/yyyy" selected={startDate} onChange={updateStartDate} />
                                    </div>
                                    <div className="col-md-6" style={{ paddingLeft: '5px' }}>
                                        <span style={{ fontWeight: 'bold', fontSize: '13px' }}>End Date</span>
                                        <DatePicker dateFormat="dd/MM/yyyy" selected={endDate} onChange={updateEndDate} />
                                    </div>
                                </div>

                                <div className="row" style={{ marginTop: '5px' }}>
                                    <div className="col-md-6" style={{ paddingRight: '5px' }}>
                                        <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>Country</span>
                                        <select name="selectCountry" id="selectCountry" style={{ width: '100%', fontSize: '14px' }} onChange={updateCountry} value={selectedCountry}>
                                            <option value="" disabled hidden>Select Country</option>
                                            {countryList.map(country => (
                                                <option key={country} value={country}>{country}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-6" style={{ paddingLeft: '5px' }}>
                                        <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>City</span>
                                        <select name="selectCity" id="selectCity" style={{ width: '100%', fontSize: '13px' }} onChange={updateCity} value={selectedCity}>
                                            <option value="" disabled hidden>Select City</option>
                                            {cityList.map(city => (
                                                <option key={city} value={city}>{city}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="row" style={{ marginTop: '5px' }}>
                                    <div className="col-md-12">
                                        <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>Channel</span>
                                        <select name="selectChannel" id="selectChannel" style={{ width: '100%', fontSize: '13px' }} onChange={updateChannel} value={selectedChannel}>
                                            <option value="" disabled hidden>Select Channel</option>
                                            {channelList.map(channel => (
                                                <option key={channel} value={channel}>{channel}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="row" style={{ marginTop: '5px' }}>
                                    <div className="col-md-12">
                                        <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>Store</span>
                                        <select name="selectStore" id="selectStore" style={{ width: '100%', fontSize: '13px' }} onChange={updateStore} value={selectedStore}>
                                            <option value="" disabled hidden>Select Store</option>
                                            {storeList.map(store => (
                                                <option key={store} value={store}>{store}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="row" style={{ marginTop: '5px' }}>
                                    <div className="col-md-12">
                                        <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>Category</span>
                                        <select name="selectCategory" id="selectCategory" style={{ width: '100%', fontSize: '13px' }} onChange={updateCategory} value={selectedCategory}>
                                            <option value="" disabled hidden>Select Category</option>
                                            <option value="14">Product</option>
                                            <option value="15">Price</option>
                                            <option value="16">Placement</option>
                                            <option value="17">Promotion</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="row" style={{ marginTop: '15px' }}>
                                    <div className="col-md-6">
                                        <button className="button_primary" onClick={handleReset} disabled={!filtered || isLoading}>Reset</button>
                                    </div>
                                    <div className="col-md-6">
                                        <button className="button_primary" onClick={handleFilter} disabled={isLoading}>Filter</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
            )}
        </>
    );
}

export default PhotoStatistics;
