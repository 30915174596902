import React, { useEffect, useMemo, useState } from "react";
import CustomMap from "../../components/CustomMap";
import { Table, Alert } from "react-bootstrap";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BiRefresh, BiReset } from "react-icons/bi";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import moment from "moment";
import axios from "axios";
import "../../styles/photohub/general.css";
import "../../styles/general.scss";
import "../../styles/home.scss";
import SingleSelectDropdownSimpleArray from "../../components/SingleSelectDropdownSimpleArray";
import SelectbarWithSearchOption from "../../components/SelectbarWithSearchOption";
import CustomDropDownFilters from "../../components/CustomDropDownFilters";
import DynamicDataTable from "../../components/DynamicDataTable";
import CustomDownloader from "../../components/CustomDownloader";
import DownloadCsv from "../../components/DownloadCsv";

const AuditorRoute = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(null);
  const [firstTimeLoading, setFirstTimeLoading] = useState(true);

  // LOWEST ONSHELF WITH CATEGORY
  const [geoTaggingTableLoading, setGeoTaggingTableLoading] = useState(false);
  const [modifiedgeoTaggingColumnDetails, setModifiedGeoTaggingColumnDetails] =
    useState();
  const [geoTaggingRows, setGeoTaggingRows] = useState([]);
  const [geoTaggingColumnHeaders, setGeoTaggingColumnHeaders] = useState([]);
  const [geoTaggingPage, setGeoTaggingPage] = React.useState(0);
  const [geoTaggingPageSize, setGeoTaggingPageSize] = React.useState(50);
  const [geoTaggingRowCount, setGeoTaggingRowCount] = React.useState();

  /* MAP VARIABLES */
  const [mapCenter, setMapCenter] = useState(null);
  const [markers, setMarkers] = useState([]);

  /* FILTER VARIABLES */

  const [isFiltered, setIsFiltered] = useState(false);
  // CHANNEL FILTER VARIABLES
  const [channelsToShow, setChannelsToShow] = useState([]);
  const [channelSelectedAll, setChannelSelectedAll] = useState(false);
  const [channelFilters, setChannelFilters] = useState([]);
  // CITY FILTER VARIABLES
  const [citiesToShow, setCitiesToShow] = useState([]);
  const [citySelectedAll, setCitySelectedAll] = useState(false);
  const [cityFilters, setCityFilters] = useState([]);
  // STORE FILTER VARIABLES
  const [storesToShow, setStoresToShow] = useState([]);
  const [storeSelectedAll, setStoreSelectedAll] = useState(false);
  const [storeFilters, setStoreFilters] = useState([]);

  // Auditor
  const [auditorsToShow, setAuditorsToShow] = useState([]);
  const [auditorSelectedAll, setAuditorSelectedAll] = useState(false);
  const [auditorFilters, setAuditorFilters] = useState([]);

    // DATE FILTERS VARIABLES
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);

  const ITEM_HEIGHT = 50;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
        width: 200,
      },
    },
  };

  const memoizedGeoTaggingColumnDetails = useMemo(() => {
    if (geoTaggingColumnHeaders) {
      const idIndex = geoTaggingColumnHeaders.indexOf("ID");
      let modifiedColumnDetails = [...geoTaggingColumnHeaders];

      if (idIndex !== -1) {
        modifiedColumnDetails.splice(idIndex, 1);
        modifiedColumnDetails = ["ID", ...modifiedColumnDetails];
      }

      return modifiedColumnDetails;
    }
  }, [geoTaggingColumnHeaders]);

  useEffect(() => {
    const getFilterValues = async () => {
      console.log("Filtered loading:");
      setIsLoading(true);
      await axios({
        method: "get",
        url: "/api/filterLists",
        headers: { "Content-Type": "application/json" },
        timeout: 120000,
      })
        .then((response) => {
          console.log(response, "Filtered Values:");
          const data = response?.data;
          const store = [
            ...new Set(data.filters.map((item) => item.storeName.trim())),
          ];
          const cities = [
            ...new Set(data.filters.map((item) => item.storeCity.trim())),
          ];
          const channels = [
            ...new Set(data.filters.map((item) => item.storeChannel.trim())),
          ];
          const users = [
            ...new Set(data.filters.map((item) => item.userName.trim())),
          ];
          setAuditorFilters(users);
          //setAuditorsToShow(users[0]);
          setChannelFilters(channels);
          setCityFilters(cities);
          setStoreFilters(store);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Filtered Values Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    getFilterValues();
  }, []);
  useEffect(() => {
    geoTagging();
  }, [geoTaggingPage, geoTaggingPageSize]);
  useEffect(() => {
    if (firstTimeLoading) {
      auditorRoute();
    }
  }, [firstTimeLoading, auditorFilters, selectedStartDate,selectedEndDate]);

  const handleSelectStartDate = (newStartDate) => {
    setSelectedStartDate(newStartDate);
    setSelectedEndDate(null)
    if (newStartDate && !selectedEndDate) {
        setStartDateError(false);
        setEndDateError(true);
    } else if (!newStartDate && selectedEndDate) {
        setStartDateError(true);
        setEndDateError(false);
    } else {
        setStartDateError(false);
        setEndDateError(false);
    }
};

const handleSelectEndDate = (newEndDate) => {
    setSelectedEndDate(newEndDate);

    if (!selectedStartDate && newEndDate) {
        setStartDateError(true);
        setEndDateError(false);
    } else if (selectedStartDate && !newEndDate) {
        setStartDateError(false);
        setEndDateError(true);
    } else {
        setStartDateError(false);
        setEndDateError(false);
    }
};

  const geoTagging = async () => {
    setGeoTaggingTableLoading(true);
    await axios({
      method: "post",
      url: "/api/geo_tagging",
      headers: { "Content-Type": "application/json" },
      data: {
        startdate: selectedStartDate ? moment(selectedStartDate?.$d).format("YYYY-MM-DD") : "",
          enddate: selectedEndDate ? moment(selectedEndDate?.$d).format("YYYY-MM-DD") : "",
        channel: channelsToShow,
        city: citiesToShow,
        store: storesToShow,
        username: auditorsToShow,
        skip: geoTaggingPage,
        limit: geoTaggingPageSize,
      },
    })
      .then((response) => {
        console.log(response, "resopnse data");
        setGeoTaggingTableLoading(false);
        const responseData = response?.data;
        setGeoTaggingRows(responseData?.table);
        setGeoTaggingRowCount(responseData?.rowCount);
        setGeoTaggingColumnHeaders(responseData?.columns);
      })
      .catch((error) => {
        console.log("Map Api: ", error);
        setGeoTaggingTableLoading(false);
        setAlertError("Something went wrong. Please try again.");
      });
  };
  const auditorRoute = async () => {
    await axios({
      method: "post",
      url: "/api/auditor_route",
      headers: { "Content-Type": "application/json" },
      data: {
        username: auditorsToShow,
        startdate: selectedStartDate ? moment(selectedStartDate?.$d).format("YYYY-MM-DD") : "",
          enddate: selectedEndDate ? moment(selectedEndDate?.$d).format("YYYY-MM-DD") : "",
        channel: channelsToShow,
        city: citiesToShow,
        store: storesToShow,
      },
    })
      .then((response) => {
        setIsLoading(false);
        console.log(response, "response data ");
        const responseData = response.data.data;

        let latitudeTotal = 0;
        let longitudeTotal = 0;
        let dataLength = responseData.length;

        responseData.forEach(({ visitLatitude, visitLongitude }) => {
          latitudeTotal += visitLatitude;
          longitudeTotal += visitLongitude;
        });

        latitudeTotal = latitudeTotal / dataLength;
        longitudeTotal = longitudeTotal / dataLength;

        const averageLatLng = { lat: latitudeTotal, lng: longitudeTotal };
        setFirstTimeLoading(false);
        setMapCenter(averageLatLng);
        setMarkers(responseData);
      })
      .catch((error) => {
        console.log("Map Api: ", error);
        setIsLoading(false);
        setAlertError("Something went wrong. Please try again.");
      });
  };
  const handleFilter = async () => {
    setIsLoading(true);
    setAlertError(null);
    geoTagging();
    auditorRoute();
    setIsFiltered(true);
  };

  const handleReset = () => {
    setIsFiltered(false);
    setSelectedStartDate(new Date());
    setAuditorsToShow([]);
    setCitiesToShow([]);
    setChannelsToShow([]);
    setStoresToShow([]);
  };
  return (
    <div>
      {isLoading ? (
        <div className="col-12 d-flex justify-content-center loader">
          <br />
          <div
            className="spinner-border text-info"
            role="status"
            style={{ zIndex: "2" }}
          ></div>
          <div className="loader-message">Loading...</div>
        </div>
      ) : alertError ? (
        <Alert variant="danger">{alertError}</Alert>
      ) : (
        <div className="container-fluid feature_data position-relative">
          <div className="row align-items-center justify-content-between">
            <div className="page_heading col-5 p-0">
              <h4 className="page_heading_primary">
                Auditor
                <span className="page_heading_secondary">&nbsp;Route</span>
              </h4>
            </div>
          </div>
          <div className="filters_row row justify-content_center">
            <CustomDropDownFilters
              filterValues={auditorFilters}
              isSelectedAll={auditorSelectedAll}
              label="Auditor"
              setValuesToShow={setAuditorsToShow}
              setSelectedAll={setAuditorSelectedAll}
              valuesToShow={auditorsToShow ? auditorsToShow : []}
            />
            <CustomDropDownFilters
              filterValues={channelFilters}
              isSelectedAll={channelSelectedAll}
              label="Channel"
              setValuesToShow={setChannelsToShow}
              setSelectedAll={setChannelSelectedAll}
              valuesToShow={channelsToShow ? channelsToShow : []}
            />
            <CustomDropDownFilters
              filterValues={cityFilters}
              isSelectedAll={citySelectedAll}
              label="Town"
              setValuesToShow={setCitiesToShow}
              setSelectedAll={setCitySelectedAll}
              valuesToShow={citiesToShow ? citiesToShow : []}
            />
            <CustomDropDownFilters
              filterValues={storeFilters}
              isSelectedAll={storeSelectedAll}
              label="Store"
              setValuesToShow={setStoresToShow}
              setSelectedAll={setStoreSelectedAll}
              valuesToShow={storesToShow ? storesToShow : []}
              showSearchBar={true}
            />
                          <div className="date-picker-container col-5 d-flex justify-content-end">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ width: "150px" }}>
                <DatePicker
                  className={`${selectedStartDate && "date_selected"}`}
                  label="From"
                  inputFormat="DD-MMM-YYYY"
                  mask={"__-__-____ __"}
                  value={selectedStartDate}
                  maxDate={selectedEndDate}
                  disableFuture={true}
                  onChange={handleSelectStartDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={startDateError}
                      helperText={null}
                      size="small"
                    />
                  )}
                />
              </div>
              <div style={{ width: "150px" }}>
                <DatePicker
                  className={`${selectedEndDate && "date_selected"}`}
                  label="To"
                  inputFormat="DD-MMM-YYYY"
                  mask={"__-__-____ __"}
                  value={selectedEndDate}
                  minDate={selectedStartDate}
                  disableFuture={true}
                  onChange={handleSelectEndDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={endDateError}
                      helperText={null}
                      size="small"
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
            </div>
            <div
              class="btn-group"
              role="group"
              aria-label="Basic example"
              style={{ width: "140px" }}
            >
              <button className="apply_filter" onClick={handleFilter}>
                Filter
              </button>
              <button className="btn btn-light" onClick={handleReset}>
                <BiRefresh />
              </button>
            </div>
          </div>

          <div className="row justify-content-center mt-3">
            <div className="card custom_table col" style={{ height: "420px" }}>
            <CustomDownloader
                url={"auditor_route_download"}
                fileName={"Auditor_route.xlsx"}
                buttonName={"Download"}
                data={{
                  startdate: selectedStartDate ? moment(selectedStartDate?.$d).format("YYYY-MM-DD") : "",
                  enddate: selectedEndDate ? moment(selectedEndDate?.$d).format("YYYY-MM-DD") : "",
                channel: channelsToShow,
                city: citiesToShow,
                store: storesToShow,
                username: auditorsToShow,
                }}
              />
              {geoTaggingRows?.length > 0 && (
                <DynamicDataTable
                rowID={"ID"}
                columnHeaders={memoizedGeoTaggingColumnDetails}
                rows={geoTaggingRows}
                isLoading={geoTaggingTableLoading}
                setPageSize={setGeoTaggingPageSize}
                pageSize={geoTaggingPageSize}
                setPage={setGeoTaggingPage}
                rowCount={geoTaggingRowCount}
              />
              )}
            </div>
          </div>
          {markers && markers?.length > 0 ? (
            <>
              <div className="row">
                <div className="col-md-12">
                  <CustomMap mapCenter={mapCenter} markers={markers} />
                </div>
              </div>
            </>
          ) : (
            <span>No data found.</span>
          )}
        </div>
      )}
    </div>
  );
};

export default AuditorRoute;
