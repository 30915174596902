import React, { useEffect, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import '../../styles/general.scss';
import '../../styles/home.scss';
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import moment from 'moment/moment';
import DoughnutChart from '../../components/DoughnutChart';
import CustomDropDownFilters from '../../components/CustomDropDownFilters';
import DynamicDataTable from '../../components/DynamicDataTable';

const ShareOfFreezer = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);


  // DATE FILTERS VARIABLES 
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  // TABLE DATA 
  // LOWEST ONSHELF WITH CATEGORY
  const [fourPCTableLoading, setFourPCTableLoading] = useState(false)
  const [modifiedFourPCColumnDetails, setModifiedFourPCColumnDetails] = useState();
  const [fourPCRows, setFourPCRows] = useState([]);
  const [fourPCColumnHeaders, setFourPCColumnHeaders] = useState([])
  const [fourPCPage, setFourPCPage] = React.useState(0);
  const [fourPCPageSize, setFourPCPageSize] = React.useState(50);
  const [fourPCRowCount, setFourPCRowCount] = React.useState();

  // CHANNEL FILTER VARIABLES 
  const [channelsToShow, setChannelsToShow] = useState([]);
  const [channelSelectedAll, setChannelSelectedAll] = useState(false);
  const [channelFilters, setChannelFilters] = useState([]);

  // REGION FILTER VARIABLES 
  const [regionsToShow, setRegionsToShow] = useState([]);
  const [regionSelectedAll, setRegionSelectedAll] = useState(false);
  const [regionFilters, setRegionFilters] = useState([]);

  // CITY FILTER VARIABLES 
  const [citiesToShow, setCitiesToShow] = useState([]);
  const [citySelectedAll, setCitySelectedAll] = useState(false);
  const [cityFilters, setCityFilters] = useState([]);

  // AREA FILTER VARIABLES 
  const [areasToShow, setAreasToShow] = useState([]);
  const [areaSelectedAll, setAreaSelectedAll] = useState(false);
  const [areaFilters, setAreaFilters] = useState([]);

  // STORE FILTER VARIABLES 
  const [storesToShow, setStoresToShow] = useState([]);
  const [storeSelectedAll, setStoreSelectedAll] = useState(false);
  const [storeFilters, setStoreFilters] = useState([]);


  useEffect(() => {
    const tempModifiedFourPCColumnDetails = []
    if (fourPCColumnHeaders) {
      fourPCColumnHeaders.filter(function (column) {
        if (column === "ID") {
          return false; // skip
        }
        return true;
      }).map((header) => {
        {
          tempModifiedFourPCColumnDetails.push(header)
        }
      })
      setModifiedFourPCColumnDetails(tempModifiedFourPCColumnDetails)
    }


  }, [fourPCColumnHeaders])

  const handleSelectStartDate = (newStartDate) => {
    setSelectedStartDate(newStartDate);

    if (newStartDate && !selectedEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    }
    else if (!newStartDate && selectedEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    }
    else {
      setStartDateError(false);
      setEndDateError(false);
    }

  }



  const handleSelectEndDate = (newEndDate) => {
    setSelectedEndDate(newEndDate);

    if (!selectedStartDate && newEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    }
    else if (selectedStartDate && !newEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    }
    else {
      setStartDateError(false);
      setEndDateError(false);
    }

  }
  useEffect(() => {
    const getFilterValues = async () => {
      setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/filter_values",
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => {
          const responseData = response?.data;
          setChannelFilters(responseData?.channel);
          setRegionFilters(responseData?.region);
          setCityFilters(responseData?.city);
          setAreaFilters(responseData?.area);
          setStoreFilters(responseData?.store);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Filtered Values Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    }
    getFilterValues();
  }, [])


  useEffect(() => {

    const fetchTableData = async () => {
      setFourPCTableLoading(true)
      await axios({
        method: "post",
        url: "/api/table",
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          startdate: selectedStartDate ? moment(selectedStartDate?.$d).format("YYYY-MM-DD") : "",
          enddate: selectedEndDate ? moment(selectedEndDate?.$d).format("YYYY-MM-DD") : "",
          city: citiesToShow,
          store: storesToShow,
          area: areasToShow,
          region: regionsToShow,
          channel: channelsToShow,
          skip: fourPCPage,
          limit: fourPCPageSize
        })
      })
        .then((response) => {
          setFourPCTableLoading(false)
          const responseData = response?.data;
          setFourPCRows(responseData?.table)
          setFourPCRowCount(responseData?.rowCount)
          setFourPCColumnHeaders(responseData?.columns)
        })
        .catch((error) => {
          setFourPCTableLoading(false)
          setAlertError('something went wrong')
          console.log("Get Filters Api: ", error);
        });
    }
    fetchTableData();

  }, [fourPCPage, fourPCPageSize, selectedStartDate, selectedEndDate, citiesToShow, storesToShow, areasToShow, regionsToShow, channelsToShow])


  return (
    <div className='container-fluid main_container share_of_freezer'>
      {(isLoading || alertError) ?
        (
          <div className="loading_area">
            {alertError &&
              <Alert variant="danger">{alertError}</Alert>
            }
            {isLoading &&
              <div className="col-12 d-flex justify-content-center loader">
                <div className="custom_loader spinner-border text-info" role="status" style={{ zIndex: '2' }}>
                </div>
                <div className="loader-message">Loading...</div>
              </div>
            }
          </div>
        ) : (
          <>
            <div className='row align-items-center justify-content-between'>
              <div className="page_heading col-5 p-0">
                <h2 className='page_heading_primary'>
                  Share of
                  <span className="page_heading_secondary">
                    &nbsp;Freezer
                  </span>
                </h2>
              </div>
              <div className='date_cont col-6 p-0'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                      className={`${selectedStartDate && "date_selected"}`}
                    label="From"
                    inputFormat="DD-MMM-YYYY"
                  mask={"__-__-____ __"} 
                    value={selectedStartDate}
                    maxDate={selectedEndDate}
                    disableFuture={true}
                    onChange={handleSelectStartDate}
                    renderInput={(params) => <TextField {...params} error={startDateError} helperText={null} size="small" />}
                  />

                  <DatePicker
                      className={`${selectedEndDate && "date_selected"}`}
                    label="To"
                    inputFormat="DD-MMM-YYYY"
                  mask={"__-__-____ __"} 
                    value={selectedEndDate}
                    minDate={selectedStartDate}
                    disableFuture={true}
                    onChange={handleSelectEndDate}
                    renderInput={(params) => <TextField {...params} error={endDateError} helperText={null} size="small" />}
                  />
                </LocalizationProvider>
              </div>
            </div>

            <div className="filters_row row">
              <div className="col-2 d-flex align-items-center justify-content-center gap-2">
                <CustomDropDownFilters
                  filterValues={channelFilters}
                  isSelectedAll={channelSelectedAll}
                  label='Channel'
                  setValuesToShow={setChannelsToShow}
                  setSelectedAll={setChannelSelectedAll}
                  valuesToShow={channelsToShow ? channelsToShow : []}
                />
              </div>

              <div className="col-2 d-flex align-items-center justify-content-center gap-2">
                <CustomDropDownFilters
                  filterValues={regionFilters}
                  isSelectedAll={regionSelectedAll}
                  label='Region'
                  setValuesToShow={setRegionsToShow}
                  setSelectedAll={setRegionSelectedAll}
                  valuesToShow={regionsToShow ? regionsToShow : []}
                />
              </div>

              <div className="col-2 d-flex align-items-center justify-content-center gap-2">
                <CustomDropDownFilters
                  filterValues={cityFilters}
                  isSelectedAll={citySelectedAll}
                  label='City'
                  setValuesToShow={setCitiesToShow}
                  setSelectedAll={setCitySelectedAll}
                  valuesToShow={citiesToShow ? citiesToShow : []}
                />
              </div>

              <div className="col-2 d-flex align-items-center justify-content-center gap-2">
                <CustomDropDownFilters
                  filterValues={areaFilters}
                  isSelectedAll={areaSelectedAll}
                  label='Area'
                  setValuesToShow={setAreasToShow}
                  setSelectedAll={setAreaSelectedAll}
                  valuesToShow={areasToShow ? areasToShow : []}
                />
              </div>

              <div className="col-2 d-flex align-items-center justify-content-center gap-2">
                <CustomDropDownFilters
                  filterValues={storeFilters}
                  isSelectedAll={storeSelectedAll}
                  label='Store'
                  setValuesToShow={setStoresToShow}
                  setSelectedAll={setStoreSelectedAll}
                  valuesToShow={storesToShow ? storesToShow : []}
                />
              </div>
            </div>
            <div className="row gap-3 graph_row">
              <div className="col-6 graph_column custom_bg">
              <h5 className="custom_card_heading">National Compliance</h5>
                <DoughnutChart
                  data={[34, 56, 23, 34, 22, 56, 23, 34, 22]}
                  labels={[
                    'jan',
                    'fab',
                    'march',
                    'may',
                    'june',
                    'fab',
                    'march',
                    'may',
                    'june'
                  ]}
                  tite='Share of Freezer'
                />
              </div>
              <div className="custom_bg col hideFooter d-flex gap-2 flex-column">
              <h5 className="custom_card_heading">National Compliance</h5>
              {(fourPCRows?.length > 0) &&
                  <DynamicDataTable
                  rowID={'Visit ID'}
                    columnHeaders={modifiedFourPCColumnDetails}
                    rows={fourPCRows}
                    isLoading={fourPCTableLoading}
                    setPageSize={setFourPCPageSize}
                    pageSize={fourPCPageSize}
                    setPage={setFourPCPage}
                    page={fourPCPage}
                    rowCount={fourPCRowCount}
                    hideFooter={true}
                    showDownload={false}
                  />
                }
              </div>


            </div>



          </>
        )
      }




    </div>
  )
}

export default ShareOfFreezer