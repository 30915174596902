import React, { useState } from "react";
import axios from "axios";
import "../styles/general.scss";
import { UTurnLeftRounded } from "@mui/icons-material";
import { FiDownload } from "react-icons/fi";
const DownloadCsv = ({
  url,
  fileName,
  data,
  buttonName,
  buttonWidth,
  setReportDownloaded,
  setDownloadReoprtName,
  recordLogActivity,
  apiTimeout
}) => {
  // CSV DOWNLOAD
  const [csvData, setCSVData] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);

  console.log(data, " this is the fileter data");

  const downloadCSV = (url, name) => {
    setCSVData(null);
    setButtonClicked(true);
    setReportDownloaded(1);
    setDownloadReoprtName(fileName);
    const getTemplate = async () => {
      await axios({
        method: "post",
        url: `/api/${url}`,
        responseType: "blob",
        timeout: apiTimeout?apiTimeout:240000,
        data: JSON.stringify(data ? data : ""),
      })
        .then((response) => {
          setCSVData(response.data);
          recordLogActivity(1,fileName);
          console.log(response, "downalod csv");
          const blob = new Blob([response.data], {
            type: "data:text/xlsx;charset=utf-8,",
          });
          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = name;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/xlsx",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 100);
        })
        .catch(function (error) {
          console.log(error);
          setButtonClicked(false);
        });
    };

    getTemplate();
  };
  return (
    <>
      <button
        onClick={() => downloadCSV(url, fileName)}
        className="btn ml-1 csv_button"
        style={{ width: buttonWidth ? buttonWidth : "100px" }}
      >
        <FiDownload />
        {buttonClicked && !csvData ? (
          <div
            className="spinner-border text-info"
            role="status"
            style={{
              zIndex: "2",
              width: "20px",
              height: "20px",
              textAlign: "center",
            }}
          ></div>
        ) : buttonName ? (
          buttonName
        ) : (
          "Download"
        )}
      </button>
    </>
  );
};

export default DownloadCsv;
