import React, { useState, useEffect, useRef } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
  SubMenu,
} from "react-pro-sidebar";
import {
  AiFillCodeSandboxCircle,
  AiFillCodeSandboxSquare,
  AiOutlineDashboard,
  AiOutlineEyeInvisible,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
} from "react-icons/ai";
import { FaRoute } from "react-icons/fa";
import SidePanel from "./SidePanel";
import { useNavigate } from "react-router-dom";
import { CiLogout } from "react-icons/ci";
import logo from "../assets/logo.png";
import Avatar from "../assets/avatar.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/sidebar.css";
import { TbReportAnalytics, TbReportSearch } from "react-icons/tb";
import { MdOutlineEventAvailable, MdOutlineListAlt } from "react-icons/md";
import { BiFridge, BiImages } from "react-icons/bi";
import { IoMdPhotos } from "react-icons/io";

const SideBar = ({ currentUser, component, logOut }) => {
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 992px)").matches
  );
  const [isPhotoHubSubMenuOpen, setIsPhotoHubSubMenuOpen] = useState(false);
  const [isMonitorAnalyticsSubMenuOpen, setIsMonitorAnalyticsSubMenuOpen] =
    useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const { collapseSidebar, collapsed } = useProSidebar();
  const profileDropDownRef = useRef(null);
  const navigate = useNavigate();
  useOutsideAlerter(profileDropDownRef);

  useEffect(() => {
    window
      .matchMedia("(max-width: 992px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref?.current && !ref?.current?.contains(event.target)) {
          setOpenProfileMenu(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const tabHandler = (tab, flag) => {
    if (flag === "mobile_view") {
      toggleSidePanel();
      navigate(tab);
    } else {
      navigate(tab);
    }
  };

  const handlePhotoHubSubMenuState = () => {
    console.log(isPhotoHubSubMenuOpen, "isPhotoHubSubMenuOpen");
    setIsPhotoHubSubMenuOpen(!isPhotoHubSubMenuOpen);
  };

  const handleMonitorAnalyticsSubMenuState = (state) => {
    setIsMonitorAnalyticsSubMenuOpen(state);
  };

  const toggleProfileMenu = () => {
    setOpenProfileMenu(!openProfileMenu);
  };

  const toggleSidePanel = () => {
    setOpenSidePanel(!openSidePanel);
  };

  const handleClick = () => {
    navigate("/");
  };
  console.log(currentUser, " THIS is current user");
  return (
    <div style={{ display: "flex" }}>
      {matches ? (
        <SidePanel
          openSidePanel={openSidePanel}
          setOpenSidePanel={setOpenSidePanel}
          tabHandler={tabHandler}
          activeTab={window.location.pathname}
          currentUser={currentUser}
        />
      ) : (
        <Sidebar>
          <div className="logo_section">
            <img src={logo} alt="logo" className="logo" onClick={handleClick} />
          </div>

          <Menu style={{ marginTop: "15px" }}>
            {currentUser?.user !== "aduitadmin@fc.com" && (
              <>
                <MenuItem
                  icon={<AiOutlineDashboard />}
                  active={
                    window.location.pathname === "/" ||
                    window.location.pathname === "/home/summary"
                  }
                  onClick={() => tabHandler("/home/summary")}
                >
                  Summary
                </MenuItem>
                <MenuItem
                  icon={<TbReportAnalytics />}
                  active={window.location.pathname === "/home/4p_compliance"}
                  onClick={() => tabHandler("/home/4p_compliance")}
                >
                  4P Compliance
                </MenuItem>
                <MenuItem
                  icon={<MdOutlineEventAvailable />}
                  active={window.location.pathname === "/home/osa"}
                  onClick={() => tabHandler("/home/osa")}
                >
                  On Shelf Availability
                </MenuItem>
                {/* <MenuItem
                active={window.location.pathname === "/home/osa-ext"}
                onClick={() => tabHandler("/home/osa-ext")}
              >
                On Shelf Availability Ext
              </MenuItem> */}

                {/* <MenuItem
                active={window.location.pathname === "/home/SOF"}
                onClick={() => tabHandler("/home/SOF")}
              >
                Share of Freezer
              </MenuItem> */}
                {/* <MenuItem
                active={window.location.pathname === "/home/price-list"}
                onClick={() => tabHandler("/home/price-list")}
              >
                Price List
              </MenuItem> */}
                <MenuItem
                  icon={<MdOutlineListAlt />}
                  active={window.location.pathname === "/home/price-ext"}
                  onClick={() => tabHandler("/home/price-ext")}
                >
                  Price List
                </MenuItem>
                <MenuItem
                  icon={<AiFillCodeSandboxCircle />}
                  active={
                    window.location.pathname === "/home/freezer-placement"
                  }
                  onClick={() => tabHandler("/home/freezer-placement")}
                >
                  Freezer Placement
                </MenuItem>
                <MenuItem
                  icon={<AiFillCodeSandboxSquare />}
                  active={window.location.pathname === "/home/freezer-purity"}
                  onClick={() => tabHandler("/home/freezer-purity")}
                >
                  Freezer Purity
                </MenuItem>
                <MenuItem
                  icon={<AiOutlineEyeInvisible />}
                  active={
                    window.location.pathname === "/home/propositions-visibility"
                  }
                  onClick={() => tabHandler("/home/propositions-visibility")}
                >
                  Visibility of Propositions
                </MenuItem>
              </>
            )}
            {(currentUser?.user === "admin@fc.com" ||
              currentUser?.user === "aduitadmin@fc.com") && (
              <>
                <MenuItem
                  icon={<FaRoute />}
                  active={window.location.pathname === "/home/auditor-route"}
                  onClick={() => tabHandler("/home/auditor-route")}
                >
                  Auditor Route
                </MenuItem>
                {currentUser?.user === "admin@fc.com" && (
                  <MenuItem
                    icon={<TbReportSearch />}
                    active={window.location.pathname === "/admin/usage-log"}
                    onClick={() => tabHandler("/admin/usage-log")}
                  >
                    Usage Log
                  </MenuItem>
                )}
              </>
            )}

            {/* <MenuItem
                active={window.location.pathname === "/home/planogram"}
                onClick={() => tabHandler("/home/planogram")}
                >
                Planogram Compliance
              </MenuItem> */}
            {/* <MenuItem
                active={window.location.pathname === "/home/planogramExt"}
                onClick={() => tabHandler("/home/planogramExt")}
                >
                Planogram Compliance
              </MenuItem> */}

            <SubMenu
              icon={<BiImages />}
              label="Photo Hub"
              open={isPhotoHubSubMenuOpen}
              onOpenChange={() => handlePhotoHubSubMenuState()}
            >
              <MenuItem
                active={
                  window.location.pathname === "/photoHub/realogram" ||
                  window.location.pathname.includes("realogram-image-details")
                }
                onClick={() => tabHandler("/photoHub/realogram")}
              >
                Realogram
              </MenuItem>

              <MenuItem
                active={
                  window.location.pathname === "/photoHub/rejected-images" ||
                  window.location.pathname.includes("rejected-image-detail")
                }
                onClick={() => tabHandler("/photoHub/rejected-images")}
              >
                Rejected Images
              </MenuItem>
              <MenuItem
                active={
                  window.location.pathname === "/photoHub/compliance" ||
                  window.location.pathname.includes("compliant-image-details")
                }
                onClick={() => tabHandler("/photoHub/compliance")}
              >
                Compliance
              </MenuItem>
            </SubMenu>

            {/* <SubMenu 
                            icon = {<IoMdAnalytics />} 
                            label = "Item 2" 
                            open = {window.location.pathname.includes("monitor") ? true : isMonitorAnalyticsSubMenuOpen}
                            onOpenChange = {(state) => handleMonitorAnalyticsSubMenuState(state)}
                        >
                            <MenuItem active = {window.location.pathname === '/item2/subItem'} onClick = {() => tabHandler('/item2/subItem')}>
                                sub Item
                            </MenuItem>
                        </SubMenu> */}
          </Menu>
        </Sidebar>
      )}

      <div className={`content_section ${collapsed ? "collapsed" : ""}`}>
        {matches && openSidePanel && <div className="overlay"></div>}
        <div className={`top_header ${collapsed ? "collapsed" : ""}`}>
          {matches ? (
            <>
              <button className="openbtn" onClick={toggleSidePanel}>
                ☰
              </button>

              <div className="logo_section">
                <img
                  src={logo}
                  alt="logo"
                  className="logo"
                  onClick={handleClick}
                />
              </div>
            </>
          ) : (
            <button
              className="btn text-white menu-button m-0 d-flex align-items-center"
              onClick={() => collapseSidebar()}
            >
              {collapsed ? (
                <AiOutlineMenuUnfold className="header-icon" />
              ) : (
                <AiOutlineMenuFold className="header-icon" />
              )}
              Menu
            </button>
          )}

          {currentUser && (
            <div className="profile-container">
              {!matches && (
                <div className="user-details">
                  <span>{currentUser?.userName}</span>
                  {/* <small>{currentUser?.role}</small> */}
                </div>
              )}

              <div className="action" ref={profileDropDownRef}>
                <div className="profile" onClick={toggleProfileMenu}>
                  <img src={Avatar} alt="User" />
                </div>

                <div className={`menu ${openProfileMenu ? "active" : ""}`}>
                  <h3>
                    {currentUser?.user}
                    <br /> <span>{currentUser?.userName}</span>
                  </h3>
                  <ul>
                    <li>
                      <CiLogout className="icon" />
                      <a
                        href="#/"
                        onClick={(event) => {
                          event.preventDefault();
                          logOut();
                        }}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="content_inner">{component ? component : ""}</div>
      </div>
    </div>
  );
};

export default SideBar;
