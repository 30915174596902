import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
// import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend);
const DoughnutChart = (props) => {
    const [matches, setMatches] = useState(window.matchMedia("(max-width: 992px)").matches);
    const [height, setHeight] = useState(matches && !props?.customHeight ? 400 : props?.customHeight ? props?.customHeight : 300);
    function getUniqueColor(n) {
        const rgb = [0, 0, 0];
        for (let i = 0; i < 24; i++) {
            rgb[i % 3] <<= 1;
            rgb[i % 3] |= n & 0x01;
            n >>= 1;
        }
        return '#' + rgb.reduce((a, c) => (c > 0x0f ? c.toString(16) : '0' + c.toString(16)) + a, '');
    }
    useEffect(() => {
        window
            .matchMedia("(max-width: 992px)")
            .addEventListener('change', e => setMatches(e.matches));

        window
            .matchMedia("(min-width: 320px)")
            .addEventListener('change', () => setHeight(300));
    }, [])

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        text: 'Primary Sales',
        fontSize: 50,
        fontColor: 'black',
        plugins: {
            title: {
                display: false,
                text: 'Custom Chart Title',
                color:'#EE259A'
            },
            legend: {
                position: 'top',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle'
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.label;
                        let value = context.formattedValue;

                        if (!label)
                            label = 'Unknown'

                        let sum = 0;
                        let dataArr = context.chart.data.datasets[0].data;
                        dataArr.map(data => {
                            sum += Number(data);
                        });

                        let percentage = (value * 100 / sum).toFixed(2) + '%';
                        return label + ": " + percentage;
                    }
                }
            },
            // datalabels: {
            //     formatter: (value, dnct1) => {
            //       let sum = 0;
            //       let dataArr = dnct1.chart.data.datasets[0].data;
            //       dataArr.map(data => {
            //         sum += Number(data);
            //       });

            //       let percentage = (value * 100 / sum).toFixed(2) + '%';
            //       return percentage;
            //     },
            //     color: '#ff3',
            //   }

        },

    };
    console.log(props, 'dfsf')
    const labels = props?.labels;
    const datasets = [
        {
            label: '# of Votes',
            data: props?.data,
            backgroundColor: [
                getUniqueColor(0),
                getUniqueColor(1),
                getUniqueColor(2),
                getUniqueColor(3),
                getUniqueColor(4),
                getUniqueColor(5),
                getUniqueColor(6),
                getUniqueColor(7),
                getUniqueColor(8),
            ],
            // borderColor: [
            //     'rgba(255, 99, 132, 1)',
            //     'rgba(54, 162, 235, 1)',
            //     'rgba(255, 206, 86, 1)',
            //     'rgba(75, 192, 192, 1)',
            //     'rgba(153, 102, 255, 1)',
            //     'rgba(255, 159, 64, 1)',
            // ],
            borderWidth: 1,
        },
    ]

    const data = {
        labels,
        datasets
    };
    return (
        <div className="custom_graph">
            {props?.loading ? (
                <div className="col-12 text-center">
                    <br />
                    <div className="custom_loader spinner-border text-info" role="status" style={{ zIndex: '2' }}>
                    </div>
                    <span className="text-info d-block">{props?.title ? props?.title : ''}</span>
                    <br />
                </div>
            ) : (
                <Doughnut options={options} data={data} height={height} />
            )}
        </div>
    )

}

export default DoughnutChart