import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import LoggedUser from './LoggedUser';
import Canvas from './Canvas';
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import TextField from "@mui/material/TextField";
import moment from 'moment';
import { Navigate, useLocation } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { Modal } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles/photohub/PhotoDetails.css';
import '../../styles/photohub/Realogram.css';
import '../../styles/photohub/general.css';
import { useRef } from 'react';
import { BiRefresh } from 'react-icons/bi';
import CustomDropDownFilters from '../../components/CustomDropDownFilters';
import axios from 'axios';
import CustomMultiSelectWithOptionValue from '../../components/CustomMultiSelectWithOptionValue';

const SCROLL_SENSITIVITY = 0.0005;
const MAX_ZOOM = 5;
const MIN_ZOOM = 0.1;

function Realogram(props) {
    const canvasRef = useRef(null);
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const [isLoading, setIsLoading] = useState(false);
    // const [photoLoading, setPhotoLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    let { state } = useLocation();
    const [images, setImages] = useState(state?.images);
    const [imageIndex, setImageIndex] = useState(state?.imageIndex);
    const [flag, setFlag] = useState(false);
    // DATE FILTERS VARIABLES
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);

    // CHANNEL FILTER VARIABLES
    const [channelsToShow, setChannelsToShow] = useState([]);
    const [channelSelectedAll, setChannelSelectedAll] = useState(false);
    const [channelFilters, setChannelFilters] = useState([]);

    // REGION FILTER VARIABLES
    const [regionsToShow, setRegionsToShow] = useState([]);
    const [regionSelectedAll, setRegionSelectedAll] = useState(false);
    const [regionFilters, setRegionFilters] = useState([]);

    // AREA FILTER VARIABLES
    const [areasToShow, setAreasToShow] = useState([]);
    const [areaSelectedAll, setAreaSelectedAll] = useState(false);
    const [areaFilters, setAreaFilters] = useState([]);

    // STORE FILTER VARIABLES
    const [storesToShow, setStoresToShow] = useState([]);
    const [storeSelectedAll, setStoreSelectedAll] = useState(false);
    const [storeFilters, setStoreFilters] = useState([]);

    // Territory Filter
    const [territorySelectedAll, setTerritorySelectedAll] = useState(false);
    const [territoryFilters, setTerritoryFilters] = useState([]);
    const [territoryToShow, setTerritoryToShow] = useState([]);

    // Category FILTER VARIABLES
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categoriesToShow, setCategoriesToShow] = useState([]);
    const [categortySelectedAll, setCategortySelectedAll] = useState(false);
    const [categoryList, setCategoryList] = useState([
        { value: "14", label: "Product" },
        { value: "15", label: "Price" },
        { value: "16", label: "Placement" },
        { value: "17", label: "Promotion" }
    ]);


    /*Image Variables*/
    const [image, setImage] = useState("");
    const [length, setLength] = useState(0);
    const [xMin, setXMin] = useState([]);
    const [yMin, setYMin] = useState([]);
    const [xMax, setXMax] = useState([]);
    const [yMax, setYMax] = useState([]);
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    const [label, setLabel] = useState(null);
    const [showLabel, setShowLabel] = useState(false);
    const [showModal, setShowModal] = useState(false);

    /*Button Variables*/

    const [currentState, setCurrentState] = useState(state);
    const [nextButton, setNextButton] = useState(true);
    const [previousButton, setPreviousButton] = useState(false);
    const [page, setPage] = useState(state?.imageIndex);
    const [totalPages, setTotalPages] = useState(1);

    /*Photo Metadata Variables*/
    const [visitDate, setVisitDate] = useState("");
    const [visitDataUploadDate, setVisitDataUploadDate] = useState("");
    const [storeName, setStoreName] = useState("");
    const [storeCity, setStoreCity] = useState("");
    const [storeRegion, setStoreRegion] = useState("");
    const [storeChannel, setStoreChannel] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [companyName, setCompanyName] = useState("");

    /*Filter Variables*/
    const [filtered, setFiltered] = useState(false);
    const [filteredImage, setFilteredImage] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [selectedOption, setSelectedOption] = useState('');
    const [storeOptions, setStoreOptions] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedChannel, setSelectedChannel] = useState("");
    const [selectedStore, setSelectedStore] = useState("");
    console.log(state, ' ths isi current state')
    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            setErrorMessage(null);
            setLabel(null);
            if (currentState) {
                setImages(currentState.images);
                setPage(currentState.page);
                setTotalPages(currentState.totalPages);

                if (!flag)
                    setImageIndex(currentState.imageIndex);

                if (currentState.images.length === 1)
                    setNextButton(false);

                if (currentState.imageIndex >= 0 && currentState.page >= 0)
                    setPreviousButton(true);
            }
            let imageData = null;
            let imageResponse = null;
            try {
                imageResponse = await fetch('/api/filteredRealogramImages', {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        filename: state?.images[imageIndex].filestoragePath,
                    })
                });

                imageData = await imageResponse.json();
                console.log(imageData, 'image data image data')
                if (imageData && imageData.length > 0) {
                    var image = "";
                    var xmins = [];
                    var ymins = [];
                    var xmaxes = [];
                    var ymaxes = [];
                    var width = null;
                    var height = null;
                    var labels = [];

                    const uniqueProductNames = new Set();

                    for (const dataObjects of imageData.imageDetails) {
                        image = dataObjects.filestoragePath;
                        xmins.push(dataObjects.boxXMin);
                        ymins.push(dataObjects.boxYMin);
                        xmaxes.push(dataObjects.boxXMax);
                        ymaxes.push(dataObjects.boxYMax);
                        width = dataObjects.boxWidth;
                        height = dataObjects.boxHeight;

                        if (dataObjects.productName && !uniqueProductNames.has(dataObjects.productName)) {
                            uniqueProductNames.add(dataObjects.productName);
                            labels.push(dataObjects.productName);
                        }
                    }


                    setImage(localStorage.getItem("Original Image") ? localStorage.getItem("Original Image") : image);
                    setFilteredImage(image);
                    setLength(imageData.imageDetails.length);
                    setXMin(xmins);
                    setYMin(ymins);
                    setXMax(xmaxes);
                    setYMax(ymaxes);
                    setWidth(width);
                    setHeight(height);
                    setLabel(labels);

                }
                else {
                    setImage(null);
                    setFilteredImage(null);
                }
                setIsLoading(false);
            }
            catch (error) {
                console.log("Realogram Api Error: ", error);
                setErrorMessage("Internal Server Error! Please try again later.");
                setIsLoading(false);
            }

            if (imageData && imageData.length > 0) {
                try {
                    const photoMetadataResponse = await fetch('/api/photoMetadata', {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ filename: image })
                    });

                    const photoMetadata = await photoMetadataResponse.json();

                    for (const dataObject of photoMetadata.metadata) {
                        setVisitDate(moment(dataObject.visitDate).format("yyyy-MM-DD"));
                        setVisitDataUploadDate(moment(dataObject.visitDataUploadDate).format("yyyy-MM-DD"));
                        setStoreName(dataObject.storeName);
                        setStoreCity(dataObject.storeCity);
                        setStoreRegion(dataObject.storeRegion);
                        setStoreChannel(dataObject.storeChannel);
                        setCategoryName(dataObject.categoryName);
                        setCompanyName(dataObject.companyName);
                    }
                    setIsLoading(false);
                }
                catch (error) {
                    console.log("Photo Metadata Api Error: ", error);
                    setErrorMessage("Internal Server Error! Please try again later.");
                    setIsLoading(false);
                }
            }


        }
        fetchData();

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [imageIndex]);




    const next = () => {
        canvasRef.current = null;
        if (imageIndex >= 0 && imageIndex < images.length) {
            const nextImageIndex = imageIndex + 1;

            setImageIndex(nextImageIndex);
            setPreviousButton(true);
            setFlag(true);

            if (nextImageIndex === images.length - 1 && page <= totalPages) {
                setCurrentState(null);
                setNextButton(false);
            }
            else if (nextImageIndex === images.length) {
                const nextPage = page + 1;

                setCurrentState(null);
                setPage(nextPage);
                setImageIndex(0);

                if (nextPage === totalPages) {
                    setNextButton(false);
                }
            }
        }
        else {
            setNextButton(false);
            setPreviousButton(true);
        }
    }

    const previous = () => {
        canvasRef.current = null;
        if (page >= 0) {
            const previousImageIndex = imageIndex - 1;

            setImageIndex(previousImageIndex);
            setNextButton(true);
            setFlag(true);

            if (previousImageIndex === 0 && page === 0) {
                setCurrentState(null);
                setPreviousButton(false);
            }
            else if (previousImageIndex < 0) {
                const previousPage = page - 1;

                setCurrentState(null);
                setPage(previousPage);
                setImageIndex(0);

                if (previousPage === 0) {
                    setPreviousButton(false);
                }
            }
        }
        else {
            setNextButton(true);
            setPreviousButton(false);
        }
    }
    const [zoomLevel, setZoomLevel] = useState(1);

    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [draggind, setDragging] = useState(false);

    const touch = useRef({ x: 0, y: 0 });
    const containerRef = useRef(null);
    const observer = useRef(null);
    const background = useMemo(() => new Image(), [filteredImage]);

    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

    const handleWheel = (event) => {
        const { deltaY } = event;

        // Check if the mouse pointer is inside the image container
        if (containerRef.current && containerRef.current.contains(event.target)) {
            const { deltaY } = event;
            if (!draggind) {
                setZoom((zoom) =>
                    clamp(zoom + deltaY * SCROLL_SENSITIVITY * -1, MIN_ZOOM, MAX_ZOOM)
                );
            }
        };
    }

    const handleMouseMove = (event) => {
        if (draggind) {
            const { x, y } = touch.current;
            const { clientX, clientY } = event;
            setOffset({
                x: offset.x + (x - clientX),
                y: offset.y + (y - clientY),
            });
            touch.current = { x: clientX, y: clientY };
        }
    };

    const handleMouseDown = (event) => {
        const { clientX, clientY } = event;
        touch.current = { x: clientX, y: clientY };
        setDragging(true);
    };

    const handleMouseUp = () => setDragging(false);

    const draw = () => {
        if (canvasRef.current) {
            const context = canvasRef.current.getContext("2d");
            const { width, height } = background;

            // Set canvas dimensions to match the image
            canvasRef.current.width = width;
            canvasRef.current.height = height;
            // Clear canvas
            context.clearRect(0, 0, width, height);

            // Apply transformations to both image and labels
            context.save(); // Save the current transformation state

            // Apply zoom and translation to the image
            context.translate(-offset.x, -offset.y);
            context.scale(zoom, zoom);

            // Make sure we're zooming to the center
            const x = (context.canvas.width / zoom - background.width) / 2;
            const y = (context.canvas.height / zoom - background.height) / 2;

            // Draw image
            context.drawImage(background, x, y);
            setIsLoading(false)
            // Apply zoom and translation to labels using zoomLevel
            context.translate(offset.x, offset.y); // Translate back to original position
            context.scale(zoomLevel, zoomLevel);

            // Draw labels
            for (var i = 0; i < length; i++) {
                context.lineWidth = 10;

                // Generate random color
                const randomColor = "#" + Math.round(Math.random() * 16777215).toString(16);
                context.strokeStyle = randomColor;

                // Adjust the coordinates to the center based on the zoom level
                const labelX = (xMin[i] + xMax[i]) / 2;
                const labelY = (yMin[i] + yMax[i]) / 2;

                // Draw the rect at the center
                context.strokeRect(labelX - (xMax[i] - xMin[i]) / 2, labelY - (yMax[i] - yMin[i]) / 2, xMax[i] - xMin[i], yMax[i] - yMin[i]);

                if (showLabel === true) {
                    context.lineWidth = 3;

                    // Set the font style
                    context.font = "bold 70px Arial"; // Replace "Arial" with your preferred font family

                    // Generate another random color for text
                    const textColor = "#" + Math.round(Math.random() * 16777215).toString(16);
                    context.fillStyle = textColor;
                    context.strokeStyle = textColor;

                    // Measure the text width and height
                    const textWidth = context.measureText(label[i]).width;
                    const textHeight = 60; // Assuming a fixed height for text

                    // Draw the text at the center
                    context.strokeText(label[i], labelX - textWidth / 2, labelY + textHeight / 2);
                    context.fillText(label[i], labelX - textWidth / 2, labelY + textHeight / 2); // Fill the text
                }

            }
            context.restore(); // Restore the previous transformation state
        }
    };



    useEffect(() => {
        if (containerRef.current) {
            observer.current = new ResizeObserver((entries) => {
                entries.forEach(({ target }) => {
                    const { width, height } = background;
                    // If width of the container is smaller than image, scale image down
                    if (target.clientWidth < width) {
                        // Calculate scale
                        const scale = target.clientWidth / width;

                        // Redraw image
                        canvasRef.current.width = width * scale;
                        canvasRef.current.height = height * scale;
                        canvasRef.current
                            .getContext("2d")
                            .drawImage(background, 0, 0, width * scale, height * scale);
                    }
                });
            });
            observer.current.observe(containerRef.current);

            return () => observer.current.unobserve(containerRef.current);
        }
    }, []);
    console.log(background, filteredImage, canvasRef.current, ' these variables');
    useLayoutEffect(() => {

        const canvas = canvasRef.current;

        if (background && filteredImage && canvas) {
            background.src = "https://storage.googleapis.com/staging-api-uploads/" + filteredImage;
            background.onload = () => {
                // Get the image dimensions
                const { width, height } = background;

                // Set canvas dimensions to match the image
                canvas.width = width;
                canvas.height = height;

                // Set image as background
                canvas.getContext("2d").drawImage(background, 0, 0);
                draw();
            };
        }
    }, [background, filteredImage, canvasRef.current, zoom, offset, showLabel]);







    console.log(width, height, ' dimensions')
    const handleChange = (e) => {
        if (e.target.checked === true)
            setShowLabel(true);
        else
            setShowLabel(false);
    }



    const handleCloseModal = () => {
        setShowModal(false);
    }
    console.log(isLoading, ' is loading state')


    const navigateToPreviousPage = () => {
        window.history.back();
    };
    console.log(label, 'store options')
    return (
        <div className="container-fluid main_container">
            {!props?.currentUser?.user ? (
                <Navigate to="/login" replace={true} />
            ) : (

                <div>
                    <div className="row align-items-center justify-content-between">
                        <div className="page_heading col-5 p-0">
                            <h4 className="page_heading_primary">
                                Realogram
                                <span className="page_heading_secondary">&nbsp;</span>
                            </h4>
                        </div>
                        <div className="col-2">
                            <button className="button_primary" onClick={navigateToPreviousPage}>
                                Back
                            </button>
                        </div>

                    </div>
                    {/* <div className="filters_row row">
                        <CustomDropDownFilters
                            filterValues={channelFilters}
                            isSelectedAll={channelSelectedAll}
                            label="Channel"
                            setValuesToShow={setChannelsToShow}
                            setSelectedAll={setChannelSelectedAll}
                            valuesToShow={channelsToShow ? channelsToShow : []}
                        />

                        <CustomDropDownFilters
                            filterValues={regionFilters}
                            isSelectedAll={regionSelectedAll}
                            label="Region"
                            setValuesToShow={setRegionsToShow}
                            setSelectedAll={setRegionSelectedAll}
                            valuesToShow={regionsToShow ? regionsToShow : []}
                        />

                        <CustomDropDownFilters
                            filterValues={areaFilters}
                            isSelectedAll={areaSelectedAll}
                            label="Distribution"
                            setValuesToShow={setAreasToShow}
                            setSelectedAll={setAreaSelectedAll}
                            valuesToShow={areasToShow ? areasToShow : []}
                        />
                        <CustomDropDownFilters
                            filterValues={territoryFilters}
                            isSelectedAll={territorySelectedAll}
                            label="Territory"
                            setValuesToShow={setTerritoryToShow}
                            setSelectedAll={setTerritorySelectedAll}
                            valuesToShow={territoryToShow ? territoryToShow : []}
                        />
                        <CustomMultiSelectWithOptionValue
                            filterValues={categoryList}
                            isSelectedAll={categortySelectedAll}
                            label="Category"
                            setValuesToShow={setCategoriesToShow}
                            setSelectedAll={setCategortySelectedAll}
                            valuesToShow={categoriesToShow ? categoriesToShow : []}
                        />
                        <CustomDropDownFilters
                            filterValues={storeFilters}
                            isSelectedAll={storeSelectedAll}
                            label="Store"
                            setValuesToShow={setStoresToShow}
                            setSelectedAll={setStoreSelectedAll}
                            valuesToShow={storesToShow ? storesToShow : []}
                        />
                        <div className="date-picker-container col d-flex justify-content-end">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div style={{ width: '150px' }}>
                                    <DatePicker
                                        className={`${selectedStartDate && "date_selected"}`}
                                        label="From"
                                        inputFormat="DD-MMM-YYYY"
                                        mask={"__-__-____ __"}
                                        value={selectedStartDate}
                                        maxDate={selectedEndDate}
                                        disableFuture={true}
                                        onChange={handleSelectStartDate}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={startDateError}
                                                helperText={null}
                                                size="small"
                                            />
                                        )}
                                    />
                                </div>
                                <div style={{ width: '150px' }}>
                                    <DatePicker
                                        className={`${selectedEndDate && "date_selected"}`}
                                        label="To"
                                        inputFormat="DD-MMM-YYYY"
                                        mask={"__-__-____ __"}
                                        value={selectedEndDate}
                                        minDate={selectedStartDate}
                                        disableFuture={true}
                                        onChange={handleSelectEndDate}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={endDateError}
                                                helperText={null}
                                                size="small"
                                            />
                                        )}
                                    />
                                </div>
                            </LocalizationProvider>
                        </div>
                        <div class="btn-group" role="group" aria-label="Basic example" style={{ width: '140px' }}>
                            <button className="apply_filter" onClick={handleFilter} disabled={isLoading}>
                                Filter
                            </button>
                            <button className='btn btn-light btn_refresh' onClick={handleReset} disabled={!filtered || isLoading}>
                                <BiRefresh />
                            </button>
                        </div>
                    </div> */}

                    <div style={{ width: '100%' }}>
                        {errorMessage && (
                            <span>{errorMessage}</span>
                        )
                        }
                        {isLoading && !canvasRef.current ? (
                            <>
                                <div className="d-flex justify-content-center loader_con">
                                    <br />
                                    <br />
                                    <div className="spinner-border text-info" role="status"></div>
                                </div>
                            </>
                        ) : (
                            filteredImage === '' ? (
                                <span>No pictures to display.</span>
                            ) :
                                (
                                    <>
                                        <div className='row pb-2'>
                                            <div className="scrollButtons">
                                                <button className="pagination_next d-flex gap-3" style={{ width: 'unset' }}>
                                                    <NavigateBeforeIcon
                                                        className={`icon ${!previousButton ? 'disabled_button' : ''}`}
                                                        onClick={previous}
                                                        disabled={!previousButton}
                                                    />
                                                    <NavigateNextIcon
                                                        className={`icon ${!nextButton ? 'disabled_button' : ''}`}
                                                        onClick={next}
                                                        disabled={!nextButton}
                                                    />
                                                </button>
                                            </div>
                                            <div className='col-md-8 position-relative'>
                                                <div ref={containerRef}>
                                                    <canvas
                                                        ref={canvasRef}
                                                        className="image"
                                                        height={height}
                                                        width={width}
                                                        onMouseDown={handleMouseDown}
                                                        onMouseUp={handleMouseUp}
                                                        onWheel={handleWheel}
                                                        onMouseMove={handleMouseMove}
                                                    />
                                                </div>
                                                {filteredImage && (
                                                    <label className='d-flex align-items-center' style={{ position: 'absolute', top: '0', left: '10px', backgroundColor: '#ffffff', border: '2px solid', padding: '2px' }}>
                                                        Display Labels &nbsp;
                                                        <input type="checkbox" onChange={e => handleChange(e)} />
                                                    </label>
                                                )}
                                                {/* <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                                            <button onClick={handleZoomIn}>Zoom In</button>
                                            <button onClick={handleZoomOut}>Zoom Out</button>
                                        </div> */}
                                            </div>
                                            <div className='col p-0'>
                                                <div className="r_metadata onShelf_data d-flex">
                                                    <div>
                                                        <span>On Shelf Availability</span>
                                                        <br />
                                                        <ul className='osa_list'>
                                                            {label && label.length > 0 ? (
                                                                label.map((productName, index) => (
                                                                    <li key={index}>{productName}</li>
                                                                ))
                                                            ) : (
                                                                <p>No labels found.</p>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row justify-content-center'>
                                            <div className='col-md-12'>
                                                <div className="r_metadata">
                                                    <div className='row w-100 justify-content-center text-center'>
                                                        <span>Photo's Metadata</span>
                                                    </div>
                                                    <div className="row w-100 justify-content-center gap-5" style={{ fontSize: '14px', marginTop: '0.5em' }}>
                                                        <div className='col-4'>
                                                            <div className="d-flex justify-content-between">
                                                                <span style={{ fontWeight: '500' }}>Visit Date:</span>
                                                                {visitDate ? visitDate : "NA"}
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <span style={{ fontWeight: '500' }}>Upload Date:</span>
                                                                {visitDataUploadDate ? visitDataUploadDate : "NA"}
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <span style={{ fontWeight: '500' }}>Store Name:</span>
                                                                {storeName ? storeName : "NA"}
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <span style={{ fontWeight: '500' }}>Store City:</span>
                                                                {storeCity ? storeCity : "NA"}
                                                            </div>
                                                        </div>
                                                        <div className='col-4'>
                                                            <div className="d-flex justify-content-between">
                                                                <span style={{ fontWeight: '500' }}>Store Region:</span>
                                                                {storeRegion ? storeRegion : "NA"}
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <span style={{ fontWeight: '500' }}>Store Channel:</span>
                                                                {storeChannel ? storeChannel : "NA"}
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <span style={{ fontWeight: '500' }}>Category Name:</span>
                                                                {categoryName ? categoryName : "NA"}
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <span style={{ fontWeight: '500' }}>Company Name:</span>
                                                                {companyName ? companyName : "NA"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </>


                                ))}
                    </div>


                    {/* <div className={`dataFilter realogram ${filteredImage ? '' : ''}`}>
                            <span>Filter</span>

                            <div className="row">
                                <div className="col-md-6" style={{ paddingRight: '5px' }}>
                                    <span style={{ fontWeight: 'bold', fontSize: '13px' }}>Start Date</span>
                                    <DatePicker dateFormat="dd/MM/yyyy" selected={startDate} onChange={updateStartDate} />
                                </div>
                                <div className="col-md-6" style={{ paddingLeft: '5px' }}>
                                    <span style={{ fontWeight: 'bold', fontSize: '13px' }}>End Date</span>
                                    <DatePicker dateFormat="dd/MM/yyyy" selected={endDate} onChange={updateEndDate} />
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: '5px' }}>
                                <div className="col-md-6" style={{ paddingRight: '5px' }}>
                                    <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>Country</span>
                                    <select name="selectCountry" id="selectCountry" style={{ width: '100%', fontSize: '14px' }} onChange={updateCountry} value={selectedCountry}>
                                        <option value="" disabled hidden>Select Country</option>
                                        {countryList.map((country) => <option key={country} value={country}>{country}</option>)}
                                    </select>
                                </div>
                                <div className="col-md-6" style={{ paddingLeft: '5px' }}>
                                    <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>City</span>
                                    <select name="selectCity" id="selectCity" style={{ width: '100%', fontSize: '13px' }} onChange={updateCity} value={selectedCity}>
                                        <option value="" disabled hidden>Select City</option>
                                        {cityList.map((city) => <option key={city} value={city}>{city}</option>)}
                                    </select>
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: '5px' }}>
                                <div className="col-md-12">
                                    <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>City</span>
                                    <select name="selectCity" id="selectCity" style={{ width: '100%', fontSize: '13px' }} onChange={updateCity} value={selectedCity}>
                                        <option value="" disabled hidden>Select City</option>
                                        {cityList.map((city) => <option key={city} value={city}>{city}</option>)}
                                    </select>
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: '5px' }}>
                                <div className="col-md-12">
                                    <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>Channel</span>
                                    <select name="selectChannel" id="selectChannel" style={{ width: '100%', fontSize: '13px' }} onChange={updateChannel} value={selectedChannel}>
                                        <option value="" disabled hidden>Select Channel</option>
                                        {channelList.map((channel) => <option key={channel} value={channel}>{channel}</option>)}
                                    </select>
                                </div>
                            </div>

                            {storeOptions &&

                                <div className="row" style={{ marginTop: '5px' }}>
                                    <div className="col-md-12">
                                        <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>Store</span>
                                        <Select
                                            options={storeOptions}
                                            value={selectedOption}
                                            onChange={handleStoreChange}
                                            isSearchable={true}
                                            placeholder="Select Store"
                                            noOptionsMessage={() => 'Type to search...'}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '4px',
                                                }),
                                            }}
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.value}
                                            isClearable={true} // Added this line
                                        />
                                      
                                    </div>
                                </div>
                            }

                            <div className="row" style={{ marginTop: '5px' }}>
                                <div className="col-md-12">
                                    <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>Category</span>
                                    <select name="selectCategory" id="selectCategory" style={{ width: '100%', fontSize: '13px' }} onChange={updateCategory} value={selectedCategory}>
                                        <option value="" disabled hidden>Select Category</option>
                                        <option value="14">Product</option>
                                        <option value="15">Price</option>
                                        <option value="16">Placement</option>
                                        <option value="17">Promotion</option>
                                    </select>
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: '15px' }}>
                                <div className="col-md-6">
                                    <button className="button_primary" onClick={handleReset} disabled={!filtered || isLoading}>Reset</button>
                                </div>
                                <div className="col-md-6">
                                    <button className="button_primary" onClick={handleFilter} disabled={isLoading}>Filter</button>
                                </div>
                            </div>
                        </div> */}

                    <Modal centered show={showModal} onHide={handleCloseModal}>
                        <Modal.Body>
                            {width > 1280 ? (
                                <Canvas draw={draw} width={1280} height={height} />
                            ) : (
                                <Canvas draw={draw} width={width} height={height} />
                            )}
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </div>
    );
}

export default Realogram;