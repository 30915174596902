import React from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import SideBar from '../components/SideBar';

const AdminPanel = (props) => 
{
    return (
        <ProSidebarProvider>
            <SideBar currentUser = {props?.currentUser} component = {props?.component} logOut = {props?.logOut} />
        </ProSidebarProvider>
    );
}

export default AdminPanel;