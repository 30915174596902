import React, { useEffect, useRef, useState } from "react";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { MdOutlineArrowBack } from "react-icons/md";
import { IoMdAnalytics } from "react-icons/io";
import "../styles/components.scss";
import { AiOutlineHome } from "react-icons/ai";
import {
  AiFillCodeSandboxCircle,
  AiFillCodeSandboxSquare,
  AiOutlineDashboard,
  AiOutlineEyeInvisible,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
} from "react-icons/ai";
import { FaRoute } from "react-icons/fa";
import { CiLogout } from "react-icons/ci";
import { TbReportAnalytics, TbReportSearch } from "react-icons/tb";
import { MdOutlineEventAvailable, MdOutlineListAlt } from "react-icons/md";
import { BiFridge, BiImages } from "react-icons/bi";

const SidePanel = ({
  currentUser,
  openSidePanel,
  setOpenSidePanel,
  tabHandler,
  activeTab,
}) => {
  const [isPhotoHubSubMenuOpen, setIsPhotoHubSubMenuOpen] = useState(false);
  const [isFeatureDataSubMenuOpen, setIsFeatureDataSubMenuOpen] =
    useState(false);
  const [isMonitorAnalyticsSubMenuOpen, setIsMonitorAnalyticsSubMenuOpen] =
    useState(false);
  const sidePanelRef = useRef();
  useOutsideAlerter(sidePanelRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref?.current && !ref?.current?.contains(event.target)) {
          setOpenSidePanel(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleFeatureDataSubMenuState = (state) => {
    setIsFeatureDataSubMenuOpen(state);
  };

  const handleMonitorAnalyticsSubMenuState = (state) => {
    setIsMonitorAnalyticsSubMenuOpen(state);
  };
  const handlePhotoHubSubMenuState = () => {
    console.log(isPhotoHubSubMenuOpen, "isPhotoHubSubMenuOpen");
    setIsPhotoHubSubMenuOpen(!isPhotoHubSubMenuOpen);
  };
  return (
    <div
      className={`sidePanel ${openSidePanel && "openSidePanel"}`}
      ref={sidePanelRef}
    >
      <div className="backButton">
        <a
          href="#/"
          onClick={(event) => {
            event.preventDefault();
            setOpenSidePanel(false);
          }}
        >
          <MdOutlineArrowBack /> Back
        </a>
      </div>

      <div
        className="d-flex flex-column justify-content-between"
        style={{ height: "65%" }}
      >
        <div className="nav_menu">
          <Menu style={{ marginTop: "15px" }}>
            <MenuItem
              icon={<AiOutlineDashboard />}
              active={
                window.location.pathname === "/" ||
                window.location.pathname === "/home/summary"
              }
              onClick={() => tabHandler("/home/summary")}
            >
              Summary
            </MenuItem>
            <MenuItem
              icon={<TbReportAnalytics />}
              active={window.location.pathname === "/home/4p_compliance"}
              onClick={() => tabHandler("/home/4p_compliance")}
            >
              4P Compliance
            </MenuItem>
            <MenuItem
              icon={<MdOutlineEventAvailable />}
              active={window.location.pathname === "/home/osa"}
              onClick={() => tabHandler("/home/osa")}
            >
              On Shelf Availability
            </MenuItem>

            <MenuItem
              icon={<MdOutlineListAlt />}
              active={window.location.pathname === "/home/price-ext"}
              onClick={() => tabHandler("/home/price-ext")}
            >
              Price List
            </MenuItem>
            <MenuItem
              icon={<AiFillCodeSandboxCircle />}
              active={window.location.pathname === "/home/freezer-placement"}
              onClick={() => tabHandler("/home/freezer-placement")}
            >
              Freezer Placement
            </MenuItem>
            <MenuItem
              icon={<AiFillCodeSandboxSquare />}
              active={window.location.pathname === "/home/freezer-purity"}
              onClick={() => tabHandler("/home/freezer-purity")}
            >
              Freezer Purity
            </MenuItem>
            <MenuItem
              icon={<AiOutlineEyeInvisible />}
              active={
                window.location.pathname === "/home/propositions-visibility"
              }
              onClick={() => tabHandler("/home/propositions-visibility")}
            >
              Visibility of Propositions
            </MenuItem>
            <MenuItem
              icon={<FaRoute />}
              active={window.location.pathname === "/home/auditor-route"}
              onClick={() => tabHandler("/home/auditor-route")}
            >
              Auditor Route
            </MenuItem>
            {currentUser?.user === "admin@fc.com" && (
              <MenuItem
                icon={<TbReportSearch />}
                active={window.location.pathname === "/admin/usage-log"}
                onClick={() => tabHandler("/admin/usage-log")}
              >
                Usage Log
              </MenuItem>
            )}

            {currentUser?.user === "admin@fc.com" && (
              <SubMenu
                icon={<BiImages />}
                label="Photo Hub"
                open={isPhotoHubSubMenuOpen}
                onOpenChange={() => handlePhotoHubSubMenuState()}
              >
                <MenuItem
                  active={window.location.pathname === "/photoHub/realogram"}
                  onClick={() => tabHandler("/photoHub/realogram")}
                >
                  Realogram
                </MenuItem>

                <MenuItem
                  active={
                    window.location.pathname === "/photoHub/rejected-images"
                  }
                  onClick={() => tabHandler("/photoHub/rejected-images")}
                >
                  Rejected Images
                </MenuItem>
                <MenuItem
                  active={window.location.pathname === "/photoHub/compliance"}
                  onClick={() => tabHandler("/photoHub/compliance")}
                >
                  Compliance
                </MenuItem>
              </SubMenu>
            )}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default SidePanel;
