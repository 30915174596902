import React, { useEffect, useState } from "react";
import "../../styles/general.scss";
import "../../styles/home.scss";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { Alert } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import moment from "moment/moment";
import CustomDropDownFilters from "../../components/CustomDropDownFilters";
import DynamicDataTable from "../../components/DynamicDataTable";
import DownloadCsv from "../../components/DownloadCsv";

const Analysis = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);

  // GENERAL DATA VARIABLES
  const [totalStoresLoading, setTotalStoresLoading] = useState(false);
  const [totalStores, setTotalStores] = useState(null);

  const [reportDownloaded, setReportDownloaded] = useState(0);
  const [downloadReoprtName, setDownloadReoprtName] = useState("");
  const [logId, setLogId] = useState(null);

 // DATE FILTERS VARIABLES
 const [selectedStartDate, setSelectedStartDate] = useState(moment().startOf('month').format("YYYY-MM-DD"));
const [selectedEndDate, setSelectedEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  // TABLE DATA
  // ONSHELF ALL SKU
  const [osaTableLoading, setOsaTableLoading] = useState(false);
  const [modifiedOsaColumnDetails, setModifiedOsaColumnDetails] = useState();
  const [osaRows, setOsaRows] = useState([]);
  const [osaColumnHeaders, setOsaColumnHeaders] = useState([]);
  const [osaPage, setOsaPage] = React.useState(0);
  const [osaPageSize, setOsaPageSize] = React.useState(100);
  const [osaRowCount, setOsaRowCount] = React.useState();

  // ONSHELF MUST HAVE SKU
  const [mustHaveSkuTableLoading, setMustHaveSkuTableLoading] = useState(false);
  const [
    modifiedMustHaveSkuColumnDetails,
    setModifiedMustHaveSkuColumnDetails,
  ] = useState();
  const [mustHaveSkuRows, setMustHaveSkuRows] = useState([]);
  const [mustHaveSkuColumnHeaders, setMustHaveSkuColumnHeaders] = useState([]);
  const [mustHaveSkuPage, setMustHaveSkuPage] = React.useState(0);
  const [mustHaveSkuPageSize, setMustHaveSkuPageSize] = React.useState(100);
  const [mustHaveSkuRowCount, setMustHaveSkuRowCount] = React.useState();

  // CHANNEL FILTER VARIABLES
  const [channelsToShow, setChannelsToShow] = useState([]);
  const [channelSelectedAll, setChannelSelectedAll] = useState(false);
  const [channelFilters, setChannelFilters] = useState([]);

  // REGION FILTER VARIABLES
  const [regionsToShow, setRegionsToShow] = useState([]);
  const [regionSelectedAll, setRegionSelectedAll] = useState(false);
  const [regionFilters, setRegionFilters] = useState([]);

  // AREA FILTER VARIABLES
  const [areasToShow, setAreasToShow] = useState([]);
  const [areaSelectedAll, setAreaSelectedAll] = useState(false);
  const [areaFilters, setAreaFilters] = useState([]);

  // STORE FILTER VARIABLES
  const [storesToShow, setStoresToShow] = useState([]);
  const [storeSelectedAll, setStoreSelectedAll] = useState(false);
  const [storeFilters, setStoreFilters] = useState([]);

  // Territory Filter
  const [territorySelectedAll, setTerritorySelectedAll] = useState(false);
  const [territoryFilters, setTerritoryFilters] = useState([]);
  const [territoryToShow, setTerritoryToShow] = useState([]);

  // availability FILTER VARIABLES
  const [selectedAvailability, setSelectedAvailability] = useState("Yes");
  const [availabilitySelectedAll, setAvailabilitySelectedAll] = useState(false);
  const [availabilityFilters, setavailabilityFilters] = useState(["Yes", "No"]);
  useEffect(() => {
    const tempModifiedOsaColumnDetails = [];
    const tempModifiedMustHaveSkuColumnDetails = [];

    if (osaColumnHeaders) {
      osaColumnHeaders
        .filter(function (column) {
          if (column === "ID") {
            return false; // skip
          }
          return true;
        })
        .map((header) => {
          {
            tempModifiedOsaColumnDetails.push(header);
          }
        });
      setModifiedOsaColumnDetails(tempModifiedOsaColumnDetails);
    }
    if (mustHaveSkuColumnHeaders) {
      mustHaveSkuColumnHeaders
        .filter(function (column) {
          if (column === "ID") {
            return false; // skip
          }
          return true;
        })
        .map((header) => {
          {
            tempModifiedMustHaveSkuColumnDetails.push(header);
          }
        });
      setModifiedMustHaveSkuColumnDetails(tempModifiedMustHaveSkuColumnDetails);
    }
  }, [osaColumnHeaders, mustHaveSkuColumnHeaders]);
  console.log(props?.currentUser?.user, " current user");
  useEffect(() => {
    const getFilterValues = async () => {
      setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/filter_values",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({
          email: props?.currentUser?.user,
          region: "",
        }),
      })
        .then((response) => {
          const responseData = response?.data;
          setChannelFilters(responseData?.channel);
          setRegionFilters(responseData?.region);
          setTerritoryFilters(responseData?.storeTerritory);
          setAreaFilters(responseData?.area);
          setStoreFilters(responseData?.store);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Filtered Values Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    const userLogActivity = async () => {
      setAlertError("");

      await axios({
        method: "post",
        url: "/api/LogUserActivity",
        data: {
          page: "OSA Activity Started",
          email: props?.currentUser?.user,
        },
      })
        .then((response) => {
          console.log(response, "actoivity");
          setLogId(response?.data?.LogId);
        })
        .catch(function (error) {
          console.log("Get Databases Api: ", error);
          setAlertError("something went wrong");
        });
    };
    userLogActivity();
    getFilterValues();
  }, []);

  useEffect(() => {
    const fetchVisits = async () => {
      setTotalStoresLoading(true);
      await axios({
        method: "post",
        url: "/api/no_of_visits",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
         startdate: selectedStartDate
            ? selectedStartDate
            : "",
          enddate: selectedEndDate
            ? selectedEndDate
            : "",
          region: regionsToShow,
          territory: territoryToShow,
          store: storesToShow,
          area: areasToShow,
          channel: channelsToShow,
          email: props?.currentUser?.user,
        }),
      })
        .then((response) => {
          setTotalStoresLoading(false);
          console.log(response, " total stores");
          setTotalStores(response?.data?.count);
        })
        .catch((error) => {
          setTotalStoresLoading(false);
          setAlertError("something went wrong");
          console.log("Get Filters Api: ", error);
        });
    };
    if (selectedStartDate) {
      if (selectedStartDate && selectedEndDate) {
        fetchVisits();
      }
    } else {
      fetchVisits();
    }
  }, [
    props?.currentUser?.user,
    selectedStartDate,
    selectedEndDate,
    territoryToShow,
    storesToShow,
    areasToShow,
    regionsToShow,
    channelsToShow,
  ]);

  useEffect(() => {
    const fetchAllSkuTableData = async () => {
      setOsaTableLoading(true);
      await axios({
        method: "post",
        url: "/api/table_on_shelf_avail",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
         startdate: selectedStartDate
            ? selectedStartDate
            : "",
          enddate: selectedEndDate
            ? selectedEndDate
            : "",
          availability: selectedAvailability,
          territory: territoryToShow,
          store: storesToShow,
          area: areasToShow,
          region: regionsToShow,
          channel: channelsToShow,
          skip: osaPage,
          limit: osaPageSize,
          email: props?.currentUser?.user,
        }),
      })
        .then((response) => {
          setOsaTableLoading(false);
          const responseData = response?.data;
          setOsaRows(responseData?.table);
          setOsaRowCount(responseData?.rowCount);
          setOsaColumnHeaders(responseData?.columns);
          if (logId) {
            console.log("api is called");
            recordLogActivity();
          }
        })
        .catch((error) => {
          setOsaTableLoading(false);
          setAlertError("something went wrong");
          console.log("Get Filters Api: ", error);
        });
    };
    if (selectedStartDate) {
      if (selectedStartDate && selectedEndDate) {
        fetchAllSkuTableData();
      }
    } else {
      fetchAllSkuTableData();
    }
    if (logId) {
      console.log("api is called");
      recordLogActivity();
    }
  }, [
    props?.currentUser?.user,
    osaPage,
    osaPageSize,
    selectedStartDate,
    selectedEndDate,
    territoryToShow,
    storesToShow,
    areasToShow,
    regionsToShow,
    channelsToShow,
    selectedAvailability,
    logId,
  ]);

  const recordLogActivity = async (downloaded, fileName) => {
    setAlertError("");

    await axios({
      method: "post",
      url: "/api/LogUserReportActivity",
      data: {
        startDate: selectedStartDate
          ? moment(selectedStartDate?.$d).format("YYYY-MM-DD")
          : "",
        endDate: selectedEndDate
          ? moment(selectedEndDate?.$d).format("YYYY-MM-DD")
          : "",
        city: "",
        store: "",
        territory: territoryToShow,
        area: areasToShow,
        region: regionsToShow,
        channel: channelsToShow,
        Downloaded: downloaded ? downloaded : 0,
        report: fileName ? fileName : "",
        LogId: logId,
        email: props?.currentUser?.user,
      },
    })
      .then((response) => {
        console.log(response, "activry recorded");
      })
      .catch(function (error) {
        console.log("Get Databases Api: ", error);
        setAlertError("log Activity error");
      });
  };
  useEffect(() => {
    const fetchMustHaveSkuTableData = async () => {
      setMustHaveSkuTableLoading(true);
      await axios({
        method: "post",
        url: "/api/table_OSA_must_have_SKU",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
         startdate: selectedStartDate
            ? selectedStartDate
            : "",
          enddate: selectedEndDate
            ? selectedEndDate
            : "",
          availability: selectedAvailability,
          territory: territoryToShow,
          store: storesToShow,
          area: areasToShow,
          region: regionsToShow,
          channel: channelsToShow,
          skip: mustHaveSkuPage,
          limit: mustHaveSkuPageSize,
          email: props?.currentUser?.user,
        }),
      })
        .then((response) => {
          setMustHaveSkuTableLoading(false);
          const responseData = response?.data;
          setMustHaveSkuRows(responseData?.table);
          setMustHaveSkuRowCount(responseData?.rowCount);
          setMustHaveSkuColumnHeaders(responseData?.columns);
        })
        .catch((error) => {
          setMustHaveSkuTableLoading(false);
          setAlertError("something went wrong");
          console.log("Get Filters Api: ", error);
        });
    };
    if (selectedStartDate) {
      if (selectedStartDate && selectedEndDate) {
        fetchMustHaveSkuTableData();
      }
    } else {
      fetchMustHaveSkuTableData();
    }
  }, [
    props?.currentUser?.user,
    mustHaveSkuPage,
    mustHaveSkuPageSize,
    selectedStartDate,
    selectedEndDate,
    territoryToShow,
    storesToShow,
    areasToShow,
    regionsToShow,
    channelsToShow,
  ]);

  const handleSelectStartDate = (newStartDate) => {
    setSelectedStartDate(newStartDate);
    setSelectedEndDate(null);
    if (newStartDate && !selectedEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else if (!newStartDate && selectedEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };

  const handleSelectEndDate = (newEndDate) => {

    setSelectedEndDate(newEndDate);

    if (!selectedStartDate && newEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else if (selectedStartDate && !newEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };
  return (
    <div className="container-fluid main_container analysis">
      {isLoading || alertError ? (
        <div className="loading_area">
          {alertError && <Alert variant="danger">{alertError}</Alert>}
          {isLoading && !alertError && (
            <div className="col-12 d-flex justify-content-center loader">
              <div
                className="custom_loader spinner-border text-info"
                role="status"
                style={{ zIndex: "2" }}
              ></div>
              <div className="loader-message">Loading...</div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="row align-items-center justify-content-between">
            <div className="col-3 p-0">
              <div className="page_heading">
                <h4 className="page_heading_primary">
                  OSA -
                  <span className="page_heading_secondary">
                    &nbsp;(No. of Visits: {totalStores ? totalStores : 0})
                  </span>
                </h4>
              </div>
            </div>
            <div className="col-4">
              <DownloadCsv
              apiTimeout={840000}
                url={"must_have_sku_availability_report"}
                fileName={"MHSKU_Availability.xlsx"}
                buttonName={"SKU Report"}
                buttonWidth={'150px'}
                data={{ email: props?.currentUser?.user }}
                setReportDownloaded={setReportDownloaded}
                setDownloadReoprtName={setDownloadReoprtName}
                recordLogActivity={recordLogActivity}
              />
            </div>
          </div>
          <div className="filters_row row">
            <CustomDropDownFilters
              filterValues={channelFilters}
              isSelectedAll={channelSelectedAll}
              label="Channel"
              setValuesToShow={setChannelsToShow}
              setSelectedAll={setChannelSelectedAll}
              valuesToShow={channelsToShow ? channelsToShow : []}
            />

            <CustomDropDownFilters
              filterValues={regionFilters}
              isSelectedAll={regionSelectedAll}
              label="Region"
              setValuesToShow={setRegionsToShow}
              setSelectedAll={setRegionSelectedAll}
              valuesToShow={regionsToShow ? regionsToShow : []}
            />
            <CustomDropDownFilters
              filterValues={areaFilters}
              isSelectedAll={areaSelectedAll}
              label="Distribution"
              setValuesToShow={setAreasToShow}
              setSelectedAll={setAreaSelectedAll}
              valuesToShow={areasToShow ? areasToShow : []}
            />
            <CustomDropDownFilters
              filterValues={territoryFilters}
              isSelectedAll={territorySelectedAll}
              label="Territory"
              setValuesToShow={setTerritoryToShow}
              setSelectedAll={setTerritorySelectedAll}
              valuesToShow={territoryToShow ? territoryToShow : []}
            />
            <CustomDropDownFilters
              filterValues={storeFilters}
              isSelectedAll={storeSelectedAll}
              label="Store"
              setValuesToShow={setStoresToShow}
              setSelectedAll={setStoreSelectedAll}
              valuesToShow={storesToShow ? storesToShow : []}
            />
            {/* <Dropdown
              className={`${
                !selectedAvailability
                  ? "dropDown_disabled"
                  : "dropdown_selected"
              }`}
            >
              <Dropdown.Toggle variant="secondary">
                Availability
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {availabilityFilters?.map((month, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={(event) =>
                      setSelectedAvailability(event.target.text)
                    }
                  >
                    {month}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}
            <div className="date-picker-container col-5 d-flex justify-content-end">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{ width: "150px" }}>
                  <DatePicker
                    className={`custom_date_picker ${
                      selectedStartDate && "date_selected"
                    }`}
                    label="From"
                    inputFormat="DD-MMM-YYYY"
                    mask={"__-__-____ __"}
                    value={selectedStartDate}
                    maxDate={selectedEndDate}
                    disableFuture={true}
                    onChange={(date) => handleSelectStartDate(moment(date?.$d).format("YYYY-MM-DD"))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={startDateError}
                        helperText={null}
                        size="small"
                      />
                    )}
                  />
                </div>
                <div style={{ width: "150px" }}>
                  <DatePicker
                    className={`custom_date_picker ${
                      selectedEndDate && "date_selected"
                    }`}
                    label="To"
                    inputFormat="DD-MMM-YYYY"
                    mask={"__-__-____ __"}
                    value={selectedEndDate}
                    minDate={selectedStartDate}
                    disableFuture={true}
                    onChange={(date) => handleSelectEndDate(moment(date?.$d).format("YYYY-MM-DD"))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={endDateError}
                        helperText={null}
                        size="small"
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </div>
          </div>
          {/* <div className="row justify-content-center">
                            <div className="col-3 custom_bg">
                                <div className="d-flex justify-content-between">
                                    <div className="align-self-center">
                                        <FaStore className="count_icon" />
                                    </div>
                                    <div className="media-body text-right">
                                        {totalStoresLoading ?
                                            (
                                                <div className="col-12 d-flex justify-content-center loader">
                                                    <div className="custom_loader spinner-border text-info" role="status" style={{ zIndex: '2' }}>
                                                    </div>
                                                </div>

                                            )
                                            : (
                                                <>
                                                    <span>Audited Stores</span>
                                                    <h6 className='margin'>{totalStores ? totalStores : 0}</h6>
                                                </>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </div> */}
          {/* Data Tables  */}
          <div className="row justify-content-center gap-3">
            <div className="card custom_table col-5">
              <label htmlFor="" className="table_label text-center">
                Must Have SKUs
              </label>
              {mustHaveSkuRows?.length > 0 && (
                <DynamicDataTable
                  rowID={"ID"}
                  columnHeaders={modifiedMustHaveSkuColumnDetails}
                  rows={mustHaveSkuRows}
                  isLoading={mustHaveSkuTableLoading}
                  setPageSize={setMustHaveSkuPageSize}
                  pageSize={mustHaveSkuPageSize}
                  setPage={setMustHaveSkuPage}
                  page={mustHaveSkuPage}
                  rowCount={mustHaveSkuRowCount}
                  hideFooter={true}
                  showDownload={true}
                  // <------>CV DATA
                  setReportDownloaded={setReportDownloaded}
                  setDownloadReoprtName={setDownloadReoprtName}
                  recordLogActivity={recordLogActivity}
                  csvLink={"Excel_OSA_must_have_SKU"}
                  csvName={"Must_have_skus.xlsx"}
                  filters={{
                    startdate: selectedStartDate
                      ? moment(selectedStartDate?.$d).format("YYYY-MM-DD")
                      : "",
                    enddate: selectedEndDate
                      ? moment(selectedEndDate?.$d).format("YYYY-MM-DD")
                      : "",
                    availability: selectedAvailability,
                    area: areasToShow,
                    region: regionsToShow,
                    channel: channelsToShow,
                    email: props?.currentUser?.user,
                  }}
                  // <------>
                />
              )}
            </div>

            <div className="card custom_table col-5">
              <label htmlFor="" className="table_label text-center">
                All SKUs
              </label>
              {osaRows?.length > 0 && (
                <DynamicDataTable
                  rowID={"ID"}
                  columnHeaders={modifiedOsaColumnDetails}
                  rows={osaRows}
                  isLoading={osaTableLoading}
                  setPageSize={setOsaPageSize}
                  pageSize={osaPageSize}
                  setPage={setOsaPage}
                  page={osaPage}
                  rowCount={osaRowCount}
                  hideFooter={true}
                  showDownload={true}
                  // <------>CV DATA
                  setReportDownloaded={setReportDownloaded}
                  setDownloadReoprtName={setDownloadReoprtName}
                  recordLogActivity={recordLogActivity}
                  csvLink={"Excel_on_shelf_avail"}
                  csvName={"All_skus.xlsx"}
                  filters={{
                    startdate: selectedStartDate
                      ? moment(selectedStartDate?.$d).format("YYYY-MM-DD")
                      : "",
                    enddate: selectedEndDate
                      ? moment(selectedEndDate?.$d).format("YYYY-MM-DD")
                      : "",
                    availability: selectedAvailability,
                    area: areasToShow,
                    region: regionsToShow,
                    channel: channelsToShow,
                    email: props?.currentUser?.user,
                  }}
                  // <------>
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Analysis;
