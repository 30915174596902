import { useContext } from "react";
import { createContext, useState } from "react";

const ComplianceContext = createContext()

export const ComplianceProvider = ({ children }) => {
    const [activeTab, setActiveTab] = useState('compliant');

    return (
        <ComplianceContext.Provider value={{
            activeTab,
            setActiveTab
        }}>
            {children}
        </ComplianceContext.Provider>
    )
}

export const useCompliance = () => {
    return useContext(ComplianceContext);
}