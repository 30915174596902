import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

// const optionsArray = [
//   { value: "14", label: "Product" },
//   { value: "15", label: "Price" },
//   { value: "16", label: "Placement" },
//   { value: "17", label: "Promotion" }
// ];

const SingleSelectDropdown = ({ label, optionsArray,setSelectedValue,selectedValue }) => {

  const handleOptionClick = (option) => {
    setSelectedValue(option?.value);
  };
  return (
    <Dropdown className={`${!(selectedValue) ? 'dropDown_disabled' : 'dropdown_selected'}`}>
      <Dropdown.Toggle variant="secondary">
        {label}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {optionsArray?.map(option => (
          <Dropdown.Item
            key={option}
            value={option}
            onClick={() => handleOptionClick(option)}
            className={selectedValue?.label === option.label ? 'active' : ''}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SingleSelectDropdown;
