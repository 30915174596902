import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import "../styles/general.scss";
import "../styles/home.scss";
import { Paper } from "@mui/material";
import CustomDropDownFilters from "./CustomDropDownFilters";
import { useState } from "react";
import moment from "moment";
const CustomSummaryPage = (props) => {
  return (
    <div className="main_container">
      <div className="row align-items-center justify-content-between">
        <div className="page_heading col-3 p-0 mb-2">
          <h4>
            Perfect Stores
            <span className="page_heading_secondary">&nbsp;Summary</span>
          </h4>
        </div>
        {
          // props?.currentUser === "admin@fc.com" &&
          props?.selectedRegion !== "" ? (
            <div className="filters_row row">
              <CustomDropDownFilters
                filterValues={props?.areaFilters}
                isSelectedAll={props?.areaSelectedAll}
                label="Distribution"
                setValuesToShow={props?.setAreasToShow}
                setSelectedAll={props?.setAreaSelectedAll}
                valuesToShow={props?.areasToShow ? props?.areasToShow : []}
              />

              <CustomDropDownFilters
                filterValues={props?.territoryFilters}
                isSelectedAll={props?.territorySelectedAll}
                label="Territory"
                setValuesToShow={props?.setTerritoryToShow}
                setSelectedAll={props?.setTerritorySelectedAll}
                valuesToShow={
                  props?.territoryToShow ? props?.territoryToShow : []
                }
              />
              <div className="date-picker-container col-5 d-flex justify-content-end">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div style={{ width: "150px" }}>
                    <DatePicker
                      className={`custom_date_picker ${
                        props?.selectedStartDate && "date_selected"
                      }`}
                      label="From"
                      inputFormat="DD-MMM-YYYY"
                      mask={"__-__-____ __"}
                      value={props?.selectedStartDate}
                      maxDate={props?.selectedEndDate}
                      disableMaskedInput={true}
                      onChange={(date) => props?.handleSelectStartDate(moment(date?.$d).format("YYYY-MM-DD"))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={props?.startDateError}
                          helperText={null}
                          size="small"
                        />
                      )}
                    />
                  </div>
                  <div style={{ width: "150px" }}>
                    <DatePicker
                      className={`custom_date_picker ${
                        props?.selectedEndDate && "date_selected"
                      }`}
                      label="To"
                      inputFormat="DD-MMM-YYYY"
                      mask={"__-__-____ __"}
                      value={props?.selectedEndDate}
                      minDate={props?.selectedStartDate}
                      disableMaskedInput={true}
                      onChange={(date) => props?.handleSelectEndDate(moment(date?.$d).format("YYYY-MM-DD"))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={props?.endDateError}
                          helperText={null}
                          size="small"
                        />
                      )}
                    />
                  </div>
                </LocalizationProvider>
              </div>
            </div>
          ) : (
            <div className="filters_row row">
              <CustomDropDownFilters
                filterValues={props?.channelFilters}
                isSelectedAll={props?.channelSelectedAll}
                label="Channel"
                setValuesToShow={props?.setChannelsToShow}
                setSelectedAll={props?.setChannelSelectedAll}
                valuesToShow={
                  props?.channelsToShow ? props?.channelsToShow : []
                }
              />

              <CustomDropDownFilters
                filterValues={props?.regionFilters}
                isSelectedAll={props?.regionSelectedAll}
                label="Region"
                setValuesToShow={props?.setRegionsToShow}
                setSelectedAll={props?.setRegionSelectedAll}
                valuesToShow={props?.regionsToShow ? props?.regionsToShow : []}
              />
              <CustomDropDownFilters
                filterValues={props?.areaFilters}
                isSelectedAll={props?.areaSelectedAll}
                label="Distribution"
                setValuesToShow={props?.setAreasToShow}
                setSelectedAll={props?.setAreaSelectedAll}
                valuesToShow={props?.areasToShow ? props?.areasToShow : []}
              />
              <CustomDropDownFilters
                filterValues={props?.territoryFilters}
                isSelectedAll={props?.territorySelectedAll}
                label="Territory"
                setValuesToShow={props?.setTerritoryToShow}
                setSelectedAll={props?.setTerritorySelectedAll}
                valuesToShow={
                  props?.territoryToShow ? props?.territoryToShow : []
                }
              />
              <div className="date-picker-container col-5 d-flex justify-content-end">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div style={{ width: "150px" }}>
                    <DatePicker
                      className={`custom_date_picker ${
                        props?.selectedStartDate && "date_selected"
                      }`}
                      label="From"
                      inputFormat="DD-MMM-YYYY"
                      mask={"__-__-____ __"}
                      value={props?.selectedStartDate}
                      maxDate={props?.selectedEndDate}
                      disableMaskedInput={true}
                      onChange={(date) => props?.handleSelectStartDate(moment(date?.$d).format("YYYY-MM-DD"))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={props?.startDateError}
                          helperText={null}
                          size="small"
                        />
                      )}
                    />
                  </div>
                  <div style={{ width: "150px" }}>
                    <DatePicker
                      className={`custom_date_picker ${
                        props?.selectedEndDate && "date_selected"
                      }`}
                      label="To"
                      inputFormat="DD-MMM-YYYY"
                      mask={"__-__-____ __"}
                      value={props?.selectedEndDate}
                      minDate={props?.selectedStartDate}
                      disableMaskedInput={true}
                      onChange={(date) => props?.handleSelectEndDate(moment(date?.$d).format("YYYY-MM-DD"))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={props?.endDateError}
                          helperText={null}
                          size="small"
                        />
                      )}
                    />
                  </div>
                </LocalizationProvider>
              </div>
            </div>
          )
        }
      </div>

      <div className="row custom_bg p-3">
      <div className="col d-flex justify-content-center align-items-center gap-3">
          <h3 className="medium_heading">
            <strong>Average 4P Compliance</strong>
          </h3>
          <div className="d-flex align-items-center custom_cards">
            <Paper elevation={6} square={true} className="custom_paper">
              <h4 className="percentage_value">
                {props?.productData?.ovr_compliance}%
              </h4>
            </Paper>
          </div>
        </div>
        <div className="col d-flex justify-content-center align-items-center gap-3">
          <h3 className="medium_heading">
            <strong>4P Compliance</strong>
          </h3>
          <div className="d-flex align-items-center custom_cards">
            <Paper elevation={6} square={true} className="custom_paper">
              <h4 className="percentage_value">{props?.complianceData}%</h4>
            </Paper>
          </div>
        </div>
      </div>
      <div className="row main_grid_section">
        <div className="col-6 custom_bg child_grid_section gap-3 p-3">
          <div className="child_grid_section">
            <div class="flag_heading w-25">Product</div>
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="medium_heading">
                <strong>Merchandising Basket Presence</strong>
              </h3>
              <div className="d-flex align-items-center custom_cards">
                <Paper elevation={6} square={true} className="custom_paper">
                  <h4 className="percentage_value">
                    {props?.productData?.merchandise_pct}%
                  </h4>
                </Paper>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="medium_heading">
                <strong>Must Have Sku's Availability</strong>
              </h3>
              <div className="d-flex align-items-center custom_cards">
                <Paper elevation={6} square={true} className="custom_paper">
                  <h4 className="percentage_value">
                    {props?.mustHaveData ? props?.mustHaveData : ""}%
                  </h4>
                </Paper>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="medium_heading">
                <strong>Intruder Detection Percentage</strong>
              </h3>
              <div className="d-flex align-items-center custom_cards">
                <Paper elevation={6} square={true} className="custom_paper">
                  <h4 className="percentage_value">
                    {props?.intruderData?.intruder}%
                  </h4>
                </Paper>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column gap-3">
            <div class="flag_heading w-25">Proposition</div>
            <div className="d-flex justify-content-between">
              <h3 className="medium_heading">
                <strong>Overall Proposition Compliance</strong>
              </h3>
              <h4 className="percentage_value">
                {props?.propositionData?.ovr_pct}%
              </h4>
            </div>
            <div className="custom_cards">
              <Paper
                elevation={6}
                square={true}
                className="custom_paper d-flex w-100 justify-content-around"
              >
                <div className="d-flex flex-column align-items-center">
                  <h4 className="percentage_value">
                    {props?.propositionData?.data[0]?.av_sign}%
                  </h4>
                  <label htmlFor="city" className="city_label">
                    Av Sign
                  </label>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <h4 className="percentage_value">
                    {props?.propositionData?.data[0]?.updated_livery}%
                  </h4>
                  <label htmlFor="city" className="city_label">
                    Updated Livery
                  </label>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <h4 className="percentage_value">
                    {props?.propositionData?.data[0]?.POSM_query}%
                  </h4>
                  <label htmlFor="city" className="city_label">
                    POSM
                  </label>
                </div>
              </Paper>
            </div>
          </div>
        </div>
        <div className="col-5 custom_bg child_grid_section gap-4 p-3">
          <div className="d-flex flex-column gap-3">
            <div className="w-100 d-flex align-items-center justify-content-between">
              <div class="flag_heading w-25">Price</div>
            </div>
            <div className="d-flex justify-content-between">
              <h3 className="medium_heading">
                <strong>Price List Compliance</strong>
              </h3>
              <h4 className="percentage_value">
                {props?.priceData?.overall_percentage}%
              </h4>
            </div>
            <div className="custom_cards">
              <Paper
                elevation={6}
                square={true}
                className="custom_paper d-flex w-100 justify-content-around"
              >
                {props?.priceData?.data?.map((price, index) => (
                  <div
                    key={index}
                    className="d-flex flex-column align-items-center"
                  >
                    <h4 className="percentage_value">{price?.value}%</h4>
                    <label htmlFor="city" className="city_label">
                      {price?.name}
                    </label>
                  </div>
                ))}
              </Paper>
            </div>
          </div>

          <div className="d-flex flex-column gap-3">
            <div className="w-100 d-flex align-items-center justify-content-between">
              <div class="flag_heading w-25">Place</div>
            </div>
            <div className="d-flex justify-content-between">
              <h3 className="medium_heading">
                <strong>Place Compliance</strong>
              </h3>
              <h4 className="percentage_value">
                {props?.placeData?.overall_percentage}%
              </h4>
            </div>
            <div className="custom_cards">
              <Paper
                elevation={6}
                square={true}
                className="custom_paper d-flex w-100 justify-content-around"
              >
                <div className="d-flex flex-column align-items-center">
                  <h4 className="percentage_value">
                    {props?.placeData?.Exit_percentage}%
                  </h4>
                  <label htmlFor="city" className="city_label">
                    Entry/Exit
                  </label>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <h4 className="percentage_value">
                    {props?.placeData?.Counter_percentage}%
                  </h4>
                  <label htmlFor="city" className="city_label">
                    Counter
                  </label>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <h4 className="percentage_value">
                    {props?.placeData?.Category_percentage}%
                  </h4>
                  <label htmlFor="city" className="city_label">
                    Category
                  </label>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <h4 className="percentage_value">
                    {props?.placeData?.Other_percentage}%
                  </h4>
                  <label htmlFor="city" className="city_label">
                    Other
                  </label>
                </div>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomSummaryPage;
