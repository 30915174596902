import React, { useState, useEffect } from 'react';
import LoggedUser from './LoggedUser';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dropdown/style.css';
import '../../styles/photohub/PhotoDetails.css';
import '../../styles/photohub/general.css';
import "../../styles/general.scss";
import "../../styles/home.scss";
import ReactPanZoom from 'react-image-pan-zoom-rotate';

function PhotoDetails(props) {
    const navigate = useNavigate();

    const location = useLocation();
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    let { state } = useLocation();
    const [currentState, setCurrentState] = useState(state);
    const pathname = window.location.pathname;

    /*Image Variables*/
    const [images, setImages] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);
    const [productNames, setProductNames] = useState(null);

    /*Button Variables*/
    const [nextButton, setNextButton] = useState(true);
    const [previousButton, setPreviousButton] = useState(false);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [flag, setFlag] = useState(false);

    /*Metadata Variables*/
    const [visitDate, setVisitDate] = useState("");
    const [visitDataUploadDate, setVisitDataUploadDate] = useState("");
    const [storeName, setStoreName] = useState("");
    const [storeCity, setStoreCity] = useState("");
    const [storeRegion, setStoreRegion] = useState("");
    const [storeChannel, setStoreChannel] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [companyName, setCompanyName] = useState("");

    console.log(state,'this is current sttate')
    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            setErrorMessage(null);
            setProductNames(null)
            if (currentState) {
                setImages(currentState.images);
                setPage(currentState.page);
                setTotalPages(currentState.totalPages);

                if (!flag)
                    setImageIndex(currentState.imageIndex);

                if (currentState.images.length === 1)
                    setNextButton(false);

                if (currentState.imageIndex >= 0 && currentState.page >= 0)
                    setPreviousButton(true);
            }
            else {
                // try {
                //     await fetch('/api/filteredBrandImages', {
                //         method: "POST",
                //         headers: { "Content-Type": "application/json" },
                //         body: JSON.stringify({
                //             country: state.selectedCountry, territory: state.territoryToShow,
                //             store: state.storesToShow,
                //             area: state.areasToShow,
                //             region: state.regionsToShow,
                //             channel: state.channelsToShow, startDate: state.startDate, endDate: state.endDate, page: page, limit: 100
                //         })
                //     })
                //         .then(response => response.json())
                //         .then(data => {
                //             if (data && data.length > 0) {
                //                 setImages(data.images);
                //                 setTotalPages(Math.round(data.length / 100));
                //             }
                //         });
                // }
                // catch (error) {
                //     console.log("Brand Images Api Error: ", error);
                //     setErrorMessage("Internal Server Error! Please try again later.");
                //     setIsLoading(false);
                // }
            }
            try {
                await fetch('/api/OSADetectedSKUs', {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ filename: images && images.length > 0 ? images[imageIndex].filestoragePath : currentState.images[currentState.imageIndex].filestoragePath })
                })
                    .then(response => response.json())
                    .then(data => {
                        const names = data?.imageDetails?.map(item => item.productName);

                        // Setting the state with the extracted product names
                        setProductNames(names);
                        console.log(data, 'response data onsa')
                    });
            }
            catch (error) {
                console.log("Photo Metadata Api Error: ", error);
                setErrorMessage("Internal Server Error! Please try again later.")
                setIsLoading(false);
            }
            try {
                await fetch('/api/photoMetadata', {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ filename: images && images.length > 0 ? images[imageIndex].filestoragePath : currentState.images[currentState.imageIndex].filestoragePath })
                })
                    .then(response => response.json())
                    .then(data => {
                        for (const dataObject of data.metadata) {
                            setVisitDate(moment(dataObject.visitDate).format("yyyy-MM-DD"));
                            setVisitDataUploadDate(moment(dataObject.visitDataUploadDate).format("yyyy-MM-DD"));
                            setStoreName(dataObject.storeName);
                            setStoreCity(dataObject.storeCity);
                            setStoreRegion(dataObject.storeRegion);
                            setStoreChannel(dataObject.storeChannel);
                            setCategoryName(dataObject.categoryName);
                            setCompanyName(dataObject.companyName);
                        }
                    });
            }
            catch (error) {
                console.log("Photo Metadata Api Error: ", error);
                setErrorMessage("Internal Server Error! Please try again later.")
                setIsLoading(false);
            }

            setIsLoading(false);
        }

        fetchData();

        if (pathname.toLowerCase().includes("brand-view"))
            window.history.replaceState(null, "", `/brand-view/photo-details/${imageIndex}`);
        else if (pathname.toLowerCase().includes("rejected-images"))
            window.history.replaceState(null, "", `/photo-details/${imageIndex}`);

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [currentState, flag, imageIndex, page]);
    const next = () => {
        if (imageIndex >= 0 && imageIndex < images.length) {
            const nextImageIndex = imageIndex + 1;

            setImageIndex(nextImageIndex);
            setPreviousButton(true);
            setFlag(true);

            if (nextImageIndex === images.length - 1 && page <= totalPages) {
                setCurrentState(null);
                setNextButton(false);
            }
            else if (nextImageIndex === images.length) {
                const nextPage = page + 1;

                setCurrentState(null);
                setPage(nextPage);
                setImageIndex(0);

                if (nextPage === totalPages) {
                    setNextButton(false);
                }
            }
        }
        else {
            setNextButton(false);
            setPreviousButton(true);
        }
    }

    const previous = () => {
        if (page >= 0) {
            const previousImageIndex = imageIndex - 1;

            setImageIndex(previousImageIndex);
            setNextButton(true);
            setFlag(true);

            if (previousImageIndex === 0 && page === 0) {
                setCurrentState(null);
                setPreviousButton(false);
            }
            else if (previousImageIndex < 0) {
                const previousPage = page - 1;

                setCurrentState(null);
                setPage(previousPage);
                setImageIndex(0);

                if (previousPage === 0) {
                    setPreviousButton(false);
                }
            }
        }
        else {
            setNextButton(true);
            setPreviousButton(false);
        }
    }




    const navigateToPreviousPage = () => {
        window.history.back();
    };
    return (
        <div className="container-fluid main_container">
            {!props?.currentUser?.user ? (
                <Navigate to="/login" replace={true} />
            ) : !state ? (
                <Navigate to="/" replace={true} />
            ) : (

                <div>
                    {/* <LoggedUser logOut = {props.logOut}/> */}
                    <div className="row align-items-center justify-content-between mb-1">
                        <div className="page_heading col-5 p-0">
                            <h4 className="page_heading_primary">
                            {`${window.location.pathname.includes("rejected-image-detail")?'Rejected':
                                window.location.pathname.includes("non_compliant-image-detail")?'Non-Compliant':'Compliant'}`}
                                <span className="page_heading_secondary">&nbsp;Image</span>
                            </h4>
                        </div>
                        <div className="col-2">
                            <button className="button_primary" onClick={navigateToPreviousPage}>
                                Back
                            </button>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="d-flex justify-content-center">
                            <br />
                            <br />
                            <div className="spinner-border text-info" role="status"></div>
                        </div>
                    ) : errorMessage ? (
                        <span>{errorMessage}</span>
                    ) : images && images.length > 0 && (
                        <>
                            <div className="photoDetailContainer row">
                                <div className="scrollButtons m-0">
                                    <button className="pagination_next d-flex gap-3" style={{ width: 'unset' }}>
                                        <NavigateBeforeIcon
                                            className={`icon ${!previousButton ? 'disabled_button' : ''}`}
                                            onClick={previous}
                                            disabled={!previousButton}
                                        />
                                        <NavigateNextIcon
                                            className={`icon ${!nextButton ? 'disabled_button' : ''}`}
                                            onClick={next}
                                            disabled={!nextButton}
                                        />
                                    </button>
                                </div>
                                <div className='col-8'>
                                    <ReactPanZoom image={"https://storage.googleapis.com/staging-api-uploads/" + images[imageIndex].filestoragePath} alt="Image alt text" />

                                    {/* <img src={"https://storage.googleapis.com/staging-api-uploads/" + images[imageIndex].filestoragePath} alt="" width="500px" height="470px" style={{ objectFit: '', margin: 'auto', display: 'block' }} /> */}


                                </div>
                                <div className='col'>
                                    <div className="r_metadata onShelf_data d-flex flex-column">
                                        <span style={{ fontWeight: '600' }}>On Shelf Availability</span>
                                            <ul className='osa_list'>
                                        {productNames && productNames.length > 0 ? (
                                                productNames.map((productName, index) => (
                                                    <li key={index}>{productName}</li>
                                                ))
                                                ) : (
                                                    <p>No labels found.</p>
                                                )}
                                            </ul>
                                    </div>

                                </div>
                            
                            </div>
                            <div className='row justify-content-center'>
                                <div className='col-md-12'>
                                    <div className="r_metadata">
                                        <div className='row w-100 justify-content-center text-center'>
                                            <span style={{ fontWeight: '600' }}>Photo's Metadata</span>
                                        </div>
                                        <div className="row w-100 justify-content-center gap-5" style={{ fontSize: '14px', marginTop: '0.5em' }}>
                                            <div className='col-4'>
                                                <div className="d-flex justify-content-between">
                                                    <span style={{ fontWeight: '500' }}>Visit Date:</span>
                                                    {visitDate ? visitDate : "NA"}
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <span style={{ fontWeight: '500' }}>Upload Date:</span>
                                                    {visitDataUploadDate ? visitDataUploadDate : "NA"}
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <span style={{ fontWeight: '500' }}>Store Name:</span>
                                                    {storeName ? storeName : "NA"}
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <span style={{ fontWeight: '500' }}>Store City:</span>
                                                    {storeCity ? storeCity : "NA"}
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <div className="d-flex justify-content-between">
                                                    <span style={{ fontWeight: '500' }}>Store Region:</span>
                                                    {storeRegion ? storeRegion : "NA"}
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <span style={{ fontWeight: '500' }}>Store Channel:</span>
                                                    {storeChannel ? storeChannel : "NA"}
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <span style={{ fontWeight: '500' }}>Category Name:</span>
                                                    {categoryName ? categoryName : "NA"}
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <span style={{ fontWeight: '500' }}>Company Name:</span>
                                                    {companyName ? companyName : "NA"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>


                    )}
                </div>
            )}
        </div>
    );
}

export default PhotoDetails;