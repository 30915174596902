import React, { useRef, useEffect } from 'react';

const Canvas = (props) => 
{  
    const { draw, width, height } = props;
    const canvasRef = useRef(null);
    
    useEffect(() => 
    {    
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        
        const render = () => 
        {
            draw(context);
        }

        render();

    }, [draw]);
    
    return <canvas ref = {canvasRef} {...props} width = {width} height = {height}/>
}

export default Canvas;