import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

// const optionsArray = ["Product", "Price", "Placement"];

const SingleSelectDropdownSimpleArray = ({ label, optionsArray,setSelectedValue,selectedValue }) => {

  const handleOptionClick = (value) => {
    setSelectedValue(value);
  };

  return (
    <Dropdown className={`${!(selectedValue) ? 'dropDown_disabled' : 'dropdown_selected'}`}>
      <Dropdown.Toggle variant="secondary">
        { label}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {optionsArray.map(option => (
          <Dropdown.Item
            key={option}
            onClick={() => handleOptionClick(option)}
            className={selectedValue === option ? 'active' : ''}
          >
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SingleSelectDropdownSimpleArray;
