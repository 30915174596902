import React, { useEffect, useState } from "react";
// import "../../styles/General.css";
import "../../styles/expandableTable.css";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";
import Papa from "papaparse";

const UsageLog = (props) => {
  // DATE FILTERS VARIABLES
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isSubTableLoading, setIsSubTableLoading] = useState(false);
  const [mainTableLoading, setMainTableLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [subRows, setSubRows] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  useEffect(() => {
    const fetchData = async () => {
      setData([]);
      setMainTableLoading(true);
      try {
        const response = await axios.post(
          "/api/getUserLogs",
          {
            startDate: selectedStartDate
              ? moment(selectedStartDate?.$d).format("YYYY-MM-DD")
              : "",
            endDate: selectedEndDate
              ? moment(selectedEndDate?.$d).format("YYYY-MM-DD")
              : "",
          }
        );
        setData(response?.data?.rowData || []);
        setMainTableLoading(false);
        console.log(response?.data, "jljfd");
      } catch (error) {
        setMainTableLoading(false);
        console.log("Get All User Names Api:", error);
      }
    };
    if (selectedStartDate) {
      if (selectedStartDate && selectedEndDate) {
        fetchData();
      }
    }
  }, [
    selectedStartDate,
    selectedEndDate,
  ]);

  useEffect(() => {
    setFilteredData(
      data.filter((row) =>
        row["User Email"]?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [data, searchQuery]);

  const toggleSubTable = async (rowId, pageAccessed) => {
    let apiUrl;
      apiUrl = "/api/getUserReportLogs";
    const isExpanded = expandedRows.includes(rowId);
    if (isExpanded) {
      setIsExpanded(true);
      setExpandedRows(expandedRows.filter((id) => id !== rowId));
      if (subRows && subRows.length > 0) {
        setSubRows(null);
      }
    } else {
      const mainRow = data.find((row) => row['Log ID'] === rowId);
      if (mainRow) {
        setIsExpanded(false);
        setExpandedRows([...expandedRows, rowId]);
        try {
          setIsSubTableLoading(true);
          const response = await axios.post(
            apiUrl,
            { LogId: rowId },
          );
          setIsSubTableLoading(false);
          console.log(response, "sub data:");
          const subTableData = response?.data?.rowData || [];
          if (subTableData.length === 0) {
            setExpandedRows(expandedRows.filter((id) => id !== rowId));
          }
          const updatedData = data.map((row) =>
            row['Log ID'] === rowId ? { ...row, subTableData } : row
          );
          setData(updatedData);
          setSubRows(subTableData);
        } catch (error) {
          setIsSubTableLoading(false);
          console.log("Get User Report Logs API:", error);
        }
      }
    }
  };

  const handleSearchQueryChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const renderRowsPerPageOptions = () => {
    const options = [25, 50, 100]; // Change the options as per your requirements
    return options.map((option) => (
      <option value={option} key={option}>
        {option}
      </option>
    ));
  };

  const tableHeaders = Object.keys(data[0] || {}).filter(
    (key) => key !== "subTableData"
  );
console.log(tableHeaders,'table headers')
  const getCellAlignment = (value) => {
    if (typeof value === "number") {
      return "table_align_right";
    } else {
      return "table_align_left";
    }
  };

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const handleSelectStartDate = (newStartDate) => {
    setSelectedStartDate(newStartDate);
    setSelectedEndDate("");

    if (newStartDate && !selectedEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else if (!newStartDate && selectedEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };

  const handleSelectEndDate = (newEndDate) => {
    setSelectedEndDate(newEndDate);

    if (!selectedStartDate && newEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else if (selectedStartDate && !newEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };
  useEffect(() => {
    setDefaultDates();
  }, []);
  const setDefaultDates = () => {
    const today = dayjs(); // Get today's date
    setSelectedStartDate(today);
    setSelectedEndDate(today);
  };
  const downloadCSV = () => {
    // Convert data to CSV format using Papa
    const csvData = Papa.unparse(filteredData);

    // Create a Blob with the CSV data
    const blob = new Blob([csvData], { type: "text/csv" });

    // Create a temporary URL to the Blob
    const url = window.URL.createObjectURL(blob);

    // Create an <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "usage_log.csv"; // Set the filename for the downloaded file
    a.click();

    // Clean up by revoking the URL
    window.URL.revokeObjectURL(url);
  };
  return (
    <div className="expandale_table">
      <div className="main_container">
        <div className="filters_row row justify-content-between flex-sm-column flex-md-row">
          <div className="col-md-3">
            <input
              type="search"
              className="form-control"
              value={searchQuery}
              onChange={handleSearchQueryChange}
              placeholder="Search by email"
              autoComplete="off"
            />
          </div>
          <div className="col d-flex justify-content-end">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{width:'150px'}}>
              <DatePicker
                className={`custom_date_picker ${selectedStartDate && "date_selected"}`}
                label="From"
                inputFormat="DD-MMM-YYYY"
                mask={"__-__-____ __"}
                value={selectedStartDate}
                maxDate={selectedEndDate}
                disableFuture={true}
                onChange={handleSelectStartDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={startDateError}
                    helperText={null}
                    size="small"
                  />
                )}
              />
              </div>
              <div style={{width:'150px'}}>
              <DatePicker
                className={`custom_date_picker ${selectedEndDate && "date_selected"}`}
                label="To"
                inputFormat="DD-MMM-YYYY"
                mask={"__-__-____ __"}
                value={selectedEndDate}
                minDate={selectedStartDate}
                disableFuture={true}
                onChange={handleSelectEndDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={endDateError}
                    helperText={null}
                    size="small"
                  />
                )}
              />
              </div>
            </LocalizationProvider>
          </div>
        </div>
        <div
          className="custom_card p-3 content_container d-flex flex-column"
          style={{ marginTop: "0" }}
        >
            <button className="btn csv_button ml-auto" onClick={downloadCSV}>
              Download CSV
            </button>
          {data?.length < 0 ? (
            <div className="d-flex justify-content-center">
              <br />
              <div className="spinner-border text-info" role="status"></div>
            </div>
          ) : (
            <>
              <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto" }}>
                <table className="main_table table-responsive table table-hover table-bordered">
                  <thead className="thead-main">
                    <tr>
                      {tableHeaders.map((header) => (
                        <th
                          key={header}
                          className="expandable_table_fixed_width"
                          style={{ width: "auto" }}
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {filteredData.length > 0 ? (
                    <tbody>
                      {filteredData
                        .slice(
                          (currentPage - 1) * itemsPerPage,
                          currentPage * itemsPerPage
                        )
                        .map((mainRow) => (
                          <React.Fragment key={mainRow['Log ID']}>
                            <tr
                              onClick={() =>
                                toggleSubTable(mainRow['Log ID'], mainRow?.Detail)
                              }
                              className={`${
                                mainRow?.Detail === "Dashboard Accessed"
                                  ? "unclickable"
                                  : ""
                              }`}
                            >
                              {tableHeaders.map((header, index) => (
                                <td
                                  key={header}
                                  className={`expandable_table_fixed_width ${getCellAlignment(
                                    mainRow[header]
                                  )}`}
                                  style={{
                                    width: index === 0 ? "100px" : "auto",
                                  }}
                                >
                                  {mainRow[header]}
                                </td>
                              ))}
                            </tr>
                            {isSubTableLoading && !mainRow?.subTableData && (
                              <tr
                                className={`${
                                  expandedRows.includes(mainRow['Log ID'])
                                    ? "collapseRow"
                                    : "unCollapseRow"
                                }`}
                              >
                                <td
                                  colSpan={tableHeaders.length}
                                  className="collapsedRow_bg"
                                >
                                  <div className="d-flex justify-content-center">
                                    <br />
                                    <div
                                      className="spinner-border text-info"
                                      role="status"
                                    ></div>
                                  </div>
                                </td>
                              </tr>
                            )}

                            {mainRow?.subTableData !== undefined && (
                              <tr
                                className={`${
                                  expandedRows.includes(mainRow['Log ID'])
                                    ? "collapseRow"
                                    : "unCollapseRow"
                                }`}
                              >
                                <td
                                  colSpan={tableHeaders.length}
                                  className="collapsedRow_bg"
                                >
                                  <div className="subTable_container">
                                    {mainRow?.subTableData && (
                                      <table className="sub_table table tble table-bordered table-hover table-striped">
                                        <thead className="thead-secondary">
                                          <tr className="row w-100">
                                            {Object.keys(
                                              mainRow?.subTableData[0] || {}
                                            ).map((subValue) => (
                                              <th
                                                className="col"
                                                key={subValue}
                                              >
                                                {subValue}
                                              </th>
                                            ))}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {mainRow?.subTableData.map(
                                            (subRow) => (
                                              <tr
                                                className="row w-100"
                                                key={subRow.id}
                                              >
                                                {Object.values(subRow).map(
                                                  (subValue) => (
                                                    <td
                                                      className="col"
                                                      key={subValue}
                                                    >
                                                      {subValue}
                                                    </td>
                                                  )
                                                )}
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                    </tbody>
                  ) : (
                    <>
                      {mainTableLoading ? (
                        <tbody>
                          <tr>
                            <td colSpan={tableHeaders.length}>
                              <div className="d-flex justify-content-center">
                                <br />
                                <div
                                  className="spinner-border text-info"
                                  role="status"
                                ></div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={tableHeaders.length}>
                              No records found.
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </>
                  )}
                </table>
              </div>
              {filteredData.length > 0 && (
                <div className="pagination_container">
                  <div className="items_per_page">
                    <span>Rows per Page:</span>
                    <select
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                    >
                      {renderRowsPerPageOptions()}
                    </select>
                  </div>
                  <ReactPaginate
                    previousLabel="Prev"
                    nextLabel="Next"
                    pageCount={pageCount}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UsageLog;
