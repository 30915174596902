import React, { useState } from "react";
import { Dropdown, FormControl } from "react-bootstrap";
import "../styles/general.scss";

const CustomMultiSelectWithOptionValue = ({
  filterValues,
  isSelectedAll,
  label,
  valuesToShow,
  setValuesToShow,
  setSelectedAll,
  showSearchBar,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const checkBoxFilterHandle = (e, value) => {
    const { checked } = e.target;
    if (checked) {
      const checkedValue = valuesToShow.includes(value.value);

      if (!checkedValue) {
        const tempFilters = [...valuesToShow, value.value];
        setValuesToShow(tempFilters);

        if (tempFilters.length === filterValues.length) {
          setSelectedAll(true);
        }
      }
    } else {
      const uncheckedValue = valuesToShow.filter((element) => {
        return element !== value.value;
      });

      setValuesToShow(uncheckedValue);
      setSelectedAll(false);
    }
  };

  const selectAll = (e) => {
    if (!e.target.checked) {
      setValuesToShow([]);
    } else {
      const filterArray = filterValues.map((value) => value.value);
      setValuesToShow(filterArray);
    }

    setSelectedAll(e.target.checked);
  };

  const filteredValues = filterValues?.filter((value) =>
    value.label.toLowerCase().includes(searchTerm.toLowerCase())
  );
console.log(filterValues,'filterValues')
  return (
    <Dropdown
      className={`${
        !(valuesToShow.length > 0) ? "dropDown_disabled" : "dropdown_selected"
      }`}
    >
      <Dropdown.Toggle variant="secondary">{label}</Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="p-3 d-flex flex-column" style={{ gap: "5px" }}>
          {showSearchBar && (
            <FormControl
              type="text"
              placeholder="Search..."
              className="mb-3"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          )}
          <div className="d-flex" style={{ gap: "0 8px" }}>
            <input
              type="checkbox"
              name="selectAll"
              checked={isSelectedAll}
              onChange={(e) => selectAll(e)}
            />
            <label htmlFor="selectAll">Select All</label>
          </div>

          {filteredValues?.map((value, index) => (
            <div key={index} className="d-flex" style={{ gap: "0 10px" }}>
              <input
                type="checkbox"
                value={value.value}
                onChange={(e) => checkBoxFilterHandle(e, value)}
                checked={valuesToShow.includes(value.value) ? true : false}
              />
              <label style={{ fontSize: "12px" }}>{value.label}</label>
            </div>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomMultiSelectWithOptionValue;
