import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { setRole } from '../actions/userRole';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import ProtectedRoute from './ProtectedRoute';
import AdminPanel from '../adminPanel/AdminPanel';
import Login from '../pages/authorizeUser/Login';
import ResetPassword from '../pages/authorizeUser/ResetPassword';
import ChangePassword from '../pages/authorizeUser/ChangePassword';
import PageNotFound from '../PageNotFound';
import Summary from '../pages/Homepage/Summary';
import FourP_Compliance from '../pages/Homepage/FourP_Compliance';
import Analysis from '../pages/Homepage/Analysis';
import PlanogramCompliance from '../pages/Homepage/PlanogramCompliance';
import PlanogramComplianceExt from '../pages/Homepage/PlanogramComplianceExt';
import ShareOfFreezer from '../pages/Homepage/ShareOfFreezer';
import PriceList from '../pages/Homepage/PriceList';
import PriceExt from '../pages/Homepage/PriceExt';
import FreezerPlacement from '../pages/Homepage/FreezerPlacement';
import FreezerPurity from '../pages/Homepage/FreezerPurity';
import OsaExt from '../pages/Homepage/OsaExt';
import VisibilityOfPropositions from '../pages/Homepage/VisibilityOfPropositions';
import BrandView from '../pages/PhotoHub/BrandView';
import PhotoDetails from '../pages/PhotoHub/PhotoDetails';
import Realogram from '../pages/PhotoHub/Realogram';
import MasterData from '../pages/PhotoHub/MasterData';
import PhotoStatistics from '../pages/PhotoHub/PhotoStatistics';
import RejectedImages from '../pages/PhotoHub/RejectedImages';
import VisitData from '../pages/PhotoHub/VisitData';
import UsageLog from '../pages/Admin/UsageLog';
import Compliance from '../pages/PhotoHub/Compliance';
import AuditorRoute from '../pages/PhotoHub/AuditorRoute';
import { ComplianceProvider } from '../pages/PhotoHub/Context/ComplianceContext';
import RealogramImages from '../pages/PhotoHub/RealogramImages';

function RouterLinks(props) {
    const [currentUser, setCurrentUser] = useState(props?.userRole);
    const navigate = useNavigate();
    const dispatch = useDispatch();



    const handleLogout = () => {
        if (currentUser) {
            setCurrentUser(null);
            dispatch(setRole(0, null, null));
            localStorage.setItem("IsLoggedOut", true);
            navigate("/login");
        }
    }

    return (
        <ComplianceProvider>
            <Routes>
                <Route path="/login" element={
                    <Login currentUser={currentUser} setCurrentUser={setCurrentUser} />}
                />

                <Route path="/login/reset-password" element={
                    <ResetPassword currentUser={currentUser} />}
                />

                <Route path="/login/change-password/user/:id" element={
                    <ChangePassword currentUser={currentUser} />}
                />

                <Route element={<ProtectedRoute isAllowed={!!currentUser?.user} />}>
                    <Route path="/" element={
                        <AdminPanel currentUser={currentUser} component={<Summary currentUser={currentUser} />} logOut={handleLogout} />
                    } />

                    <Route path="/home/summary" element={
                        <AdminPanel currentUser={currentUser} component={<Summary currentUser={currentUser} />} logOut={handleLogout} />
                    } />
                    <Route path="/home/4p_compliance" element={
                        <AdminPanel currentUser={currentUser} component={<FourP_Compliance currentUser={currentUser} />} logOut={handleLogout} />
                    } />
                    <Route path="/home/osa" element={
                        <AdminPanel currentUser={currentUser} component={<Analysis currentUser={currentUser} />} logOut={handleLogout} />
                    } />
                    <Route path="/home/osa-ext" element={
                        <AdminPanel currentUser={currentUser} component={<OsaExt currentUser={currentUser} />} logOut={handleLogout} />
                    } />
                    <Route path="/home/planogram" element={
                        <AdminPanel currentUser={currentUser} component={<PlanogramCompliance currentUser={currentUser} />} logOut={handleLogout} />
                    } />
                    <Route path="/home/planogramExt" element={
                        <AdminPanel currentUser={currentUser} component={<PlanogramComplianceExt currentUser={currentUser} />} logOut={handleLogout} />
                    } />
                    <Route path="/home/SOF" element={
                        <AdminPanel currentUser={currentUser} component={<ShareOfFreezer currentUser={currentUser} />} logOut={handleLogout} />
                    } />
                    <Route path="/home/price-list" element={
                        <AdminPanel currentUser={currentUser} component={<PriceList currentUser={currentUser} />} logOut={handleLogout} />
                    } />
                    <Route path="/home/price-ext" element={
                        <AdminPanel currentUser={currentUser} component={<PriceExt currentUser={currentUser} />} logOut={handleLogout} />
                    } />
                    <Route path="/home/freezer-placement" element={
                        <AdminPanel currentUser={currentUser} component={<FreezerPlacement currentUser={currentUser} />} logOut={handleLogout} />
                    } />
                    <Route path="/home/freezer-purity" element={
                        <AdminPanel currentUser={currentUser} component={<FreezerPurity currentUser={currentUser} />} logOut={handleLogout} />
                    } />
                    <Route path="/home/propositions-visibility" element={
                        <AdminPanel currentUser={currentUser} component={<VisibilityOfPropositions currentUser={currentUser} />} logOut={handleLogout} />
                    } />
                    <Route
                        path="/home/auditor-route"
                        element={
                            <AdminPanel
                                currentUser={currentUser}
                                component={<AuditorRoute currentUser={currentUser} />}
                                logOut={handleLogout}
                            />
                        }
                    />
                    <Route path="/admin/usage-log" element={
                        <AdminPanel currentUser={currentUser} component={<UsageLog currentUser={currentUser} />} logOut={handleLogout} />
                    } />
                    <Route
                        path="/photoHub/brand-view"
                        element={
                            <AdminPanel
                                currentUser={currentUser}
                                component={<BrandView currentUser={currentUser} />}
                                logOut={handleLogout}
                            />
                        }
                    />

                    <Route
                        path="/photoHub/brand-view/photo-details/:id"
                        element={
                            <AdminPanel
                                currentUser={currentUser}
                                component={<PhotoDetails currentUser={currentUser} />}
                                logOut={handleLogout}
                            />
                        }
                    />


                    <Route
                        path="/photoHub/realogram"
                        element={
                            <AdminPanel
                                currentUser={currentUser}
                                component={<RealogramImages currentUser={currentUser} />}
                                logOut={handleLogout}
                            />
                        }
                    />
                    <Route
                        path="/photoHub/realogram-image-details/:id"
                        element={
                            <AdminPanel
                                currentUser={currentUser}
                                component={<Realogram currentUser={currentUser} />}
                                logOut={handleLogout}
                            />
                        }
                    />
                    <Route
                        path="/photoHub/master-data"
                        element={
                            <AdminPanel
                                currentUser={currentUser}
                                component={<MasterData currentUser={currentUser} />}
                                logOut={handleLogout}
                            />
                        }
                    />

                    <Route
                        path="/photoHub/photo-statistics"
                        element={
                            <AdminPanel
                                currentUser={currentUser}
                                component={<PhotoStatistics currentUser={currentUser} />}
                                logOut={handleLogout}
                            />
                        }
                    />

                    <Route
                        path="/photoHub/rejected-images"
                        element={
                            <AdminPanel
                                currentUser={currentUser}
                                component={<RejectedImages currentUser={currentUser} />}
                                logOut={handleLogout}
                            />
                        }
                    />

                    <Route
                        path="/photoHub/rejected-image-details/:id"
                        element={
                            <AdminPanel
                                currentUser={currentUser}
                                component={<PhotoDetails currentUser={currentUser} />}
                                logOut={handleLogout}
                            />
                        }
                    />
                    <Route
                        path="/photoHub/compliant-image-details/:id"
                        element={
                            <AdminPanel
                                currentUser={currentUser}
                                component={<PhotoDetails currentUser={currentUser} />}
                                logOut={handleLogout}
                            />
                        }
                    />
                    <Route
                        path="/photoHub/non_compliant-image-details/:id"
                        element={
                            <AdminPanel
                                currentUser={currentUser}
                                component={<PhotoDetails currentUser={currentUser} />}
                                logOut={handleLogout}
                            />
                        }
                    />
                    <Route
                        path="/photoHub/visit-data"
                        element={
                            <AdminPanel
                                currentUser={currentUser}
                                component={<VisitData currentUser={currentUser} />}
                                logOut={handleLogout}
                            />
                        }
                    />
                    <Route
                        path="/photoHub/compliance"
                        element={
                            <AdminPanel
                                currentUser={currentUser}
                                component={
                                    <Compliance currentUser={currentUser} />
                                }
                                logOut={handleLogout}
                            />
                        }
                    />


                    {/* <Route path="/photoHub//item2/subItem" element={
                    <AdminPanel currentUser={currentUser} component={<ChartView />} logOut={handleLogout} />
                } /> */}



                </Route>

                <Route path="/*" element={<PageNotFound />} />
            </Routes>
        </ComplianceProvider>
    );
}

const mapStateToProps = state => {
    return {
        userRole: state.userRole
    };
}

export default connect(mapStateToProps)(RouterLinks);