import React, { useEffect, useState } from "react";
import "../../styles/general.scss";
import "../../styles/home.scss";
import axios from "axios";
import { Alert, Tabs, Tab } from "react-bootstrap";
import moment from "moment/moment";
import CustomSummaryPage from "../../components/CustomSummaryPage";

const Summary = (props) => {
  // const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);

  /* 4P COMPLIANCE DATE RANGE VARIABLES */
  const [productData, setProductData] = useState(null);
  const [compliance, setCompliance] = useState(null);
  const [mustHaveData, setMustHaveData] = useState(null);
  // INTRUDER DETECTION VARIABLE
  const [intruderData, setIntruderData] = useState(null);

  /* MERCHANDISE DATE RANGE VARIABLES */
  const [merchandiseData, setMerchandiseData] = useState(null);

  /* PROPOSITION COMPLIANCE DATE RANGE VARIABLES */
  const [propositionData, setPropositionData] = useState(null);

  /* PRICE COMPLIANCE DATE RANGE VARIABLES */
  const [priceData, setPriceData] = useState(null);

  /* PLACE COMPLIANCE DATE RANGE VARIABLES */
  const [placeData, setPlaceData] = useState(null);

  // DATE FILTERS VARIABLES
 // DATE FILTERS VARIABLES
 const [selectedStartDate, setSelectedStartDate] = useState(moment().startOf('month').format("YYYY-MM-DD"));
const [selectedEndDate, setSelectedEndDate] = useState(moment().format("YYYY-MM-DD"));


  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  // FILTER VARIABLES
  const [selectedRegion, setSelectedRegion] = useState("");
  const [regionFilters, setRegionFilters] = useState(null);

  // CHANNEL FILTER VARIABLES
  const [channelsToShow, setChannelsToShow] = useState([]);
  const [channelSelectedAll, setChannelSelectedAll] = useState(false);
  const [channelFilters, setChannelFilters] = useState([]);

  // REGION FILTER VARIABLES
  const [regionsToShow, setRegionsToShow] = useState([]);
  const [regionSelectedAll, setRegionSelectedAll] = useState(false);

  // AREA FILTER VARIABLES
  const [areasToShow, setAreasToShow] = useState([]);
  const [areaSelectedAll, setAreaSelectedAll] = useState(false);
  const [areaFilters, setAreaFilters] = useState([]);

  // STORE FILTER VARIABLES
  const [storesToShow, setStoresToShow] = useState([]);
  const [storeSelectedAll, setStoreSelectedAll] = useState(false);
  const [storeFilters, setStoreFilters] = useState([]);

  // Territory Filter
  const [territorySelectedAll, setTerritorySelectedAll] = useState(false);
  const [territoryFilters, setTerritoryFilters] = useState([]);
  const [territoryToShow, setTerritoryToShow] = useState([]);

console.log(selectedStartDate, selectedEndDate,'datesss')

  useEffect(() => {
    const getFilterValues = async () => {
      // setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/filter_values",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({
          email: props?.currentUser?.user,
          region: selectedRegion === "" ? regionsToShow : selectedRegion,
        }),
      })
        .then((response) => {
          const responseData = response?.data;
          let regions = responseData?.region;
          console.log(response, "filters values");
          regions.unshift("All Regions");
          setRegionFilters(regions);
          setChannelFilters(responseData?.channel);
          setTerritoryFilters(responseData?.storeTerritory);
          setAreaFilters(responseData?.area);
          setStoreFilters(responseData?.store);

          // setIsLoading(false);
        })
        .catch((error) => {
          console.log("Filtered Values Api: ", error);
          // setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    getFilterValues();
  }, [selectedRegion, props?.currentUser?.user]);
  useEffect(() => {
    setAlertError(null);

    const getProductData = async () => {
      // setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/product",
        headers: { "Content-Type": "application/json" },
        timeout:120000,
        data: JSON.stringify({
          startdate: selectedStartDate
            ? selectedStartDate
            : "",
          enddate: selectedEndDate
            ? selectedEndDate
            : "",
          email: props?.currentUser?.user,
          region: selectedRegion === "" ? regionsToShow : selectedRegion,
          territory: territoryToShow,
          area: areasToShow,
          channel: channelsToShow,
        }),
      })
        .then((response) => {
          const responseData = response?.data;
          setProductData(responseData);

          // setIsLoading(false);
        })
        .catch((error) => {
          console.log("Filtered Values Api: ", error);
          // setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    const getCompliantPer = async () => {
      // setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/compliant_per",
        headers: { "Content-Type": "application/json" },
        timeout:120000,
        data: JSON.stringify({
          startdate: selectedStartDate
            ? selectedStartDate
            : "",
          enddate: selectedEndDate
            ? selectedEndDate
            : "",
          email: props?.currentUser?.user,
          region: selectedRegion === "" ? regionsToShow : selectedRegion,
          territory: territoryToShow,
          area: areasToShow,
          channel: channelsToShow,
        }),
      })
        .then((response) => {
          console.log(response, "compliance");
          const responseData = response?.data;
          setCompliance(responseData?.compliance);
        })
        .catch((error) => {
          console.log("Filtered Values Api: ", error);
          // setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    const getMustHaveData = async () => {
      // setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/mustHave",
        headers: { "Content-Type": "application/json" },
        timeout:120000,
        data: JSON.stringify({
          startdate: selectedStartDate
            ? selectedStartDate
            : "",
          enddate: selectedEndDate
            ? selectedEndDate
            : "",
          email: props?.currentUser?.user,
          region: selectedRegion === "" ? regionsToShow : selectedRegion,
          territory: territoryToShow,
          area: areasToShow,
          channel: channelsToShow,
        }),
      })
        .then((response) => {
          const responseData = response?.data;
          console.log(responseData, " must have");
          setMustHaveData(responseData?.mustHave);

          // setIsLoading(false);
        })
        .catch((error) => {
          console.log("Filtered Values Api: ", error);
          // setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    const getIntruderData = async () => {
      // setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/intruder",
        headers: { "Content-Type": "application/json" },
        timeout:120000,
        data: JSON.stringify({
          startdate: selectedStartDate
            ? selectedStartDate
            : "",
          enddate: selectedEndDate
            ? selectedEndDate
            : "",
          email: props?.currentUser?.user,
          region: selectedRegion === "" ? regionsToShow : selectedRegion,
          territory: territoryToShow,
          area: areasToShow,
          channel: channelsToShow,
        }),
      })
        .then((response) => {
          const responseData = response?.data;
          setIntruderData(responseData);

          // setIsLoading(false);
        })
        .catch((error) => {
          console.log("Filtered Values Api: ", error);
          // setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    const getPrice = async () => {
      // setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/price",
        headers: { "Content-Type": "application/json" },
        timeout:120000,
        data: JSON.stringify({
          startdate: selectedStartDate
            ? selectedStartDate
            : "",
          enddate: selectedEndDate
            ? selectedEndDate
            : "",
          email: props?.currentUser?.user,
          region: selectedRegion === "" ? regionsToShow : selectedRegion,
          territory: territoryToShow,
          area: areasToShow,
          channel: channelsToShow,
        }),
      })
        .then((response) => {
          const responseData = response?.data;

          setPriceData(responseData);
          // setIsLoading(false);
        })
        .catch((error) => {
          console.log("Filtered Values Api: ", error);
          // setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    const getPlace = async () => {
      // setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/place",
        headers: { "Content-Type": "application/json" },
        timeout:120000,
        data: JSON.stringify({
          startdate: selectedStartDate
            ? selectedStartDate
            : "",
          enddate: selectedEndDate
            ? selectedEndDate
            : "",
          email: props?.currentUser?.user,
          region: selectedRegion === "" ? regionsToShow : selectedRegion,
          territory: territoryToShow,
          area: areasToShow,
          channel: channelsToShow,
        }),
      })
        .then((response) => {
          const responseData = response?.data?.data;
          setPlaceData(responseData[0]);
          // setIsLoading(false);
        })
        .catch((error) => {
          console.log("Filtered Values Api: ", error);
          // setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    const getProposition = async () => {
      // setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/proposition",
        headers: { "Content-Type": "application/json" },
        timeout:120000,
        data: JSON.stringify({
          startdate: selectedStartDate
            ? selectedStartDate
            : "",
          enddate: selectedEndDate
            ? selectedEndDate
            : "",
          email: props?.currentUser?.user,
          region: selectedRegion === "" ? regionsToShow : selectedRegion,
          territory: territoryToShow,
          area: areasToShow,
          channel: channelsToShow,
        }),
      })
        .then((response) => {
          const responseData = response?.data;
          setPropositionData(responseData);
          // setIsLoading(false);
        })
        .catch((error) => {
          console.log("Filtered Values Api: ", error);
          // setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };

    if (selectedStartDate) {
      if (selectedStartDate && selectedEndDate) {
        getProductData();
        getCompliantPer();
        getIntruderData();
        getProposition();
        getMustHaveData();
        getPrice();
        getPlace();
      }
    } else {
      getProductData();
      getCompliantPer();
      getIntruderData();
      getMustHaveData();
      getProposition();
      getPrice();
      getPlace();
    }
  }, [
    selectedRegion,
    territoryToShow,
    areasToShow,
    regionsToShow,
    channelsToShow,
    selectedStartDate,
    selectedEndDate,
    props?.currentUser?.user,
  ]);

  const handleSelectStartDate = (newStartDate) => {
    setSelectedStartDate(newStartDate);
    setSelectedEndDate(null);
    if (newStartDate && !selectedEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else if (!newStartDate && selectedEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };

  const handleSelectEndDate = (newEndDate) => {
    setProductData(null);
    setCompliance(null);
    setPropositionData(null);
    setPlaceData(null);
    setPriceData(null);
    setMustHaveData(null);
    setIntruderData(null);
    setMerchandiseData(null);

    setSelectedEndDate(newEndDate);

    if (!selectedStartDate && newEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else if (selectedStartDate && !newEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };

  const handleSelectTab = (eventKey) => {
    setProductData(null);
    setCompliance(null);
    setPropositionData(null);
    setPlaceData(null);
    setPriceData(null);
    setMustHaveData(null);
    setIntruderData(null);
    setMerchandiseData(null);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setAreasToShow([]);
    setTerritoryToShow([]);
    setRegionsToShow([]);
    setChannelsToShow([]);
    setAreaFilters(null);
    if (parseInt(eventKey) === 0) {
      setSelectedRegion("");
    } else {
      setSelectedRegion(regionFilters[eventKey]);
    }
  };

  console.log(
    areaFilters,
    productData,
    propositionData,
    mustHaveData,
    priceData,
    placeData,
    intruderData,
    " data"
  );
  return (
    <div className="container-fluid summary">
      <>
        {props?.currentUser?.user === "admin@fc.com" ? (
          <>
            {!regionFilters ? (
              <div className="loading_area">
                <div className="col-12 d-flex justify-content-center loader">
                  <div
                    className="custom_loader spinner-border text-info"
                    role="status"
                    style={{ zIndex: "2" }}
                  ></div>
                  <div className="loader-message">Loading...</div>
                </div>
              </div>
            ) : (
              <Tabs
                defaultActiveKey={0}
                className="mb-3"
                justify={true}
                onSelect={handleSelectTab}
              >
                {regionFilters?.map((region, index) => (
                  <Tab
                    key={index}
                    eventKey={index}
                    title={index == 0 ? "Overall" : region + " Region"}
                  >
                    {alertError && <Alert variant="danger">{alertError}</Alert>}
                    {!(
                      areaFilters &&
                      territoryFilters &&
                      productData &&
                      propositionData &&
                      priceData &&
                      placeData &&
                      intruderData
                    ) ? (
                      <div className="loading_area">
                        <div className="col-12 d-flex justify-content-center loader">
                          <div
                            className="custom_loader spinner-border text-info"
                            role="status"
                            style={{ zIndex: "2" }}
                          ></div>
                          <div className="loader-message">Loading...</div>
                        </div>
                      </div>
                    ) : (
                      <CustomSummaryPage
                        selectedStartDate={selectedStartDate}
                        selectedEndDate={selectedEndDate}
                        handleSelectStartDate={handleSelectStartDate}
                        handleSelectEndDate={handleSelectEndDate}
                        startDateError={startDateError}
                        endDateError={endDateError}
                        productData={productData}
                        complianceData={compliance}
                        propositionData={propositionData}
                        priceData={priceData}
                        placeData={placeData}
                        merchandiseData={merchandiseData}
                        mustHaveData={mustHaveData ? mustHaveData : 0}
                        intruderData={intruderData}
                        areaFilters={areaFilters ? areaFilters : []}
                        // areas
                        areasToShow={areasToShow}
                        setAreasToShow={setAreasToShow}
                        areaSelectedAll={areaSelectedAll}
                        setAreaSelectedAll={setAreaSelectedAll}
                        // territory
                        territoryFilters={
                          territoryFilters ? territoryFilters : []
                        }
                        territoryToShow={territoryToShow}
                        setTerritoryToShow={setTerritoryToShow}
                        territorySelectedAll={territorySelectedAll}
                        setTerritorySelectedAll={setTerritorySelectedAll}
                        selectedRegion={selectedRegion}
                        currentUser={props?.currentUser?.user}
                        // areas
                        channelsToShow={channelsToShow}
                        setChannelsToShow={setChannelsToShow}
                        channelSelectedAll={channelSelectedAll}
                        setChannelSelectedAll={setChannelSelectedAll}
                        channelFilters={channelFilters}
                        // regions
                        regionsToShow={regionsToShow}
                        setRegionsToShow={setRegionsToShow}
                        regionSelectedAll={regionSelectedAll}
                        setRegionSelectedAll={setRegionSelectedAll}
                        regionFilters={regionFilters.slice(1)}
                      />
                    )}
                  </Tab>
                ))}
              </Tabs>
            )}
          </>
        ) : (
          <>
            {alertError && <Alert variant="danger">{alertError}</Alert>}
            {!(
              areaFilters &&
              territoryFilters &&
              productData &&
              propositionData &&
              priceData &&
              placeData &&
              intruderData
            ) ? (
              <div className="loading_area">
                <div className="col-12 d-flex justify-content-center loader">
                  <div
                    className="custom_loader spinner-border text-info"
                    role="status"
                    style={{ zIndex: "2" }}
                  ></div>
                  <div className="loader-message">Loading...</div>
                </div>
              </div>
            ) : (
              <CustomSummaryPage
                selectedStartDate={selectedStartDate}
                selectedEndDate={selectedEndDate}
                handleSelectStartDate={handleSelectStartDate}
                handleSelectEndDate={handleSelectEndDate}
                startDateError={startDateError}
                endDateError={endDateError}
                productData={productData}
                complianceData={compliance}
                propositionData={propositionData}
                priceData={priceData}
                placeData={placeData}
                merchandiseData={merchandiseData}
                mustHaveData={mustHaveData ? mustHaveData : 0}
                intruderData={intruderData}
                // areas
                areaFilters={areaFilters ? areaFilters : []}
                areasToShow={areasToShow}
                setAreasToShow={setAreasToShow}
                areaSelectedAll={areaSelectedAll}
                setAreaSelectedAll={setAreaSelectedAll}
                // territory
                territoryFilters={territoryFilters ? territoryFilters : []}
                territoryToShow={territoryToShow}
                setTerritoryToShow={setTerritoryToShow}
                territorySelectedAll={territorySelectedAll}
                setTerritorySelectedAll={setTerritorySelectedAll}
                currentUser={props?.currentUser?.user}
              />
            )}
          </>
        )}
      </>
    </div>
  );
};

export default Summary;
