import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { Link, Navigate } from 'react-router-dom';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import 'react-datepicker/dist/react-datepicker.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import the blur effect CSS
import 'react-dropdown/style.css';
import '../../styles/photohub/general.css';
import "../../styles/general.scss";
import "../../styles/home.scss";
import { BiRefresh } from 'react-icons/bi';
import CustomDropDownFilters from '../../components/CustomDropDownFilters';
import CustomMultiSelectWithOptionValue from '../../components/CustomMultiSelectWithOptionValue';
import axios from 'axios';

const styles = theme => ({
    root: {
        position: 'relative',
        top: '20px',
        left: '10px',
        width: 'fit-content'
    },
    gridList: {
        width: '100',
        height: '68vh'
    },
    gridListTile: {
        border: '2.55px solid var(--customPrimary)',
        borderRadius: '20px',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    // gridList:
    // {
    //     width: 750
    // },
    // gridList:
    // {
    //     width: 650
    // },
    // "@media screen and (max-width: 1250px)":
    // {
    //     gridList:
    //     {
    //         width: 600
    //     }
    // },
    // "@media screen and (max-width: 1200px)":
    // {
    //     gridList:
    //     {
    //         width: 550
    //     }
    // },
    // "@media screen and (max-width: 1150px)":
    // {
    //     gridList:
    //     {
    //         width: 500
    //     }
    // },
    // "@media screen and (max-width: 1100px)":
    // {
    //     gridList:
    //     {
    //         width: 450
    //     }
    // },
    // "@media screen and (max-width: 1000px)":
    // {
    //     gridList:
    //     {
    //         width: 400
    //     }
    // }
    // Media queries...
});

const RejectedImages = (props) => {
    const {
        classes,
        currentUser
        // Other props you need
    } = props;


    // DATE FILTERS VARIABLES
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);

    // CHANNEL FILTER VARIABLES
    const [channelsToShow, setChannelsToShow] = useState([]);
    const [channelSelectedAll, setChannelSelectedAll] = useState(false);
    const [channelFilters, setChannelFilters] = useState([]);

    // REGION FILTER VARIABLES
    const [regionsToShow, setRegionsToShow] = useState([]);
    const [regionSelectedAll, setRegionSelectedAll] = useState(false);
    const [regionFilters, setRegionFilters] = useState([]);

    // AREA FILTER VARIABLES
    const [areasToShow, setAreasToShow] = useState([]);
    const [areaSelectedAll, setAreaSelectedAll] = useState(false);
    const [areaFilters, setAreaFilters] = useState([]);

    // STORE FILTER VARIABLES
    const [storesToShow, setStoresToShow] = useState([]);
    const [storeSelectedAll, setStoreSelectedAll] = useState(false);
    const [storeFilters, setStoreFilters] = useState([]);

    // Territory Filter
    const [territorySelectedAll, setTerritorySelectedAll] = useState(false);
    const [territoryFilters, setTerritoryFilters] = useState([]);
    const [territoryToShow, setTerritoryToShow] = useState([]);

    // Category FILTER VARIABLES
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categoriesToShow, setCategoriesToShow] = useState([]);
    const [categortySelectedAll, setCategortySelectedAll] = useState(false);
    const [categoryList, setCategoryList] = useState([
        { value: "14", label: "Product" },
        { value: "15", label: "Price" },
        { value: "16", label: "Placement" },
        { value: "17", label: "Promotion" }
    ]);


    const [errorMessage, setErrorMessage] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedChannel, setSelectedChannel] = useState('');
    const [selectedStore, setSelectedStore] = useState('');
    const [imageData, setImageData] = useState([]);
    const [filteredImageData, setFilteredImageData] = useState([]);
    const [filtered, setFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getFilterValues = async () => {
            setIsLoading(true);
            await axios({
                method: "post",
                url: "/api/filter_values",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    email: props?.currentUser?.user,
                    region: "",
                }),
            })
                .then((response) => {
                    const responseData = response?.data;
                    setChannelFilters(responseData?.channel);
                    setRegionFilters(responseData?.region);
                    setTerritoryFilters(responseData?.storeTerritory);
                    setAreaFilters(responseData?.area);
                    setStoreFilters(responseData?.store);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log("Filtered Values Api: ", error);
                    setIsLoading(false);
                    setErrorMessage("Something went wrong. Please try again.");
                });
        };

        const fetchRejectedImages = async () => {
            try {
                const response = await fetch('/api/rejectedImages', {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ currentDate: selectedStartDate, page: page })
                });
                const data = await response.json();
                console.log(data, ' images data')
                setImageData(data.images);
                setFilteredImageData(data.images);
                setTotalPages(Math.ceil(data?.length / 100));
            } catch (error) {
                console.log("Rejected Images Api Error: ", error);
                setErrorMessage("Internal Server Error! Please try again later.");
            }

            setIsLoading(false);
        };

        handleFilter()
        getFilterValues();
        fetchRejectedImages();
    }, []);

    const updateStartDate = (date) => {
        setStartDate(date);
    };

    const updateEndDate = (date) => {
        setEndDate(date);
    };

    const updateCountry = (e) => {
        setSelectedCountry(e.target.value);
    };

    const updateCity = (e) => {
        setSelectedCity(e.target.value);
    };

    const updateChannel = (e) => {
        setSelectedChannel(e.target.value);
    };

    const updateStore = (e) => {
        setSelectedStore(e.target.value);
    };

    const updateCategory = (e) => {
        setSelectedCategory(e.target.value);
    };

    const handleFilter = async () => {
        console.log(moment(selectedStartDate).format("yyyy-MM-DD"), 'start date')
        console.log(moment(selectedEndDate?.$d).format("YYYY-MM-DD"), 'end dates')
        setIsLoading(true);
        try {
            const response = await fetch('/api/filteredRejectedImages', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    country: selectedCountry, territory: territoryToShow,
                    store: storesToShow,
                    category: categoriesToShow,
                    area: areasToShow,
                    region: regionsToShow,
                    channel: channelsToShow, startDate: moment(selectedStartDate?.$d).format("YYYY-MM-DD"), endDate: moment(selectedEndDate?.$d).format("YYYY-MM-DD"), page: 0, limit: 100
                })
            });
            const data = await response.json();
            console.log(data, ' images data')
            setFilteredImageData(data.images);
            setTotalPages(Math.ceil(data?.length / 100));
            setPage(0);
            setFiltered(true);
        } catch (error) {
            console.log("Filtered Rejected Images Api Error: ", error);
            setErrorMessage("Internal Server Error! Please try again later.");
        }

        setIsLoading(false);
    };

    const handleReset = () => {
        setFilteredImageData(imageData);
        setFiltered(false);
        setSelectedStartDate(new Date());
        setSelectedEndDate(new Date());
        setSelectedCountry('');
        setSelectedCity('');
        setSelectedChannel('');
        setSelectedStore('');
        setSelectedCategory('');
        setPage(0);
        setTotalPages(1);
    };

    const handlePageChange = async (event, value) => {
        setIsLoading(true);
        try {
            const response = await fetch('/api/filteredRejectedImages', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    country: selectedCountry, territory: territoryToShow,
                    store: storesToShow,
                    category: categoriesToShow,
                    area: areasToShow,
                    region: regionsToShow,
                    channel: channelsToShow, startDate: moment(selectedStartDate?.$d).format("YYYY-MM-DD"), endDate: moment(selectedEndDate?.$d).format("YYYY-MM-DD"), page: value - 1, limit: 100
                })
            });
            const data = await response.json();

            setImageData(data.images);
            setFilteredImageData(data.images);
            setTotalPages(Math.ceil(data?.length / 100));
            setPage(value - 1);
        } catch (error) {
            console.log("Rejected Images Api Error: ", error);
            setErrorMessage("Internal Server Error! Please try again later.");
        }

        setIsLoading(false);
    };
    const handleSelectStartDate = (newStartDate) => {
        setSelectedStartDate(newStartDate);

        if (newStartDate && !selectedEndDate) {
            setStartDateError(false);
            setEndDateError(true);
        } else if (!newStartDate && selectedEndDate) {
            setStartDateError(true);
            setEndDateError(false);
        } else {
            setStartDateError(false);
            setEndDateError(false);
        }
    };

    const handleSelectEndDate = (newEndDate) => {
        setSelectedEndDate(newEndDate);

        if (!selectedStartDate && newEndDate) {
            setStartDateError(true);
            setEndDateError(false);
        } else if (selectedStartDate && !newEndDate) {
            setStartDateError(false);
            setEndDateError(true);
        } else {
            setStartDateError(false);
            setEndDateError(false);
        }
    };
    return (
        <div className="container-fluid main_container">
            {!props?.currentUser?.user ? (
                <Navigate to="/login" replace={true} />
            ) : (
                <div>
                    <div className="row align-items-center justify-content-between">
                        <div className="page_heading col-5 p-0">
                            <h4 className="page_heading_primary">
                                Rejected
                                <span className="page_heading_secondary">&nbsp;Images</span>
                            </h4>
                        </div>

                    </div>
                    <div className="filters_row row">
                        {/* <SingleSelectDropdownSimpleArray
                            optionsArray={countryList}
                            label="Country"
                            selectedValue={selectedCountry}
                            setSelectedValue={setSelectedCountry}
                        /> */}

                        <CustomDropDownFilters
                            filterValues={channelFilters}
                            isSelectedAll={channelSelectedAll}
                            label="Channel"
                            setValuesToShow={setChannelsToShow}
                            setSelectedAll={setChannelSelectedAll}
                            valuesToShow={channelsToShow ? channelsToShow : []}
                        />

                        <CustomDropDownFilters
                            filterValues={regionFilters}
                            isSelectedAll={regionSelectedAll}
                            label="Region"
                            setValuesToShow={setRegionsToShow}
                            setSelectedAll={setRegionSelectedAll}
                            valuesToShow={regionsToShow ? regionsToShow : []}
                        />

                        <CustomDropDownFilters
                            filterValues={areaFilters}
                            isSelectedAll={areaSelectedAll}
                            label="Distribution"
                            setValuesToShow={setAreasToShow}
                            setSelectedAll={setAreaSelectedAll}
                            valuesToShow={areasToShow ? areasToShow : []}
                        />
                        <CustomDropDownFilters
                            filterValues={territoryFilters}
                            isSelectedAll={territorySelectedAll}
                            label="Territory"
                            setValuesToShow={setTerritoryToShow}
                            setSelectedAll={setTerritorySelectedAll}
                            valuesToShow={territoryToShow ? territoryToShow : []}
                        />
                        <CustomMultiSelectWithOptionValue
                            filterValues={categoryList}
                            isSelectedAll={categortySelectedAll}
                            label="Category"
                            setValuesToShow={setCategoriesToShow}
                            setSelectedAll={setCategortySelectedAll}
                            valuesToShow={categoriesToShow ? categoriesToShow : []}
                        />
                        <CustomDropDownFilters
                            filterValues={storeFilters}
                            isSelectedAll={storeSelectedAll}
                            label="Store"
                            setValuesToShow={setStoresToShow}
                            setSelectedAll={setStoreSelectedAll}
                            valuesToShow={storesToShow ? storesToShow : []}
                        />
                        <div className="date-picker-container col d-flex justify-content-end">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div style={{ width: '150px' }}>
                                    <DatePicker
                                        className={`${selectedStartDate && "date_selected"}`}
                                        label="From"
                                        inputFormat="DD-MMM-YYYY"
                                        mask={"__-__-____ __"}
                                        value={selectedStartDate}
                                        maxDate={selectedEndDate}
                                        disableFuture={true}
                                        onChange={handleSelectStartDate}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={startDateError}
                                                helperText={null}
                                                size="small"
                                            />
                                        )}
                                    />
                                </div>
                                <div style={{ width: '150px' }}>
                                    <DatePicker
                                        className={`${selectedEndDate && "date_selected"}`}
                                        label="To"
                                        inputFormat="DD-MMM-YYYY"
                                        mask={"__-__-____ __"}
                                        value={selectedEndDate}
                                        minDate={selectedStartDate}
                                        disableFuture={true}
                                        onChange={handleSelectEndDate}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={endDateError}
                                                helperText={null}
                                                size="small"
                                            />
                                        )}
                                    />
                                </div>
                            </LocalizationProvider>
                        </div>
                        <div class="btn-group" role="group" aria-label="Basic example" style={{ width: '140px' }}>
                            <button className="apply_filter" onClick={handleFilter} disabled={isLoading}>
                                Filter
                            </button>
                            <button className='btn btn-light' onClick={handleReset} disabled={!filtered || isLoading}>
                                <BiRefresh />
                            </button>
                        </div>
                    </div>
                    <div className='photoHubDataContainer'>
                        <div style={{ width: '100%' }}>
                            {isLoading ? (
                                <div className="d-flex justify-content-center loader_con">
                                    <br />
                                    <br />
                                    <div className="spinner-border text-info" role="status"></div>
                                </div>
                            ) : errorMessage ? (
                                <span><br />{errorMessage}</span>
                            ) : filteredImageData.length === 0 ? (
                                <span><br />No pictures to display.</span>
                            ) : filteredImageData.length > 0 && (
                                <div className={classes.root}>
                                    <ImageList cols={6} rowHeight={110} gap={20} className={classes.gridList}>
                                        {filteredImageData.map((image, index) => (

                                            <ImageListItem key={image.filestoragePath + index}>
                                                <Link
                                                    to={`/photoHub/rejected-image-details/${index}`}
                                                    state={{
                                                        images: filteredImageData,
                                                        imageIndex: index,
                                                        page: page,
                                                        totalPages: totalPages,
                                                        country: selectedCountry,
                                                        territory: territoryToShow,
                                                        store: storesToShow,
                                                        area: areasToShow,
                                                        region: regionsToShow,
                                                        channel: channelsToShow,
                                                        startDate: moment(selectedStartDate?.$d).format("YYYY-MM-DD"),
                                                        endDate: moment(selectedEndDate?.$d).format("YYYY-MM-DD"),
                                                    }}
                                                >
                                                    <LazyLoadImage
                                                        alt=""
                                                        src={`https://storage.googleapis.com/staging-api-uploads/${image.filestoragePath}`} // Image URL
                                                        effect="blur" // Progressive loading effect
                                                        className={classes.gridListTile}
                                                    />
                                                </Link>
                                            </ImageListItem>
                                        ))}
                                    </ImageList>

                                    <Stack spacing={2} style={{ marginTop: '1em' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', columnGap: '10px', justifyContent: 'center' }}>
                                            <Typography>Page: {page + 1}</Typography>
                                            <Pagination count={totalPages} page={page + 1} siblingCount={0} onChange={handlePageChange} />
                                        </div>
                                    </Stack>
                                </div>
                            )}
                        </div>
                        {/* <div className={`dataFilter ${filteredImageData && filteredImageData.length > 0 ? 'dataAvailable' : ''}`}>
                            <span>Filter</span>

                            <div className="row">
                                <div className="col-md-6" style={{ paddingRight: '5px' }}>
                                    <span style={{ fontWeight: 'bold', fontSize: '13px' }}>Start Date</span>
                                    <DatePicker dateFormat="dd/MM/yyyy" selected={startDate} onChange={updateStartDate} />
                                </div>
                                <div className="col-md-6" style={{ paddingLeft: '5px' }}>
                                    <span style={{ fontWeight: 'bold', fontSize: '13px' }}>End Date</span>
                                    <DatePicker dateFormat="dd/MM/yyyy" selected={endDate} onChange={updateEndDate} />
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: '5px' }}>
                                <div className="col-md-6" style={{ paddingRight: '5px' }}>
                                    <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>Country</span>
                                    <select
                                        name="selectCountry"
                                        id="selectCountry"
                                        style={{ width: '100%', fontSize: '14px' }}
                                        onChange={updateCountry}
                                        value={selectedCountry}
                                    >
                                        <option value="" disabled hidden>Select Country</option>
                                        {countryList.map((country) => (
                                            <option key={country} value={country}>
                                                {country}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-6" style={{ paddingLeft: '5px' }}>
                                    <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>City</span>
                                    <select
                                        name="selectCity"
                                        id="selectCity"
                                        style={{ width: '100%', fontSize: '13px' }}
                                        onChange={updateCity}
                                        value={selectedCity}
                                    >
                                        <option value="" disabled hidden>Select City</option>
                                        {cityList.map((city) => (
                                            <option key={city} value={city}>
                                                {city}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: '5px' }}>
                                <div className="col-md-12">
                                    <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>Channel</span>
                                    <select
                                        name="selectChannel"
                                        id="selectChannel"
                                        style={{ width: '100%', fontSize: '13px' }}
                                        onChange={updateChannel}
                                        value={selectedChannel}
                                    >
                                        <option value="" disabled hidden>Select Channel</option>
                                        {channelList.map((channel) => (
                                            <option key={channel} value={channel}>
                                                {channel}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: '5px' }}>
                                <div className="col-md-12">
                                    <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>Store</span>
                                    <select
                                        name="selectStore"
                                        id="selectStore"
                                        style={{ width: '100%', fontSize: '13px' }}
                                        onChange={updateStore}
                                        value={selectedStore}
                                    >
                                        <option value="" disabled hidden>Select Store</option>
                                        {storeList.map((store) => (
                                            <option key={store} value={store}>
                                                {store}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: '5px' }}>
                                <div className="col-md-12">
                                    <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>Category</span>
                                    <select
                                        name="selectCategory"
                                        id="selectCategory"
                                        style={{ width: '100%', fontSize: '13px' }}
                                        onChange={updateCategory}
                                        value={selectedCategory}
                                    >
                                        <option value="" disabled hidden>Select Category</option>
                                        <option value="14">Product</option>
                                        <option value="15">Price</option>
                                        <option value="16">Placement</option>
                                        <option value="17">Promotion</option>
                                    </select>
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: '15px' }}>
                                <div className="col-md-6">
                                    <button className="button_primary" onClick={handleReset} disabled={!filtered || isLoading}>
                                        Reset
                                    </button>
                                </div>
                                <div className="col-md-6">
                                    <button className="button_primary" onClick={handleFilter} disabled={isLoading}>
                                        Filter
                                    </button>
                                </div>
                            </div>
                        </div> */}
                    </div>

                </div>
            )}
        </div>
    );
};

export default withStyles(styles)(RejectedImages);
