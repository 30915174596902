import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import Select from 'react-select';
import ImageListItem from '@material-ui/core/ImageListItem';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Link, Navigate } from 'react-router-dom';
import LoggedUser from './LoggedUser';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dropdown/style.css';
import '../../styles/photohub/PhotoDetails.css';
import '../../styles/photohub/general.css';
import "../../styles/general.scss";
import "../../styles/home.scss";
import CustomDropDownFilters from '../../components/CustomDropDownFilters';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import SingleSelectDropdownSimpleArray from '../../components/SingleSelectDropdownSimpleArray';

const brand = JSON.parse(localStorage.getItem("brandID"));

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        top: '20px',
        left: '10px',
        width: 'fit-content'
    },
    gridList: {
        width: 780,
        height: 530
    },
    gridListTile: {
        border: '2.55px solid var(--customPrimary)',
        borderRadius: '20px',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    "@media screen and (max-width: 1350px)": {
        gridList: {
            width: 750
        }
    },
    "@media screen and (max-width: 1300px)": {
        gridList: {
            width: 650
        }
    },
    // ... add other media queries here
}));

const BrandView = (props) => {
    const classes = useStyles();

    // DATE FILTERS VARIABLES
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);

    // COUNTRY FILTER VARIABLES
    const [countryList, setCountryList] = useState([]);
    const [countryToShow, setCountryToShow] = useState([]);
    const [countrySelectedAll, setCountrySelectedAll] = useState(false);

    // CHANNEL FILTER VARIABLES
    const [channelList, setChannelList] = useState([]);
    const [channelsToShow, setChannelsToShow] = useState([]);
    const [channelSelectedAll, setChannelSelectedAll] = useState(false);


    // CITY FILTER VARIABLES
    const [cityList, setCityList] = useState([]);
    const [citiesToShow, setCitiesToShow] = useState([]);
    const [citySelectedAll, setCitySelectedAll] = useState(false);

    // AREA FILTER VARIABLES
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categoryList, setCategoryList] = useState([
        { value: "14", label: "Product" },
        { value: "15", label: "Price" },
        { value: "16", label: "Placement" },
        { value: "17", label: "Promotion" }
    ]);

    // STORE FILTER VARIABLES
    const [storeList, setStoreList] = useState([]);
    const [storesToShow, setStoresToShow] = useState([]);
    const [storeSelectedAll, setStoreSelectedAll] = useState(false);

    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [errorMessage, setErrorMessage] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedChannel, setSelectedChannel] = useState('');
    const [selectedStore, setSelectedStore] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [imageData, setImageData] = useState([]);
    const [filteredImageData, setFilteredImageData] = useState([]);
    const [filtered, setFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);

    const handleSelectStartDate = (newStartDate) => {
        setSelectedStartDate(newStartDate);

        if (newStartDate && !selectedEndDate) {
            setStartDateError(false);
            setEndDateError(true);
        } else if (!newStartDate && selectedEndDate) {
            setStartDateError(true);
            setEndDateError(false);
        } else {
            setStartDateError(false);
            setEndDateError(false);
        }
    };

    const handleSelectEndDate = (newEndDate) => {
        setSelectedEndDate(newEndDate);

        if (!selectedStartDate && newEndDate) {
            setStartDateError(true);
            setEndDateError(false);
        } else if (selectedStartDate && !newEndDate) {
            setStartDateError(false);
            setEndDateError(true);
        } else {
            setStartDateError(false);
            setEndDateError(false);
        }
    };

    const updateStartDate = (date) => {
        setStartDate(date);
    };

    const updateEndDate = (date) => {
        setEndDate(date);
    };

    const updateCountry = (e) => {
        setSelectedCountry(e.target.value);
    };

    const updateCity = (e) => {
        setSelectedCity(e.target.value);
    };

    const updateChannel = (e) => {
        setSelectedChannel(e.target.value);
    };

    const updateStore = (e) => {
        setSelectedStore(e);
    };

    const updateCategory = (e) => {
        setSelectedCategory(e.target.value);
    };

    const handleFilter = () => {
        if (Date.parse(endDate) - Date.parse(startDate) >= 0) {
            setIsLoading(true);
            setErrorMessage(null);
            fetch('/api/filteredBrandImages', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    country: selectedCountry,
                    city: selectedCity,
                    channel: selectedChannel,
                    store: selectedStore,
                    category: selectedCategory,
                    startDate: moment(startDate).format("yyyy-MM-DD"),
                    endDate: moment(endDate).format("yyyy-MM-DD"),
                    page: 0,
                    limit: 100
                })
            })
                .then(response => response.json())
                .then(data => {
                    setFilteredImageData(data.images);
                    setTotalPages(Math.round(data.length / 100));
                    setPage(0);
                    setFiltered(true);
                })
                .catch(error => {
                    console.log("Filtered Brand Images Api Error: ", error);
                    setErrorMessage("Internal Server Error! Please try again later.");
                })
                .finally(() => setIsLoading(false));
        } else {
            alert("Invalid Date Range!");
        }
    };

    const handleReset = () => {
        setFilteredImageData(imageData);
        setFiltered(false);
        setSelectedStartDate(new Date())
        setSelectedEndDate(new Date());
        setSelectedCountry('');
        setSelectedCity('');
        setSelectedChannel('');
        setSelectedStore('');
        setSelectedCategory('');
        setPage(0);
        setTotalPages(1);
    };

    const handlePageChange = (event, value) => {
        setIsLoading(true);
        setErrorMessage(null);
        fetch('/api/filteredBrandImages', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                country: selectedCountry,
                city: selectedCity,
                channel: selectedChannel,
                store: selectedStore,
                category: selectedCategory,
                startDate: moment(selectedStartDate).format("yyyy-MM-DD"),
                endDate: moment(selectedStartDate).format("yyyy-MM-DD"),
                page: value - 1,
                limit: 100
            })
        })
            .then(response => response.json())
            .then(data => {
                setImageData(data.images);
                setFilteredImageData(data.images);
                setTotalPages(Math.round(data.length / 100));
                setPage(value - 1);
            })
            .catch(error => {
                console.log("Brand Images Api Error: ", error);
                setErrorMessage("Internal Server Error! Please try again later.");
            })
            .finally(() => setIsLoading(false));
    };

    const handleChange = (selectedOption) => {
        updateStore(selectedOption?.value);
        setSelectedOption(selectedOption);
    };

    useEffect(() => {
        setIsLoading(true);
        setErrorMessage(null);

        const fetchData = async () => {
            try {
                const filterResponse = await fetch('/api/filterLists', {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }
                });
                const filterData = await filterResponse.json();

                const countries = [];
                const cities = [];
                const channels = [];
                const stores = [];

                for (const dataObjects of filterData.filters) {
                    if (countries.findIndex(country => country.toLowerCase().includes(dataObjects.countryName.toLowerCase().trim())) === -1)
                        countries.push(dataObjects.countryName.trim());

                    if (cities.findIndex(city => city.toLowerCase().includes(dataObjects.storeCity.toLowerCase().trim())) === -1)
                        cities.push(dataObjects.storeCity.trim());

                    if (channels.findIndex(channel => channel.toLowerCase().includes(dataObjects.storeChannel.toLowerCase().trim())) === -1)
                        channels.push(dataObjects.storeChannel.trim());

                    if (stores.findIndex(store => store.toLowerCase().includes(dataObjects.storeName.toLowerCase().trim())) === -1)
                        stores.push(dataObjects.storeName.trim());
                }

                setCountryList(countries);
                setCityList(cities);
                setChannelList(channels);
                setStoreList(filterData?.filters);
            } catch (error) {
                console.log("Filter Lists Api Error: ", error);
                setErrorMessage("Internal Server Error! Please try again later.");
            }

            try {
                const brandImagesResponse = await fetch('/api/brandImages', {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ brandID: brand[0].brandID, currentDate: startDate, page: page })
                });
                const brandImagesData = await brandImagesResponse.json();

                setImageData(brandImagesData.images);
                setFilteredImageData(brandImagesData.images);
                setTotalPages(Math.round(brandImagesData.length / 100));
            } catch (error) {
                console.log("Brand Images Api Error: ", error);
                setErrorMessage("Internal Server Error! Please try again later.");
            }

            setIsLoading(false);
        };

        fetchData();
    }, [startDate, page]);

    const storeOptions = storeList?.map((store) => ({
        value: store.storeName,
        label: `${store.storeName} (${store.storeCode})`
    }));
    const selectedStoreOption = storeOptions.find(
        (option) => option.value === selectedStore
    );

    return (
        <div className="container-fluid main_container">
            {!props?.currentUser?.user ? (
                <Navigate to="/login" replace={true} />
            ) : (
                <div>
                    <div className="row align-items-center justify-content-between">
                        <div className="page_heading col-5 p-0">
                            <h4 className="page_heading_primary">
                                Brand
                                <span className="page_heading_secondary">&nbsp;View</span>
                            </h4>
                        </div>
                        <div className="date_cont col-5 p-0">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                   className={`${selectedStartDate && "date_selected"}`}
                                    label="From"
                                    inputFormat="DD-MMM-YYYY"
                                    mask={"__-__-____ __"}
                                    value={selectedStartDate}
                                    maxDate={selectedEndDate}
                                    disableFuture={true}
                                    onChange={handleSelectStartDate}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={startDateError}
                                            helperText={null}
                                            size="small"
                                        />
                                    )}
                                />


                                <DatePicker
                                   className={`${selectedEndDate && "date_selected"}`}
                                    label="To"
                                    inputFormat="DD-MMM-YYYY"
                                    mask={"__-__-____ __"}
                                    value={selectedEndDate}
                                    minDate={selectedStartDate}
                                    disableFuture={true}
                                    onChange={handleSelectEndDate}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={endDateError}
                                            helperText={null}
                                            size="small"
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>

                        {/* <div className="date_cont col-5 p-0">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  inputFormat="DD-MMM-YYYY"
                  mask={"__-__-____ __"} 
                  value={selectedStartDate}
                  maxDate={selectedEndDate}
                  disableFuture={true}
                  onChange={handleSelectStartDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={startDateError}
                      helperText={null}
                      size="small"
                    />
                  )}
                />

                <DatePicker
                  label="To"
                  inputFormat="DD-MMM-YYYY"
                  mask={"__-__-____ __"} 
                  value={selectedEndDate}
                  minDate={selectedStartDate}
                  disableFuture={true}
                  onChange={handleSelectEndDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={endDateError}
                      helperText={null}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </div> */}
                    </div>

                    <div className="filters_row row">

                        <div className="col d-flex align-items-center justify-content-center gap-2">
                            <SingleSelectDropdownSimpleArray
                                optionsArray={channelList}
                                label="Channel"
                                selectedValue={selectedChannel}
                                setSelectedValue={setSelectedChannel}
                            />
                        </div>

                        <div className="col d-flex align-items-center justify-content-center gap-2">
                            <SingleSelectDropdownSimpleArray
                                optionsArray={cityList}
                                label="City"
                                selectedValue={selectedCity}
                                setSelectedValue={setSelectedCity}
                            />
                        </div>
                        <div className="col d-flex align-items-center justify-content-center gap-2">
                            <SingleSelectDropdownSimpleArray
                                optionsArray={countryList}
                                label="Country"
                                selectedValue={selectedCountry}
                                setSelectedValue={setSelectedCountry}
                            />
                        </div>
                        <div className="col d-flex align-items-center justify-content-center gap-2">
                            <SingleSelectDropdown
                                optionsArray={categoryList}
                                label="Category"
                                selectedValue={selectedCategory}
                                setSelectedValue={setSelectedCategory}
                            />
                        </div>

                        <div className="col d-flex align-items-center justify-content-center gap-2">
                            <SingleSelectDropdown
                                optionsArray={storeOptions}
                                label="Store"
                                selectedValue={selectedStore}
                                setSelectedValue={setSelectedStore}
                            />
                        </div>
                        <div className="col d-flex align-items-center justify-content-center gap-2">
                            <button className="button_primary" onClick={handleFilter} disabled={isLoading}>
                                Filter
                            </button>
                        </div>
                        <div className="col d-flex align-items-center justify-content-center gap-2">
                                        <button className="button_primary" onClick={handleReset} disabled={!filtered || isLoading}>
                                            Reset
                                        </button>
                                    </div>

                    </div>
                    <div className="content-container">
                        {/* <LoggedUser logOut={props.logOut} /> */}
                        <div className="photoHubDataContainer">
                            <div style={{ width: '70%' }}>
                                {isLoading ? (
                                    <div className="d-flex justify-content-center">
                                        <br />
                                        <br />
                                        <div className="spinner-border text-info" role="status"></div>
                                    </div>
                                ) : errorMessage ? (
                                    <span><br />{errorMessage}</span>
                                ) : filteredImageData.length <= 0 ? (
                                    <span><br />No pictures to display.</span>
                                ) : (
                                    <div className={classes.root}>
                                        <ImageList cols={4} rowHeight={100} gap={20} className={classes.gridList}>
                                            {filteredImageData?.map((image, index) => (
                                                <ImageListItem key={image.filestoragePath}>
                                                    <Link to={`photo-details/${index}`} state={{ images: filteredImageData, imageIndex: index, page: page, totalPages: totalPages, country: selectedCountry, city: selectedCity, channel: selectedChannel, store: selectedStore, category: selectedCategory, startDate: moment(startDate).format("yyyy-MM-DD"), endDate: moment(endDate).format("yyyy-MM-DD") }}>
                                                        <img src={"https://storage.googleapis.com/staging-api-uploads/" + image.filestoragePath} alt="" className={classes.gridListTile} />
                                                    </Link>
                                                </ImageListItem>
                                            ))}
                                        </ImageList>

                                        <Stack spacing={2} style={{ float: 'right', marginTop: '1em' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
                                                <Typography>Page: {page + 1}</Typography>
                                                <Pagination count={totalPages} page={page + 1} siblingCount={0} onChange={handlePageChange} />
                                            </div>
                                        </Stack>
                                    </div>
                                )}
                            </div>

                        </div>

                    </div>
                </div>
            )}
        </div>
    );
}

export default BrandView;
