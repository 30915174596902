import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import "../../styles/general.scss";
import "../../styles/home.scss";
import { Paper } from "@mui/material";
import axios from "axios";
import { Alert } from "react-bootstrap";
import moment from "moment/moment";
import CustomDropDownFilters from "../../components/CustomDropDownFilters";
import DynamicDataTable from "../../components/DynamicDataTable";

const PlanogramComplianceExt = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);

  // DATE FILTERS VARIABLES
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  // TABLE DATA
  // LOWEST ONSHELF WITH CATEGORY
  const [fourPCTableLoading, setFourPCTableLoading] = useState(false);
  const [modifiedFourPCColumnDetails, setModifiedFourPCColumnDetails] =
    useState();
  const [fourPCRows, setFourPCRows] = useState(null);
  const [fourPCColumnHeaders, setFourPCColumnHeaders] = useState([]);
  const [fourPCPage, setFourPCPage] = React.useState(0);
  const [fourPCPageSize, setFourPCPageSize] = React.useState(50);
  const [fourPCRowCount, setFourPCRowCount] = React.useState();

  // CHANNEL FILTER VARIABLES
  const [channelsToShow, setChannelsToShow] = useState([]);
  const [channelSelectedAll, setChannelSelectedAll] = useState(false);
  const [channelFilters, setChannelFilters] = useState([]);

  // REGION FILTER VARIABLES
  const [regionsToShow, setRegionsToShow] = useState([]);
  const [regionSelectedAll, setRegionSelectedAll] = useState(false);
  const [regionFilters, setRegionFilters] = useState([]);

  // CITY FILTER VARIABLES
  const [citiesToShow, setCitiesToShow] = useState([]);
  const [citySelectedAll, setCitySelectedAll] = useState(false);
  const [cityFilters, setCityFilters] = useState([]);

  // AREA FILTER VARIABLES
  const [areasToShow, setAreasToShow] = useState([]);
  const [areaSelectedAll, setAreaSelectedAll] = useState(false);
  const [areaFilters, setAreaFilters] = useState([]);

  // STORE FILTER VARIABLES
  const [storesToShow, setStoresToShow] = useState([]);
  const [storeSelectedAll, setStoreSelectedAll] = useState(false);
  const [storeFilters, setStoreFilters] = useState([]);

    // DATA VARIABLES
    const [nationalPC, setNationalPC] = useState(null);
    const [regionalPC, setRegionalPC] = useState(null);
    const [basketPC, setBasketPC] = useState(null);
    const [channelPC, setChannelPC] = useState(null);

  useEffect(() => {
    const tempModifiedFourPCColumnDetails = [];
    if (fourPCColumnHeaders) {
      fourPCColumnHeaders
        .filter(function (column) {
          if (column === "ID") {
            return false; // skip
          }
          return true;
        })
        .map((header) => {
          {
            tempModifiedFourPCColumnDetails.push(header);
          }
        });
      setModifiedFourPCColumnDetails(tempModifiedFourPCColumnDetails);
    }
  }, [fourPCColumnHeaders]);

  const handleSelectStartDate = (newStartDate) => {
    setSelectedStartDate(newStartDate);

    if (newStartDate && !selectedEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else if (!newStartDate && selectedEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };

  const handleSelectEndDate = (newEndDate) => {
    setFourPCRows(null);
    setChannelPC(null);
    setRegionalPC(null);
    setSelectedEndDate(newEndDate);

    if (!selectedStartDate && newEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else if (selectedStartDate && !newEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };
  useEffect(() => {
    setFourPCRows(null);
    setChannelPC(null);
    setRegionalPC(null);
    const getFilterValues = async () => {
      setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/filter_values",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({
          email: props?.currentUser?.user,
          region: "",
        }),
      })
        .then((response) => {
          const responseData = response?.data;
          setChannelFilters(responseData?.channel);
          setRegionFilters(responseData?.region);
          setCityFilters(responseData?.city);
          setAreaFilters(responseData?.area);
          setStoreFilters(responseData?.store);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Filtered Values Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    getFilterValues();
  }, [props?.currentUser?.user]);

  useEffect(() => {
    const fetchNationalPc = async () => {
      setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/national_PC",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          startdate: selectedStartDate
            ? moment(selectedStartDate?.$d).format("YYYY-MM-DD")
            : "",
          enddate: selectedEndDate
            ? moment(selectedEndDate?.$d).format("YYYY-MM-DD")
            : "",
          email: props?.currentUser?.user,
        }),
      })
        .then((response) => {
          console.log(response, "national Pc");
          setIsLoading(false);
          const responseData = response?.data;
          setNationalPC(responseData[0]?.national_comliance);
        })
        .catch((error) => {
          setAlertError("something went wrong");
          setIsLoading(false);
          console.log("Get Filters Api: ", error);
        });
    };

    const fetchRegionalPc = async () => {
      setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/regional_PC",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          startdate: selectedStartDate
            ? moment(selectedStartDate?.$d).format("YYYY-MM-DD")
            : "",
          enddate: selectedEndDate
            ? moment(selectedEndDate?.$d).format("YYYY-MM-DD")
            : "",
          email: props?.currentUser?.user,
        }),
      })
        .then((response) => {
          console.log(response, "regional Pc");
          setIsLoading(false);
          const responseData = response?.data;
          setRegionalPC(responseData);
        })
        .catch((error) => {
          setAlertError("something went wrong");
          setIsLoading(false);
          console.log("Get Filters Api: ", error);
        });
    };

    const fetchBasketPc = async () => {
      setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/basket_PC",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          startdate: selectedStartDate
            ? moment(selectedStartDate?.$d).format("YYYY-MM-DD")
            : "",
          enddate: selectedEndDate
            ? moment(selectedEndDate?.$d).format("YYYY-MM-DD")
            : "",
          email: props?.currentUser?.user,
        }),
      })
        .then((response) => {
          console.log(response, "basket Pc");
          setIsLoading(false);
          const responseData = response?.data;
          setBasketPC(responseData?.merchandise_pct);
        })
        .catch((error) => {
          setAlertError("something went wrong");
          setIsLoading(false);
          console.log("Get Filters Api: ", error);
        });
    };

    const fetchChannelPc = async () => {
      setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/channel_PC",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          startdate: selectedStartDate
            ? moment(selectedStartDate?.$d).format("YYYY-MM-DD")
            : "",
          enddate: selectedEndDate
            ? moment(selectedEndDate?.$d).format("YYYY-MM-DD")
            : "",
          email: props?.currentUser?.user,
        }),
      })
        .then((response) => {
          console.log(response, "channel Pc");
          setIsLoading(false);
          const responseData = response?.data;
          setChannelPC(responseData);
        })
        .catch((error) => {
          setAlertError("something went wrong");
          setIsLoading(false);
          console.log("Get Filters Api: ", error);
        });
    };
    if (selectedStartDate) {
      if (selectedStartDate || selectedEndDate) {
        fetchNationalPc();
        fetchRegionalPc();
        fetchBasketPc();
        fetchChannelPc();
      }
    } else {
      fetchNationalPc();
      fetchRegionalPc();
      fetchBasketPc();
      fetchChannelPc();
    }
  }, [props?.currentUser?.user, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    const fetchTableData = async () => {
      setFourPCTableLoading(true);
      await axios({
        method: "post",
        url: "/api/PC_ext_table",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          startdate: selectedStartDate
            ? moment(selectedStartDate?.$d).format("YYYY-MM-DD")
            : "",
          enddate: selectedEndDate
            ? moment(selectedEndDate?.$d).format("YYYY-MM-DD")
            : "",
          email: props?.currentUser?.user,
          city: citiesToShow,
          store: storesToShow,
          area: areasToShow,
          region: regionsToShow,
          channel: channelsToShow,
          skip: fourPCPage,
          limit: fourPCPageSize,
        }),
      })
        .then((response) => {
          setFourPCTableLoading(false);
          const responseData = response?.data;
          setFourPCRows(responseData?.table);
          setFourPCRowCount(responseData?.rowCount);
          setFourPCColumnHeaders(responseData?.columns);
        })
        .catch((error) => {
          setFourPCTableLoading(false);
          setAlertError("something went wrong");
          console.log("Get Filters Api: ", error);
        });
    };
    if (selectedStartDate) {
      if (selectedStartDate || selectedEndDate) {
        fetchTableData();
      }
    } else {
      fetchTableData();
    }
 
  }, [
    props?.currentUser?.user,
    fourPCPage,
    fourPCPageSize,
    selectedStartDate,
    selectedEndDate,
    citiesToShow,
    storesToShow,
    areasToShow,
    regionsToShow,
    channelsToShow,
  ]);

  return (
    <div className="container-fluid main_container fourP_compliance">
      {/* {isLoading || alertError ? ( */}
          {alertError && <Alert variant="danger">{alertError}</Alert>}
          {(!fourPCRows && !regionalPC && !channelPC) ? (
        <div className="loading_area">
            <div className="col-12 d-flex justify-content-center loader">
              <div
                className="custom_loader spinner-border text-info"
                role="status"
                style={{ zIndex: "2" }}
              ></div>
              <div className="loader-message">Loading...</div>
            </div>
          {/*  */}
        </div>
      ) : (
        <>
          <div className="row align-items-center justify-content-between">
            <div className="page_heading col-5 p-0">
              <h4 className="page_heading_primary">
                Planogram
                <span className="page_heading_secondary">
                  &nbsp;Compliance
                </span>
              </h4>
            </div>
            <div className="date_cont col-5 p-0">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                         className={`${selectedStartDate && "date_selected"}`}
                  label="From"
                  inputFormat="DD-MMM-YYYY"
                  mask={"__-__-____ __"} 
                  value={selectedStartDate}
                  maxDate={selectedEndDate}
                  disableFuture={true}
                  onChange={handleSelectStartDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={startDateError}
                      helperText={null}
                      size="small"
                    />
                  )}
                />

                <DatePicker
                         className={`${selectedEndDate && "date_selected"}`}
                  label="To"
                  inputFormat="DD-MMM-YYYY"
                  mask={"__-__-____ __"} 
                  value={selectedEndDate}
                  minDate={selectedStartDate}
                  disableFuture={true}
                  onChange={handleSelectEndDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={endDateError}
                      helperText={null}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>

          <div className="filters_row row">
            <div className="col-2 d-flex align-items-center justify-content-center gap-2">
              <CustomDropDownFilters
                filterValues={channelFilters}
                isSelectedAll={channelSelectedAll}
                label="Channel"
                setValuesToShow={setChannelsToShow}
                setSelectedAll={setChannelSelectedAll}
                valuesToShow={channelsToShow ? channelsToShow : []}
              />
            </div>

            <div className="col-2 d-flex align-items-center justify-content-center gap-2">
              <CustomDropDownFilters
                filterValues={regionFilters}
                isSelectedAll={regionSelectedAll}
                label="Region"
                setValuesToShow={setRegionsToShow}
                setSelectedAll={setRegionSelectedAll}
                valuesToShow={regionsToShow ? regionsToShow : []}
              />
            </div>

            <div className="col-2 d-flex align-items-center justify-content-center gap-2">
              <CustomDropDownFilters
                filterValues={cityFilters}
                isSelectedAll={citySelectedAll}
                label="City"
                setValuesToShow={setCitiesToShow}
                setSelectedAll={setCitySelectedAll}
                valuesToShow={citiesToShow ? citiesToShow : []}
              />
            </div>

            <div className="col-2 d-flex align-items-center justify-content-center gap-2">
              <CustomDropDownFilters
                filterValues={areaFilters}
                isSelectedAll={areaSelectedAll}
                label="Area"
                setValuesToShow={setAreasToShow}
                setSelectedAll={setAreaSelectedAll}
                valuesToShow={areasToShow ? areasToShow : []}
              />
            </div>

            <div className="col-2 d-flex align-items-center justify-content-center gap-2">
              <CustomDropDownFilters
                filterValues={storeFilters}
                isSelectedAll={storeSelectedAll}
                label="Store"
                setValuesToShow={setStoresToShow}
                setSelectedAll={setStoreSelectedAll}
                valuesToShow={storesToShow ? storesToShow : []}
              />
            </div>
          </div>
          <div className="row graph_row regional_section custom_cards gap-3">
            <div className="col-3 d-flex flex-column justify-content-between p-0">
            <div className="custom_bg">
              <div className="d-flex gap-3 flex-column">
                <h5 className="custom_card_heading">National Compliance</h5>
                <Paper
                  elevation={6}
                  square={true}
                  className="custom_paper w-50"
                >
                  <div className="d-flex flex-column align-items-center">
                    <h4 className="percentage_value">
                      {nationalPC ? nationalPC : ""}%
                    </h4>
                  </div>
                </Paper>
              </div>
            </div>
            <div className="custom_bg d-flex gap-3 flex-column" style={{minHeight:'180px'}}>
              <h5 className="custom_card_heading">Regional Compliance</h5>
              <div className="channel_compliance data-section">
                <div className="custom_section">
                  {regionalPC?.map((region, index) => (
                    <h3 className="medium_heading">
                      <strong>{region?.name}</strong>
                    </h3>
                  ))}
                
                </div>

                <div className="custom_cards">
                  <Paper
                    elevation={6}
                    square={true}
                    className="custom_paper custom_section"
                  >
                    {regionalPC?.map((region, index) => (
                      <div key={index} className="d-flex align-items-center">
                        <h4 className="percentage_value">
                          {region ? region.value : ""}%
                        </h4>
                      </div>
                    ))}
                   
                  </Paper>
                </div>
              </div>
            </div>
          
            </div>
            <div className="col-3 d-flex flex-column justify-content-between p-0">
            <div className="custom_bg">
              <div className="d-flex gap-3 flex-column">
                <h5 className="custom_card_heading">Basket Presence</h5>
                <Paper
                  elevation={6}
                  square={true}
                  className="custom_paper w-50"
                >
                  <div className="d-flex flex-column align-items-center">
                    <h4 className="percentage_value">
                      {basketPC ? basketPC : ""}%
                    </h4>
                  </div>
                </Paper>
              </div>
            </div>
            <div className="custom_bg d-flex gap-3 flex-column" style={{minHeight:'180px'}}>
              <h5 className="custom_card_heading">Channel Wise</h5>
              <div className="channel_compliance data-section">
                <div className="custom_section">
                  {channelPC?.map((channel, index) => (
                    <h3 className="medium_heading">
                      <strong>{channel?.name}</strong>
                    </h3>
                  ))}
                
                </div>

                <div className="custom_cards">
                  <Paper
                    elevation={6}
                    square={true}
                    className="custom_paper custom_section"
                  >
                    {channelPC?.map((channel, index) => (
                      <div key={index} className="d-flex align-items-center">
                        <h4 className="percentage_value">
                          {channel ? channel.value : ""}%
                        </h4>
                      </div>
                    ))}
                   
                  </Paper>
                </div>
              </div>
            </div>
            </div>
            <div className="custom_bg col-5 hideFooter d-flex gap-2 flex-column" style={{height:'300px'}}>
              {fourPCRows && (
                <DynamicDataTable
                  rowID={"ID"}
                  columnHeaders={modifiedFourPCColumnDetails}
                  rows={fourPCRows ? fourPCRows : []}
                  isLoading={fourPCTableLoading}
                  setPageSize={setFourPCPageSize}
                  pageSize={fourPCPageSize}
                  setPage={setFourPCPage}
                  page={fourPCPage}
                  rowCount={fourPCRowCount}
                  // <------>CV DATA
                  showDownload={true}
                  csvLink={"PC_table_Excelfile"}
                  csvName={"PC_table_Excelfile.xlsx"}
                  filters={{
                    startdate: selectedStartDate
                    ? moment(selectedStartDate?.$d).format("YYYY-MM-DD")
                    : "",
                  enddate: selectedEndDate
                    ? moment(selectedEndDate?.$d).format("YYYY-MM-DD")
                    : "",
                  city: citiesToShow,
                  store: storesToShow,
                  area: areasToShow,
                  region: regionsToShow,
                  channel: channelsToShow,
                  skip: fourPCPage,
                  limit: fourPCPageSize,
                  email: props?.currentUser?.user,
                  }}
                />
              )}
            </div>
            {/* <div className="col-9 custom_bg">
              <div className="d-flex gap-3 flex-column">
                <h5 className="custom_card_heading">Regional Compliance</h5>
                <Paper
                  elevation={6}
                  square={true}
                  className="custom_paper w-100 d-flex w-100 justify-content-around"
                >
                  {regionalPC?.map((region, index) => (
                    <div
                      key={index}
                      className="d-flex flex-column align-items-center"
                    >
                      <h4 className="percentage_value">{region?.value}%</h4>
                      <label htmlFor="city" className="city_label">
                        {region?.name}
                      </label>
                    </div>
                  ))}
                </Paper>
              </div>
            </div> */}
          
          
          </div>

          {/* <div className="row regional_section custom_cards gap-3">
         
          
           

            <div className="col-9 graph_column custom_bg">
              <h5 className="custom_card_heading">National Compliance Trend</h5>
              <LineChart
                lineGraphData={{
                  line1: {
                    label: "Marvin",
                    data: [50, 34, 56, 23, 34, 1, 54, 16, 53, 31],
                    color: "6B88CA",
                  },
                }}
                labels={["jan", "fab", "march", "may", "june"]}
                title="line Chart"
              />
            </div>
          </div> */}
          {/* <div className="row justify-content-center">
            
          </div> */}
        </>
      )}
    </div>
  );
};

export default PlanogramComplianceExt;
