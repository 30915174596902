import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import "../../styles/general.scss";
import "../../styles/home.scss";
import { Paper } from "@mui/material";
import axios from "axios";
import { Alert } from "react-bootstrap";
import moment from "moment/moment";
import CustomDropDownFilters from "../../components/CustomDropDownFilters";
import DynamicDataTable from "../../components/DynamicDataTable";

const PriceExt = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);

  const [reportDownloaded, setReportDownloaded] = useState(0);
  const [downloadReoprtName, setDownloadReoprtName] = useState("");
  const [logId, setLogId] = useState(null);

  // DATE FILTERS VARIABLES
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  //<------ TABLE DATA ------->

  // LOWEST ONSHELF WITH CATEGORY
  const [fourPCTableLoading, setFourPCTableLoading] = useState(false);
  const [modifiedFourPCColumnDetails, setModifiedFourPCColumnDetails] =
    useState();
  const [fourPCRows, setFourPCRows] = useState([]);
  const [fourPCColumnHeaders, setFourPCColumnHeaders] = useState([]);
  const [fourPCPage, setFourPCPage] = React.useState(0);
  const [fourPCPageSize, setFourPCPageSize] = React.useState(50);
  const [fourPCRowCount, setFourPCRowCount] = React.useState();

  // CHANNEL FILTER VARIABLES
  const [channelsToShow, setChannelsToShow] = useState([]);
  const [channelSelectedAll, setChannelSelectedAll] = useState(false);
  const [channelFilters, setChannelFilters] = useState([]);

  // REGION FILTER VARIABLES
  const [regionsToShow, setRegionsToShow] = useState([]);
  const [regionSelectedAll, setRegionSelectedAll] = useState(false);
  const [regionFilters, setRegionFilters] = useState([]);

  // AREA FILTER VARIABLES
  const [areasToShow, setAreasToShow] = useState([]);
  const [areaSelectedAll, setAreaSelectedAll] = useState(false);
  const [areaFilters, setAreaFilters] = useState([]);

  // STORE FILTER VARIABLES
  const [storesToShow, setStoresToShow] = useState([]);
  const [storeSelectedAll, setStoreSelectedAll] = useState(false);
  const [storeFilters, setStoreFilters] = useState([]);

  // Territory Filter
  const [territorySelectedAll, setTerritorySelectedAll] = useState(false);
  const [territoryFilters, setTerritoryFilters] = useState([]);
  const [territoryToShow, setTerritoryToShow] = useState([]);
  // DATA VARIABLES
  const [nationalPC, setNationalPC] = useState([]);
  const [regionalPC, setRegionalPC] = useState([]);
  const [basketPC, setBasketPC] = useState([]);
  const [channelPC, setChannelPC] = useState([]);

  useEffect(() => {
    const tempModifiedFourPCColumnDetails = [];
    if (fourPCColumnHeaders) {
      fourPCColumnHeaders
        .filter(function (column) {
          if (column === "ID") {
            return false; // skip
          }
          return true;
        })
        .map((header) => {
          {
            tempModifiedFourPCColumnDetails.push(header);
          }
        });
      setModifiedFourPCColumnDetails(tempModifiedFourPCColumnDetails);
    }
  }, [fourPCColumnHeaders]);
  console.log(regionalPC, "regionallllll pc");
  useEffect(() => {
    const getFilterValues = async () => {
      setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/filter_values",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email: props?.currentUser?.user,
          region: "",
        }),
      })
        .then((response) => {
          const responseData = response?.data;
          setChannelFilters(responseData?.channel);
          setRegionFilters(responseData?.region);
          setTerritoryFilters(responseData?.storeTerritory);
          setAreaFilters(responseData?.area);
          setStoreFilters(responseData?.store);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Filtered Values Api: ", error);
          setIsLoading(false);
          setAlertError("Something went wrong. Please try again.");
        });
    };
    const userLogActivity = async () => {
      setAlertError("");

      await axios({
        method: "post",
        url: "/api/LogUserActivity",
        data: {
          page: "Price List Activity Started",
          email: props?.currentUser?.user,
        },
      })
        .then((response) => {
          console.log(response, "actoivity");
          setLogId(response?.data?.LogId);
        })
        .catch(function (error) {
          console.log("Get Databases Api: ", error);
          setAlertError("something went wrong");
        });
    };
    getFilterValues();
    userLogActivity();
  }, [props?.currentUser?.user]);

  useEffect(() => {
    const fetchRegionalPc = async () => {
      setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/price_region",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          startdate: selectedStartDate
            ? moment(selectedStartDate?.$d).format("YYYY-MM-DD")
            : "",
          enddate: selectedEndDate
            ? moment(selectedEndDate?.$d).format("YYYY-MM-DD")
            : "",
          email: props?.currentUser?.user,
        }),
      })
        .then((response) => {
          console.log(response, "regional Pc");
          setIsLoading(false);
          const responseData = response?.data;
          setRegionalPC(responseData?.data);
          setNationalPC(responseData?.overall_percentage);
        })
        .catch((error) => {
          setAlertError("something went wrong");
          setIsLoading(false);
          console.log("Get Filters Api: ", error);
        });
    };

    const fetchChannelPc = async () => {
      setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/price_channel",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          startdate: selectedStartDate
            ? moment(selectedStartDate?.$d).format("YYYY-MM-DD")
            : "",
          enddate: selectedEndDate
            ? moment(selectedEndDate?.$d).format("YYYY-MM-DD")
            : "",
          email: props?.currentUser?.user,
        }),
      })
        .then((response) => {
          console.log(response, "channel Pc");
          setIsLoading(false);
          const responseData = response?.data;
          setChannelPC(responseData?.data);
        })
        .catch((error) => {
          setAlertError("something went wrong");
          setIsLoading(false);
          console.log("Get Filters Api: ", error);
        });
    };
    if (selectedStartDate) {
      if (selectedStartDate && selectedEndDate) {
        fetchRegionalPc();
        fetchChannelPc();
      }
    } else {
      fetchRegionalPc();
      fetchChannelPc();
    }
  }, [props?.currentUser?.user, selectedStartDate, selectedEndDate]);

  const handleSelectStartDate = (newStartDate) => {
    setSelectedStartDate(newStartDate);

    if (newStartDate && !selectedEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else if (!newStartDate && selectedEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };

  const handleSelectEndDate = (newEndDate) => {
    setSelectedEndDate(newEndDate);

    if (!selectedStartDate && newEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else if (selectedStartDate && !newEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };

  useEffect(() => {
    const fetchTableData = async () => {
      setFourPCTableLoading(true);
      await axios({
        method: "post",
        url: "/api/Price_ext_table",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          startdate: selectedStartDate
            ? moment(selectedStartDate?.$d).format("YYYY-MM-DD")
            : "",
          enddate: selectedEndDate
            ? moment(selectedEndDate?.$d).format("YYYY-MM-DD")
            : "",
          territory: territoryToShow,
          store: storesToShow,
          area: areasToShow,
          region: regionsToShow,
          channel: channelsToShow,
          skip: fourPCPage,
          limit: fourPCPageSize,
          email: props?.currentUser?.user,
        }),
      })
        .then((response) => {
          setFourPCTableLoading(false);
          const responseData = response?.data;
          console.log(responseData, "respon table data");
          setFourPCRows(responseData?.table);
          setFourPCRowCount(responseData?.rowCount);
          setFourPCColumnHeaders(responseData?.columns);
        })
        .catch((error) => {
          setFourPCTableLoading(false);
          setAlertError("something went wrong");
          console.log("Get Filters Api: ", error);
        });
    };

    if (selectedStartDate) {
      if (selectedStartDate && selectedEndDate) {
        fetchTableData();
      }
    } else {
      fetchTableData();
      if (logId) {
        console.log("api is called");
        recordLogActivity();
      }
    }
  }, [
    props?.currentUser?.user,
    fourPCPage,
    fourPCPageSize,
    selectedStartDate,
    selectedEndDate,
    territoryToShow,
    storesToShow,
    areasToShow,
    regionsToShow,
    channelsToShow,
    logId,
  ]);
  // useEffect(() => {
  //   console.log("api is called");
  //   console.log(logId,downloadReoprtName)
  //   if (logId && downloadReoprtName !== "") {
  //     recordLogActivity();
  //   }
  // }, [logId, downloadReoprtName]);

  const recordLogActivity = async (downloaded, fileName) => {
    setAlertError("");

    await axios({
      method: "post",
      url: "/api/LogUserReportActivity",
      data: {
        startDate: selectedStartDate
          ? moment(selectedStartDate?.$d).format("YYYY-MM-DD")
          : "",
        endDate: selectedEndDate
          ? moment(selectedEndDate?.$d).format("YYYY-MM-DD")
          : "",
        territory: territoryToShow,
        store: storesToShow,
        area: areasToShow,
        region: regionsToShow,
        channel: channelsToShow,
        Downloaded: downloaded ? downloaded : 0,
        report: fileName ? fileName : "",
        LogId: logId,
        email: props?.currentUser?.user,
      },
    })
      .then((response) => {
        console.log(response, "activry recorded");
      })
      .catch(function (error) {
        console.log("Get Databases Api: ", error);
        setAlertError("log Activity error");
      });
  };
  return (
    <div className="container-fluid main_container fourP_compliance">
      {isLoading || alertError ? (
        <div className="loading_area">
          {alertError && <Alert variant="danger">{alertError}</Alert>}
          {isLoading && (
            <div className="col-12 d-flex justify-content-center loader">
              <div
                className="custom_loader spinner-border text-info"
                role="status"
                style={{ zIndex: "2" }}
              ></div>
              <div className="loader-message">Loading...</div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="row align-items-center justify-content-between">
            <div className="page_heading col-5 p-0">
              <h4 className="page_heading_primary">
                Price List
                <span className="page_heading_secondary">
                  &nbsp;Availability
                </span>
              </h4>
            </div>
          </div>

          <div className="filters_row row">
            <CustomDropDownFilters
              filterValues={channelFilters}
              isSelectedAll={channelSelectedAll}
              label="Channel"
              setValuesToShow={setChannelsToShow}
              setSelectedAll={setChannelSelectedAll}
              valuesToShow={channelsToShow ? channelsToShow : []}
            />

            <CustomDropDownFilters
              filterValues={regionFilters}
              isSelectedAll={regionSelectedAll}
              label="Region"
              setValuesToShow={setRegionsToShow}
              setSelectedAll={setRegionSelectedAll}
              valuesToShow={regionsToShow ? regionsToShow : []}
            />

            <CustomDropDownFilters
              filterValues={areaFilters}
              isSelectedAll={areaSelectedAll}
              label="Distribution"
              setValuesToShow={setAreasToShow}
              setSelectedAll={setAreaSelectedAll}
              valuesToShow={areasToShow ? areasToShow : []}
            />
            <CustomDropDownFilters
              filterValues={territoryFilters}
              isSelectedAll={territorySelectedAll}
              label="Territory"
              setValuesToShow={setTerritoryToShow}
              setSelectedAll={setTerritorySelectedAll}
              valuesToShow={territoryToShow ? territoryToShow : []}
            />

            <CustomDropDownFilters
              filterValues={storeFilters}
              isSelectedAll={storeSelectedAll}
              label="Store"
              setValuesToShow={setStoresToShow}
              setSelectedAll={setStoreSelectedAll}
              valuesToShow={storesToShow ? storesToShow : []}
            />
            <div className="date-picker-container col-5 d-flex justify-content-end">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{ width: "150px" }}>
                  <DatePicker
                    className={`custom_date_picker ${
                      selectedStartDate && "date_selected"
                    }`}
                    label="From"
                    inputFormat="DD-MMM-YYYY"
                    mask={"__-__-____ __"}
                    value={selectedStartDate}
                    maxDate={selectedEndDate}
                    disableFuture={true}
                    onChange={handleSelectStartDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={startDateError}
                        helperText={null}
                        size="small"
                      />
                    )}
                  />
                </div>
                <div style={{ width: "150px" }}>
                  <DatePicker
                    className={`custom_date_picker ${
                      selectedEndDate && "date_selected"
                    }`}
                    label="To"
                    inputFormat="DD-MMM-YYYY"
                    mask={"__-__-____ __"}
                    value={selectedEndDate}
                    minDate={selectedStartDate}
                    disableFuture={true}
                    onChange={handleSelectEndDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={endDateError}
                        helperText={null}
                        size="small"
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </div>
          </div>
          <div className="row total_boxes justify-content-between">
            <div className="col-4 p-0 col-md-2 col-lg-auto left_cards d-flex flex-column justify-content-end">
              <h5 className="custom_card_heading mb-2">National</h5>
              <div className="total_box box_secondary">
                <label htmlFor="">Price List Availability</label>
                <strong>{nationalPC ? nationalPC : ""}%</strong>
                <span>{`{${ regionalPC.reduce((sum, region) => sum + region.store_count, 0)}}`}</span>
              </div>
            </div>
            <div className="col-md-8 d-flex flex-column justify-content-end">
              <h5 className="custom_card_heading mb-2">
                {" "}
                Regional Price List Availability
              </h5>
              <div className="row w-100 right_cards">
                {regionalPC?.map((region, index) => (
                  <div className="col col-md-2 col-lg-auto total_box box_secondary">
                    <label htmlFor="">{region?.name}</label>
                    <strong>
                      {region?.value}
                      {region?.name !== "Store Count" ? "%" : ""}
                    </strong>
                      <span>{`{${region?.store_count}}`}</span>         
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="row gap-3 multi_channels">
            <div className="custom_bg col d-flex gap-2 flex-column">
              <h5 className="custom_card_heading">Channel Wise:</h5>
              <div className="channel_compliance data-section">
                {channelPC?.map((channel, index) => (
                  <div className="custom_section">
                    <h5 className="medium_heading">{channel?.name}</h5>
                    <div className="custom_cards">
                      <Paper
                        elevation={6}
                        square={true}
                        className="custom_paper w-100 d-flex w-100 justify-content-around"
                      >
                        <div className="d-flex flex-column align-items-center">
                          <h4 className="percentage_value">
                            {channel?.value}%
                          </h4>
                          <label htmlFor="city" className="city_label">
                            Value
                          </label>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <h4 className="percentage_value">
                            {channel?.store_count}
                          </h4>
                          <label htmlFor="city" className="city_label">
                            Total
                          </label>
                        </div>
                      </Paper>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="custom_bg col-7 hideFooter d-flex gap-2 flex-column">
              {fourPCRows?.length > 0 && (
                <DynamicDataTable
                  rowID={"ID"}
                  columnHeaders={modifiedFourPCColumnDetails}
                  rows={fourPCRows}
                  isLoading={fourPCTableLoading}
                  setPageSize={setFourPCPageSize}
                  pageSize={fourPCPageSize}
                  setPage={setFourPCPage}
                  page={fourPCPage}
                  rowCount={fourPCRowCount}
                  // <------>CV DATA
                  setReportDownloaded={setReportDownloaded}
                  setDownloadReoprtName={setDownloadReoprtName}
                  recordLogActivity={recordLogActivity}
                  showDownload={true}
                  csvLink={"Price_ext_table_Excel"}
                  csvName={"Price_ext_table.xlsx"}
                  filters={{
                    startdate: selectedStartDate
                      ? moment(selectedStartDate?.$d).format("YYYY-MM-DD")
                      : "",
                    enddate: selectedEndDate
                      ? moment(selectedEndDate?.$d).format("YYYY-MM-DD")
                      : "",
                    territory: territoryToShow,
                    store: storesToShow,
                    area: areasToShow,
                    region: regionsToShow,
                    channel: channelsToShow,
                    skip: fourPCPage,
                    limit: fourPCPageSize,
                    email: props?.currentUser?.user,
                  }}
                  // <------>
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PriceExt;
