import { useState, useEffect } from 'react';

export const useDetectOutsideClick = (el, initialState) =>
 {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => 
  {
    const onClick = e => 
    {
      if (el.current && !el.current.contains(e.target)) 
      {
        setIsActive(!isActive);
      }
    }

    if (isActive) 
    {
      document.addEventListener("mousedown", onClick);
    }

    return () => 
    {
      document.removeEventListener("mousedown", onClick);
    };

  }, [isActive, el]);

  return [isActive, setIsActive];
}