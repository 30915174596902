import React, { useRef } from 'react';
import { useDetectOutsideClick } from './useDetectOutsideClick';
import { Avatar } from '@material-ui/core';
import '../../styles/photohub/general.css';

const LoggedUser = (props) =>
{ 
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);

  return (
    <div className = "loggedUser">
      <div className = "menu-container">
      <span style = {{ fontWeight: 'bold', marginRight: '10px' }}>{currentUser.username}</span>
        <button onClick = {onClick} className = "menu-trigger">
          <Avatar />
        </button>
        <nav ref = {dropdownRef} className = {`menu ${isActive ? "active" : "inactive"}`}>
          <ul>
            <li>
              <a href = "/login" onClick = {props.logOut}>Logout</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  ); 
}

export default LoggedUser;