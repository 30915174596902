import React, { useState, useEffect } from 'react';
import LoggedUser from './LoggedUser';
import Tabs from './Tabs';
import { Navigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dropdown/style.css';
import '../../styles/photohub/MasterData.css';
import '../../styles/photohub/general.css';

function MasterData(props)
{  
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const [isLoading, setIsLoading] = useState(false);
    const [storeDataErrorMessage, setStoreDataErrorMessage] = useState(null);
    const [productDataErrorMessage, setProductDataErrorMessage] = useState(null);
    const [storeMasterData, setStoreMasterData] = useState([]);
    const [productMasterData, setProductMasterData] = useState([]);

    const storeColumns = [
        {
            name: "Code",
            selector: (row) => row.storeCode
        },
        {
            name: "Name",
            selector: (row) => row.storeName
        },
        {
            name: "Address",
            selector: (row) => row.storeAddress
        },
        {
            name: "City",
            selector: (row) => row.storeCity
        },
        {
            name: "Region",
            selector: (row) => row.storeRegion
        },
        {
            name: "Channel",
            selector: (row) => row.storeChannel
        },
        {
            name: "Sub-Channel",
            selector: (row) => row.storeSubChannel
        },
        {
            name: "Latitude",
            selector: (row) => row.storeLatitude
        },
        {
            name: "Longitude",
            selector: (row) => row.storeLongitude
        }
    ];

    const productColumns = [
        {
            name: "Name",
            selector: (row) => row.productName
        },
        {
            name: "Short Name",
            selector: (row) => row.productShortName
        },
        {
            name: "Width",
            selector: (row) => row.productWidth
        },
        {
            name: "Height",
            selector: (row) => row.productHeight
        },
    ];

    useEffect(() => 
    {
        async function fetchData() 
        {
            setIsLoading(true);
            setStoreDataErrorMessage(null);
            setProductDataErrorMessage(null);

            try
            {
                const storeApiResponse = await fetch('/api/storeMasterData', {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }
                });
        
                const storeApiData = await storeApiResponse.json();
                var storeData = [];
        
                for (const dataObjects of storeApiData.storeData)
                    storeData.push(dataObjects);
        
                setStoreMasterData(storeData);
            }
            catch(error)
            {
                console.log("Store Master Data Api Error: ", error);
                setStoreDataErrorMessage("Internal Server Error! Please try again later.");
                setIsLoading(false);
            }

            try
            {
                const brand = JSON.parse(localStorage.getItem("brandID"));
                const productApiResponse = await fetch('/api/productMasterData', {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ brandID: brand[0].brandID }) 
                });
        
                const productApiData = await productApiResponse.json();
                var productData = [];
        
                for (const dataObjects of productApiData.productData)
                    productData.push(dataObjects);
        
                setProductMasterData(productData);
            }
            catch(error)
            {
                console.log("Product Master Data Api Error: ", error);
                setProductDataErrorMessage("Internal Server Error! Please try again later.");
                setIsLoading(false);
            }

            setIsLoading(false);
        }
        
        fetchData();
    }, [])

    return (
        <>
              {!props?.currentUser?.user ? (
                <Navigate to = "/login" replace = {true} />
            ) : (
                <div style = {{ marginBottom: '1em' }}>

                    <div className = "content-container">
                        <h2 style = {{ marginTop: '20px' }}>Photo Hub</h2>
                        {/* <LoggedUser logOut = {props.logOut}/> */}

                        <Tabs style = {{ marginRight: '100px' }}>
                            <div label = "Store" style = {{ height: '100px', overflowY: 'auto', display: 'flex' }}>
                                {isLoading ? (
                                    <div className = "d-flex justify-content-center">
                                        <br />
                                        <div className = "spinner-border text-info" role = "status">
                                            <span className = "sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : storeDataErrorMessage ? (
                                    <span>{storeDataErrorMessage}</span>
                                ) : (
                                    <div className = "table-overflow">
                                        <DataTable
                                            columns = {storeColumns}
                                            data = {storeMasterData}
                                            pagination
                                        />
                                    </div>
                                )}
                            </div>
                            <div label = "Product">
                                {isLoading ? (
                                    <div className = "d-flex justify-content-center">
                                        <br />
                                        <div className = "spinner-border text-info" role = "status">
                                            <span className = "sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : productDataErrorMessage ? (
                                    <span>{productDataErrorMessage}</span>
                                ) : (                                    
                                    <div className = "table-overflow">
                                        <DataTable
                                            columns = {productColumns}
                                            data = {productMasterData}
                                            pagination
                                        />
                                    </div>
                                )}
                            </div>
                        </Tabs>
                    </div>
                </div>
            )}
        </>
    );
}

export default MasterData;