import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import LoggedUser from './LoggedUser';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { Navigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dropdown/style.css';
import '../../styles/photohub/general.css';

const VisitData = (props) => {
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [errorMessage, setErrorMessage] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [countryList, setCountryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [storeList, setStoreList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedChannel, setSelectedChannel] = useState('');
    const [selectedStore, setSelectedStore] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [visitData, setVisitData] = useState([]);
    const [filteredVisitData, setFilteredVisitData] = useState([]);
    const [filtered, setFiltered] = useState(false);
    const [csvData, setCSVData] = useState([]);
    const [csvHeaders, setCSVHeaders] = useState([]);

    const visitColumns = [
        {
            name: "Visit ID",
            selector: (row) => row.visitID
        },
        {
            name: "Visit Date",
            selector: (row) => row.visitDate
        },
        {
            name: "Visitor Name",
            selector: (row) => row.userName
        },
        {
            name: "Store Code",
            selector: (row) => row.storeCode
        },
        {
            name: "Store Name",
            selector: (row) => row.storeName
        },
        {
            name: "Store Region",
            selector: (row) => row.storeRegion
        },
        {
            name: "Store City",
            selector: (row) => row.storeCity
        },
        {
            name: "Store Area",
            selector: (row) => row.storeArea
        },
        {
            name: "Store Channel",
            selector: (row) => row.storeChannel
        }
    ];

    useEffect(() => {
        setIsLoading(true);
        setErrorMessage(null);

        async function fetchData() {
            try {
                const response = await fetch('/api/filterLists', {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }
                });
                const data = await response.json();

                const countries = [];
                const cities = [];
                const channels = [];
                const stores = [];

                for (const dataObject of data.filters) {
                    if (!countries.some(country => country.toLowerCase().includes(dataObject.countryName.toLowerCase().trim()))) {
                        countries.push(dataObject.countryName.trim());
                    }

                    if (!cities.some(city => city.toLowerCase().includes(dataObject.storeCity.toLowerCase().trim()))) {
                        cities.push(dataObject.storeCity.trim());
                    }

                    if (!channels.some(channel => channel.toLowerCase().includes(dataObject.storeChannel.toLowerCase().trim()))) {
                        channels.push(dataObject.storeChannel.trim());
                    }

                    if (!stores.some(store => store.toLowerCase().includes(dataObject.storeName.toLowerCase().trim()))) {
                        stores.push(dataObject.storeName.trim());
                    }
                }

                setCountryList(countries);
                setCityList(cities);
                setChannelList(channels);
                setStoreList(stores);
            } catch (error) {
                console.log("Filter Lists Api Error: ", error);
                setErrorMessage("Internal Server Error! Please try again later.");
            }

            try {
                const api = await fetch('/api/visitMasterData', {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ currentDate: startDate })
                });

                const response = await api.json();
                const data = response.visitData;

                setVisitData(data);
                setFilteredVisitData(data);
            } catch (error) {
                console.log("Visit Master Data Api Error: ", error);
                setErrorMessage("Internal Server Error! Please try again later.");
            }

            setIsLoading(false);
        }

        fetchData();
    }, []);

    const updateStartDate = (date) => {
        setStartDate(date);
    }

    const updateEndDate = (date) => {
        setEndDate(date);
    }

    const updateCountry = (e) => {
        setSelectedCountry(e.target.value);
    }

    const updateCity = (e) => {
        setSelectedCity(e.target.value);
    }

    const updateChannel = (e) => {
        setSelectedChannel(e.target.value);
    }

    const updateStore = (e) => {
        setSelectedStore(e.target.value);
    }

    const updateCategory = (e) => {
        setSelectedCategory(e.target.value);
    }

    const handleFilter = () => {
        if (Date.parse(endDate) - Date.parse(startDate) >= 0) {
            setIsLoading(true);
            setErrorMessage(null);

            async function fetchFilteredData() {
                try {
                    const api = await fetch('/api/filteredVistMasterData', {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            country: selectedCountry,
                            city: selectedCity,
                            channel: selectedChannel,
                            store: selectedStore,
                            category: selectedCategory,
                            startDate: moment(startDate).format("yyyy-MM-DD"),
                            endDate: moment(endDate).format("yyyy-MM-DD")
                        })
                    });

                    const response = await api.json();

                    setFilteredVisitData(response);
                    setFiltered(true);
                } catch (error) {
                    console.log("Filtered Visit Master Data Api Error: ", error);
                    setErrorMessage("Internal Server Error! Please try again later.");
                }

                setIsLoading(false);
            }

            fetchFilteredData();
        } else {
            alert("Invalid Date Range!");
        }
    }

    const handleReset = () => {
        setFilteredVisitData(visitData);
        setFiltered(false);
        setStartDate(new Date());
        setEndDate(new Date());
        setSelectedCountry('');
        setSelectedCity('');
        setSelectedChannel('');
        setSelectedStore('');
        setSelectedCategory('');
    }

    const handleExtractData = () => {
        const columns = [
            "visitID",
            "visitDate",
            "userName",
            "storeCode",
            "storeName",
            "storeRegion",
            "storeCity",
            "storeArea",
            "storeChannel"
        ];

        setCSVHeaders(columns);
        setCSVData(filteredVisitData);
    }

    return (
        <>
              {!props?.currentUser?.user ? (
                <Navigate to="/login" replace={true} />
            ) : (
                <div>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                        <div className="extractDataButtonContainer">
                            <h2>Photo Hub</h2>
                            {!isLoading && filteredVisitData && filteredVisitData.length > 0 && (
                                <CSVLink
                                    className="btn btn-info extractDataButton"
                                    data={csvData}
                                    headers={csvHeaders}
                                    asyncOnClick={true}
                                    filename={"VisitData.csv"}
                                    onClick={handleExtractData}
                                >
                                    Extract Data
                                </CSVLink>
                            )}
                        </div>
                        {/* <LoggedUser logOut={props.logOut} /> */}
                    </div>
                    <div className={`dataFilter ${filteredVisitData && filteredVisitData.length > 0 ? 'dataAvailable' : ''}`}>
                        <span>Filter</span>

                        <div className="row">
                            <div className="col-md-6" style={{ paddingRight: '5px' }}>
                                <span style={{ fontWeight: 'bold', fontSize: '13px' }}>Start Date</span>
                                <DatePicker dateFormat="dd/MM/yyyy" selected={startDate} onChange={updateStartDate} />
                            </div>
                            <div className="col-md-6" style={{ paddingLeft: '5px' }}>
                                <span style={{ fontWeight: 'bold', fontSize: '13px' }}>End Date</span>
                                <DatePicker dateFormat="dd/MM/yyyy" selected={endDate} onChange={updateEndDate} />
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: '5px' }}>
                            <div className="col-md-6" style={{ paddingRight: '5px' }}>
                                <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>Country</span>
                                <select name="selectCountry" id="selectCountry" style={{ width: '100%', fontSize: '14px' }} onChange={updateCountry} value={selectedCountry}>
                                    <option value="" disabled hidden>Select Country</option>
                                    {countryList?.map((country) => <option key={country} value={country}>{country}</option>)}
                                </select>
                            </div>
                            <div className="col-md-6" style={{ paddingLeft: '5px' }}>
                                <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>City</span>
                                <select name="selectCity" id="selectCity" style={{ width: '100%', fontSize: '13px' }} onChange={updateCity} value={selectedCity}>
                                    <option value="" disabled hidden>Select City</option>
                                    {cityList?.map((city) => <option key={city} value={city}>{city}</option>)}
                                </select>
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: '5px' }}>
                            <div className="col-md-12">
                                <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>Channel</span>
                                <select name="selectChannel" id="selectChannel" style={{ width: '100%', fontSize: '13px' }} onChange={updateChannel} value={selectedChannel}>
                                    <option value="" disabled hidden>Select Channel</option>
                                    {channelList?.map((channel) => <option key={channel} value={channel}>{channel}</option>)}
                                </select>
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: '5px' }}>
                            <div className="col-md-12">
                                <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>Store</span>
                                <select name="selectStore" id="selectStore" style={{ width: '100%', fontSize: '13px' }} onChange={updateStore} value={selectedStore}>
                                    <option value="" disabled hidden>Select Store</option>
                                    {storeList?.map((store) => <option key={store} value={store}>{store}</option>)}
                                </select>
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: '5px' }}>
                            <div className="col-md-12">
                                <span style={{ fontWeight: 'bold', fontSize: '13px', display: 'block' }}>Category</span>
                                <select name="selectCategory" id="selectCategory" style={{ width: '100%', fontSize: '13px' }} onChange={updateCategory} value={selectedCategory}>
                                    <option value="" disabled hidden>Select Category</option>
                                    <option value="14">Product</option>
                                    <option value="15">Price</option>
                                    <option value="16">Placement</option>
                                    <option value="17">Promotion</option>
                                </select>
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: '15px' }}>
                            <div className="col-md-6">
                                <button className="button_primary" onClick={handleReset} disabled={!filtered || isLoading}>Reset</button>
                            </div>
                            <div className="col-md-6">
                                <button className="button_primary" onClick={handleFilter} disabled={isLoading}>Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default VisitData;
