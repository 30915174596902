import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import "../../styles/general.scss";
import "../../styles/home.scss";
import { Paper } from "@mui/material";
import axios from "axios";
import { Alert } from "react-bootstrap";
import moment from "moment/moment";
import LineChart from "../../components/LineChart";

const PriceList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);

  // DATE FILTERS VARIABLES
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  // DATA VARIABLES
  const [nationalPC, setNationalPC] = useState([]);
  const [regionalPC, setRegionalPC] = useState([]);
  const [basketPC, setBasketPC] = useState([]);
  const [channelPC, setChannelPC] = useState([]);
  useEffect(() => {
    const fetchRegionalPc = async () => {
      setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/price_region",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          startdate: selectedStartDate
            ? moment(selectedStartDate?.$d).format("YYYY-MM-DD")
            : "",
          enddate: selectedEndDate
            ? moment(selectedEndDate?.$d).format("YYYY-MM-DD")
            : "",
          email: props?.currentUser?.user,
        }),
      })
        .then((response) => {
          console.log(response, "regional Pc");
          setIsLoading(false);
          const responseData = response?.data;
          setRegionalPC(responseData?.data);
          setNationalPC(responseData?.overall_percentage);
        })
        .catch((error) => {
          setAlertError("something went wrong");
          setIsLoading(false);
          console.log("Get Filters Api: ", error);
        });
    };

    const fetchChannelPc = async () => {
      setIsLoading(true);
      await axios({
        method: "post",
        url: "/api/price_channel",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          startdate: selectedStartDate
            ? moment(selectedStartDate?.$d).format("YYYY-MM-DD")
            : "",
          enddate: selectedEndDate
            ? moment(selectedEndDate?.$d).format("YYYY-MM-DD")
            : "",
          email: props?.currentUser?.user,
        }),
      })
        .then((response) => {
          console.log(response, "channel Pc");
          setIsLoading(false);
          const responseData = response?.data;
          setChannelPC(responseData?.data);
        })
        .catch((error) => {
          setAlertError("something went wrong");
          setIsLoading(false);
          console.log("Get Filters Api: ", error);
        });
    };
    if (selectedStartDate) {
      if (selectedStartDate && selectedEndDate) {
        fetchRegionalPc();
        fetchChannelPc();
      }
    } else {
      fetchRegionalPc();
      fetchChannelPc();
    }
  }, [props?.currentUser?.user, selectedStartDate, selectedEndDate]);
  const handleSelectStartDate = (newStartDate) => {
    setSelectedStartDate(newStartDate);

    if (newStartDate && !selectedEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else if (!newStartDate && selectedEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };

  const handleSelectEndDate = (newEndDate) => {
    setSelectedEndDate(newEndDate);

    if (!selectedStartDate && newEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else if (selectedStartDate && !newEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };
  return (
    <div className="container-fluid main_container planogram_compliance">
      {isLoading || alertError ? (
        <div className="loading_area">
          {alertError && <Alert variant="danger">{alertError}</Alert>}
          {isLoading && (
            <div className="col-12 d-flex justify-content-center loader">
              <div
                className="custom_loader spinner-border text-info"
                role="status"
                style={{ zIndex: "2" }}
              ></div>
              <div className="loader-message">Loading...</div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="row align-items-center justify-content-between">
            <div className="page_heading col-5 p-0">
              <h2>
                Price
                <span className="page_heading_secondary">
                  &nbsp;List Availability
                </span>
              </h2>
            </div>
            <div className="date_cont col-6 p-0">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={`${selectedStartDate && "date_selected"}`}
                  label="From"
                  inputFormat="DD-MMM-YYYY"
                  mask={"__-__-____ __"}
                  value={selectedStartDate}
                  maxDate={selectedEndDate}
                  disableFuture={true}
                  onChange={handleSelectStartDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={startDateError}
                      helperText={null}
                      size="small"
                    />
                  )}
                />

                <DatePicker
                  className={`${selectedEndDate && "date_selected"}`}
                  label="To"
                  inputFormat="DD-MMM-YYYY"
                  mask={"__-__-____ __"}
                  value={selectedEndDate}
                  minDate={selectedStartDate}
                  disableFuture={true}
                  onChange={handleSelectEndDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={endDateError}
                      helperText={null}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="row total_boxes">
            <div className="col-4 col-md-2 col-lg-auto total_box box_secondary">
              <label htmlFor="">
                National Price List Availability
                <br />
                {/* <small>(PC Availability)</small> */}
                <br />
                <strong>{nationalPC ? nationalPC : ""}%</strong>
              </label>
            </div>
            {regionalPC?.map((region, index) => (
              <div className="col-4 col-md-2 col-lg-auto total_box box_secondary">
                <label htmlFor="">
                Regional Price List Availability
                  <br />
                  <small>{"(" + region?.name + ")"}</small>
                </label>
                <strong>{region?.value}%</strong>
              </div>
            ))}
          </div>
     

          <div className="row gap-3 graph_row justify-content-center">
            <div className="custom_bg col-3 d-flex gap-3 flex-column">
              <h5 className="custom_card_heading">Channel Wise</h5>
              <div className="channel_compliance data-section">
                <div className="custom_section">
                  {channelPC?.map((channel, index) => (
                    <h3 key={index} className="medium_heading">
                      <strong>{channel?.name}</strong>
                    </h3>
                  ))}
                  {/* <h3 className="medium_heading">
                    <strong>Kiryana Retail</strong>
                  </h3>
                  <h3 className="medium_heading">
                    <strong>Petro Marts</strong>
                  </h3>
                  <h3 className="medium_heading">
                    <strong>Bakery</strong>
                  </h3> */}
                </div>

                <div className="custom_cards">
                  <Paper
                    elevation={6}
                    square={true}
                    className="custom_paper custom_section"
                  >
                    {channelPC?.map((channel, index) => (
                      <div key={index} className="d-flex align-items-center">
                        <h4 className="percentage_value">
                          {channel ? channel.value : ""}%
                        </h4>
                      </div>
                    ))}
                    {/* <div className="d-flex align-items-center">
                      <h4 className="percentage_value">
                        {channelPC ? channelPC.Modern_Retail : ""}
                      </h4>
                    </div>
                    <div className="d-flex align-items-center">
                      <h4 className="percentage_value">
                        {channelPC ? channelPC.Kiryana_Store : ""}
                      </h4>
                    </div>
                    <div className="d-flex align-items-center">
                      <h4 className="percentage_value">
                        {channelPC ? channelPC.Petro_mart : ""}
                      </h4>
                    </div>
                    <div className="d-flex align-items-center">
                      <h4 className="percentage_value">
                        {channelPC ? channelPC.Bakery : ""}
                      </h4>
                    </div> */}
                  </Paper>
                </div>
              </div>
            </div>

            {/* <div className="col-9 graph_column custom_bg">
              <h5 className="custom_card_heading">National Compliance Trend</h5>
              <LineChart
                lineGraphData={{
                  line1: {
                    label: "Marvin",
                    data: [50, 34, 56, 23, 34, 1, 54, 16, 53, 31],
                    color: "6B88CA",
                  },
                }}
                labels={["jan", "fab", "march", "may", "june"]}
                title="line Chart"
              />
            </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default PriceList;
