import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = (props) => {
  const [matches, setMatches] = useState(window.matchMedia("(max-width: 992px)").matches);
  const [height, setHeight] = useState(matches && !props?.customHeight ? 400 : props?.customHeight ? props?.customHeight : 300);
  const [chartData, setChartData] = useState([]);

  const options =
  {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
      title: {
        display: false,
        text: props?.title ? props?.title : "",
        color: '#065886',
        padding: {
          top: 10,
          bottom: 20
        }
      },
      datalabels: {
        display: true,
        color: "#666666",
        align: "end",
        anchor: "end",
        font: { size: "8" }
      }
    },
    // elements: {
    //   line: {
    //     tension: 0.4, // Adjust the tension value for smoother line edges
    //   }
    // }
  };

  const labels = (props?.weeks && props?.weeks.length > 0) ? props?.weeks : (props?.labels && props?.labels.length > 0) ? props?.labels : [];
  const datasets = (chartData && chartData.length > 0) ? chartData : (props?.datasets && props?.datasets.length > 0) ? props?.datasets : [];

  console.log(Object.entries(props?.lineGraphData)[0][1].color, ' entries')
  useEffect(() => {
    if (props?.lineGraphData) {
      setChartData([]);

      for (let i = 0; i < Object.keys(props?.lineGraphData).length; i++) {
        setChartData(chartData => [...chartData, {
          lineTension: 0.28,
          label: Object.entries(props?.lineGraphData)[i][0] && Object.entries(props?.lineGraphData)[i][0],
          data: Object.entries(props?.lineGraphData)[i][1].data && Object.entries(props?.lineGraphData)[i][1].data,
          borderColor: "#" + `${Object.entries(props?.lineGraphData)[i][1].color && Object.entries(props?.lineGraphData)[i][1].color}`,
          backgroundColor: "#" + `${Object.entries(props?.lineGraphData)[i][1].color && Object.entries(props?.lineGraphData)[i][1].color}`
        }]);
      }
    }

    window
      .matchMedia("(max-width: 992px)")
      .addEventListener('change', e => setMatches(e.matches));

    window
      .matchMedia("(min-width: 320px)")
      .addEventListener('change', () => setHeight(300));

  }, [props, props?.lineGraphData])

  const data = {
    labels: labels,
    datasets: datasets
  };

  return (
    <div className="custom_graph">
      {props?.loading ? (
        <div className="col-12 text-center">
          <br />
          <div className="custom_loader spinner-border text-info" role="status" style={{ zIndex: '2' }}>
          </div>
          <span className="text-info d-block">{props?.title ? props?.title : ''}</span>
          <br />
        </div>
      ) : (
        <Line options={options} data={data} height={height} style={{ zIndex: '1' }} />
      )}
    </div>
  );
}

export default LineChart;