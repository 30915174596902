import { BrowserRouter } from 'react-router-dom';
import RouterLinks from './routes/RouterLinks';

function App() 
{
  return (
    <BrowserRouter>
      <RouterLinks />
    </BrowserRouter>
  );
}

export default App;