import React, { useEffect, useState, useRef, createRef } from "react";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { RenderCellExpand } from "./DataTableCellExpand";
import "../styles/general.scss";
import DownloadCsv from "./DownloadCsv";

const DynamicDataTable = ({
  columnHeaders,
  rows,
  isLoading,
  pageSize,
  page,
  setPageSize,
  setPage,
  rowCount,
  rowID,
  hideFooter,
  filters,
  csvName,
  csvLink,
  showDownload,
  setReportDownloaded,
  setDownloadReoprtName,
  logId,
  recordLogActivity,
}) => {
  const [columns, setColumns] = useState([]);
  const [columnRefs, setColumnRefs] = useState([]);
  const DEFAULT_MIN_WIDTH_CELL = 80;
  const DEFAULT_MAX_WIDTH_CELL = 500;
  const tableRef = useRef(null);
  const isResizing = useRef(-1);

  const ODD_OPACITY = 0.2;
  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .MuiDataGrid-cell": {
      color:
        theme.palette.mode === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.65)",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: 0,
    },
    "& .MuiDataGrid-cell:hover": {
      color: "primary.main",
    },
    // [`& .${gridClasses.row}.even`]: {
    //     backgroundColor: theme.palette.grey[200],
    //     '&:hover, &.Mui-hovered': {
    //         backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
    //         '@media (hover: none)': {
    //             backgroundColor: 'transparent',
    //         },
    //     },
    //     '&.Mui-selected': {
    //         backgroundColor: alpha(
    //             theme.palette.primary.main,
    //             ODD_OPACITY + theme.palette.action.selectedOpacity,
    //         ),
    //         '&:hover, &.Mui-hovered': {
    //             backgroundColor: alpha(
    //                 theme.palette.primary.main,
    //                 ODD_OPACITY +
    //                 theme.palette.action.selectedOpacity +
    //                 theme.palette.action.hoverOpacity,
    //             ),
    //             '@media (hover: none)': {
    //                 backgroundColor: alpha(
    //                     theme.palette.primary.main,
    //                     ODD_OPACITY + theme.palette.action.selectedOpacity,
    //                 )
    //             }
    //         }
    //     }
    // },
  }));

  useEffect(() => {
    if (columnHeaders?.length > 0) {
      const columnReferences = columnHeaders?.map(() => createRef());
      let tempColumns = [];

      for (let i = 0; i < columnHeaders.length; i++) {
        const values = rows.map((keys) => keys[columnHeaders[i]]);
        //         // const isValueString = values.every(value => isNaN(value));

        if (isNaN(values[i])) {
          tempColumns.push({
            field: columnHeaders[i],
            type: "string",
            minWidth: 140,
            width: 80,
            disableColumnMenu: true,
            renderHeader: () => {
              return (
                <div>
                  <span>{columnHeaders[i]}</span>
                  <div
                    ref={columnReferences[i]}
                    className="resizeLine"
                    onMouseDown={() => handleResizeColumn(i)}
                  />
                </div>
              );
            },
            renderCell: RenderCellExpand,
          });
        } else {
          tempColumns.push({
            field: columnHeaders[i],
            headerAlign: "center",
            type: "number",
            minWidth: 110,
            width: 80,
            disableColumnMenu: true,
            renderHeader: () => {
              return (
                <div>
                  <span>{columnHeaders[i]}</span>
                  <div
                    ref={columnReferences[i]}
                    className="resizeLine"
                    onMouseDown={() => handleResizeColumn(i)}
                  />
                </div>
              );
            },
            renderCell: RenderCellExpand,
          });
        }
      }

      setColumns(tempColumns);
      setColumnRefs(columnReferences);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [columnHeaders]);
  /* Handle column resize calls */
  useEffect(() => {
    document.addEventListener("mousemove", handleOnMouseMove);
    document.addEventListener("mouseup", handleOnMouseUp);
    // document.addEventListener.onmousemove = handleOnMouseMove;
    // document.addEventListener.onmouseup = handleOnMouseUp;
    return () => {
      document.removeEventListener("mousemove", handleOnMouseMove);
      document.removeEventListener("mouseup", handleOnMouseUp);
    };

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [columnRefs]);
  // useEffect(() => {
  //     // const columnHeaders = Object.keys(columnDetails);
  //     // const dataTypes = Object.values(columnDetails)?.map(column => column.type);
  //     // const flex = Object.values(columnDetails).map(column => column.flex);
  //     let tempColumns = [];
  //     const columnReferences = columnHeaders?.map(() => createRef());
  //     for (let i = 0; i < columnHeaders.length; i++) {
  //         const values = rows.map(keys => keys[columnHeaders[i]]);
  //         // const isValueString = values.every(value => isNaN(value));

  //         if (isNaN(values[i])) {

  //             tempColumns.push({
  //                 field: columnHeaders[i],
  //                 // headerName: columnHeaders[i],
  //                 type: 'string',
  //                 width : 150,
  //                 //   width: widths[i],
  //                 filterable: true,
  //                 renderHeader: () => { return (<div> <span>{columnHeaders[i]}</span> <div ref={columnReferences[i]} className="resizeLine" onMouseDown={() => handleResizeColumn(i)} /></div>) },
  //                 renderCell: RenderCellExpand
  //             });
  //         }
  //         else {
  //             tempColumns.push({
  //                 field: columnHeaders[i],
  //                 // headerName: columnHeaders[i],
  //                 type: 'number',
  //                 flex : 1,
  //                 filterable: true,
  //                 renderHeader: () => { return (<div> <span>{columnHeaders[i]}</span> <div ref={columnReferences[i]} className="resizeLine" onMouseDown={() => handleResizeColumn(i)} /></div>) },
  //                 renderCell: RenderCellExpand
  //             });
  //         }
  //     }

  //     setColumns(tempColumns);
  //     setColumnRefs(columnReferences)

  // }, [columnHeaders, rows]);

  const adjustColumnWidth = (index, width) => {
    const tempColumns = [...columns];
    const minWidth = tempColumns[index]?.minWidth ?? DEFAULT_MIN_WIDTH_CELL;
    const maxWidth = columnRefs[index]?.maxWidth ?? DEFAULT_MAX_WIDTH_CELL;
    const newWidth =
      width > maxWidth ? maxWidth : width < minWidth ? minWidth : width;

    tempColumns[index].width = newWidth;
    setColumns(tempColumns);
  };

  const setCursorDocument = (isResizing) => {
    document.body.style.cursor = isResizing ? "col-resize" : "auto";
  };

  const handleOnMouseMove = (e) => {
    if (isResizing?.current >= 0) {
      const eleBounds =
        columnRefs[
          isResizing?.current
        ]?.current?.parentElement?.getBoundingClientRect();
      let ret = false;
      if (e.clientX >= eleBounds?.left) {
        const newWidth =
          e.clientX -
          columnRefs[
            isResizing?.current
          ]?.current?.parentElement?.getBoundingClientRect()?.left;
        adjustColumnWidth(isResizing?.current, newWidth?.toFixed(0));
      }
    }
  };

  const handleOnMouseUp = () => {
    isResizing.current = -1;
    setCursorDocument(false);
  };

  const handleResizeColumn = (index) => {
    isResizing.current = index;
    setCursorDocument(true);
  };

  function CustomGridToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        {showDownload && (
          <DownloadCsv
            url={csvLink}
            fileName={csvName}
            data={filters}
            setReportDownloaded={setReportDownloaded}
            setDownloadReoprtName={setDownloadReoprtName}
            logId={logId}
            recordLogActivity={recordLogActivity}
          />
        )}
        {/* <GridToolbarExport className="export" /> */}
      </GridToolbarContainer>
    );
  }

  return (
    <div className="datagrid">
      <StripedDataGrid
        ref={tableRef}
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        columnBuffer={2}
        rowHeight={20}
        // rowsPerPageOptions={[5]}
        hideFooterPagination={hideFooter}
        hideFooterSelectedRowCount={hideFooter}
        pagination
        page={page}
        pageSize={pageSize}
        paginationMode="server"
        loading={isLoading}
        density="compact"
        disableExtendRowFullWidth={true}
        disableSelectionOnClick={false}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        components={{ Toolbar: CustomGridToolbar }}
        getRowId={(row) => row[rowID]}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
      />
    </div>
  );
};

export default React.memo(DynamicDataTable);
