import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import '../../styles/photohub/general.css';
Chart.register(...registerables);

/*const processedImages = JSON.parse(localStorage.getItem("processed"));
const unprocessedImages = JSON.parse(localStorage.getItem("unprocessed"));
const months = JSON.parse(localStorage.getItem("months"));
var totalImages = '';

if (processedImages && unprocessedImages)
    totalImages = processedImages.map((num, idx) => num + unprocessedImages[idx]);

console.log("Processed Images: ", processedImages);
console.log("Unprocessed Images: ", unprocessedImages);
console.log("Months: ", months);
console.log("Total Images: ", totalImages);

const data = 
{
    labels: months,
    datasets: [
        {
            label: "Number of Images Unprocessed",
            data: unprocessedImages,
            backgroundColor: "rgba(87, 121, 234, 0.6)",
            borderColor: "rgba(87, 121, 234, 0.6)",
            order: 1
        },
        {
            label: "Number of Images Processed",
            data: processedImages,
            backgroundColor: "rgba(18, 200, 150, 0.6)",
            borderColor: "rgba(18, 200, 150, 0.6)",
            order: 1
        },
        {
            label: "Total Images",
            data: totalImages,
            backgroundColor: "rgba(234, 87, 102, 0.6)",
            borderColor: "rgba(234, 87, 102, 0.6)",
            fill: false,
            pointHoverRadius: 20,
            pointHoverBorderWidth: 5,
            type: "line",
            order: 0
        }
    ]
};*/

const options = 
{
    responsive: true,
    maintainAspectRatio: false, 
    aspectRatio: 1.4,
    barThickness: 60,
    title: 
    {
        display: true,
        text: "Bar + Line Chart",
        fontSize: 25
    },
    scales: 
    {
        xAxes: {
            scaleLabel: 
            {
                display: true,
                labelString: "Months"
            },
            stacked: "true"
        },
        yAxes: {
            scaleLabel: 
            {
                display: true,
                labelString: "Values"
            },
            stacked: "true"
        }
    }
};

const MixedChart = (props) => 
{
  return (
    <div className = "charts-card">
        <Bar 
            data = {props.data}
            options = {options}
            height = {null}
            width = {null}
        />
    </div>
  );
}

export default MixedChart;